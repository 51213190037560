import React from "react";

const ArrowDown = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.58782 0.58718C0.912214 0.262786 1.43807 0.262499 1.76282 0.586539L5 3.81667L8.23718 0.586539C8.56193 0.2625 9.08779 0.262786 9.41218 0.58718V0.58718C9.73682 0.911824 9.73682 1.43818 9.41218 1.76282L5.70711 5.46789C5.31658 5.85842 4.68342 5.85842 4.29289 5.46789L0.587821 1.76282C0.263177 1.43818 0.263176 0.911824 0.58782 0.58718V0.58718Z" fill="currentColor"/>
    </svg>
  )
}

export default ArrowDown