import React from "react";
import style from "./PatientBox.module.sass";
import SliderProgressBar from "../DailySequence/Slider/SliderProgressBar";
import PatientMenu from "./PatientMenu/PatientMenu";
import { useState } from "react";
function PatientBox(props) {
  const {
    actions,
    actionsUrls,
    activeActions,
    archiveRedirect,
    archiveTranslations,
    groupTags,
    isDemo,
    linkToClick,
    licenses,
    pathway,
    patientEmail,
    patientIdTooltip,
    patientLoginCode,
    patientName,
    showEmail,
    score,
    toClick
  } = props;

  const [groupTagHover, setGroupTagHover] = useState(false)

  const renderPatientNameLink = () => {
    return (
      <a href={linkToClick} className={`d-flex ${style.patientName}`}>
        <span style={{paddingTop: "5px"}}>{patientName}</span>
        {renderPatientId()}
      </a>
    );
  };

  const renderPatientName = () => {
    return (
      <a className={`d-flex ${style.patientName}`}>
        <span style={{paddingTop: "5px"}}>{patientName}</span>
        {renderPatientId()}
      </a>
    );
  };

  const renderPatientId = () => {
    return (
      (patientLoginCode || patientEmail) &&
      <div className={`auto-tooltip d-flex ${style.patientId}`} 
           data-toggle="tooltip"
           title={patientIdTooltip}>
        {/* {patientId} */}
        {patientLoginCode}
        {patientEmail && <div className={`patient-email ps-1`}>{realPatientEmail(patientEmail)}</div>}
        {showEmail && <div className={`patient-email`}>{patientEmail}</div>} {/* TODO: probably we can safely remove this */}
      </div>

    );
  };

  const realPatientEmail = (email) => {
    if (email == null) return null;
    return email.includes('@nomail.no') ? null : email;
  }

  const renderProgressBar = () => {
    return (
      <div className={`${style.progress}`} style={{width: (licenses.includes("euleria_lab")) ? "50%" : "100%"}}>
        <div className={``}>
          <SliderProgressBar score={score} />
        </div>{" "}
      </div>
    );
  };

  const goToPatient = () => {
    window.location.href = linkToClick;
  };

  return (
      <div className={`${style.PatientBox} ${toClick ? "clickable" : ""}`}
           style={isDemo? {marginBottom: "30px"} : {}}
           onClick={toClick ? () => goToPatient() : null}>
        <div className={`col ${style.flexContainer}`}>
          <div className={style.containerRow}>
            <div>
                {toClick ? renderPatientNameLink() : renderPatientName()}
              </div>
              {!isDemo && groupTags && 
              <div className={style.groupsCol}>
                  {groupTags.map((group, index) => {
                    if(index < 2) {
                      return (
                        <span className={style.groupTag}
                              key={group}>
                          {group}
                        </span>
                      )
                    }
                  })}
                  {groupTags.length > 2 &&
                    <span className={`${style.groupTag} ${style.hiddenGroups}`}
                          onMouseEnter={() => setGroupTagHover(true)}
                          onMouseLeave={() => setGroupTagHover(false)}>
                      {`+ ${groupTags.length - 2}`}
                    </span>
                  }
                  {groupTags.length > 2 &&
                    <div className={style.groupPopover} style={groupTagHover ? {} : {display: "none"}}>
                      {groupTags.map((group, index) => {
                        if (index >= 2) {
                          return (
                            <div className={style.group}
                                key={group}>
                              {group}
                            </div>
                          )
                        } 
                      })}
                    </div>
                  }
                </div>
              }
          </div>
          {!isDemo &&
            <div className={`${style.containerRow} ${style.second}`}>
              {renderProgressBar()}
              {licenses.includes("euleria_lab") &&
                <div className={`${style.pathwayCol}`}>
                  {!pathway.path &&
                    <a href={pathway.newPath}
                      className={style.set}
                      onClick={(e) => e.stopPropagation()}>
                      {pathway.setPathway}
                    </a>
                  }
                  {pathway.path &&
                    <div className={style.current}>
                      {pathway.label}
                    </div>
                  }
                </div>}
            </div>
          }
        </div>
        <div className={style.patientMenuCol}>
          {activeActions.length > 0 && 
            <PatientMenu
              actionsUrls={actionsUrls}
              activeActions={activeActions}
              archiveRedirect={archiveRedirect}
              archiveTranslations={archiveTranslations}
              isDemo={isDemo}
              patientName={patientName}
              translations={actions} 
            />
          }
        </div>
      </div>
  );
}

PatientBox.defaultProps = {
  toClick: false,
};

export default PatientBox;
