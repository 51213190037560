import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ChatListSearch from "./ChatListSearch/ChatListSearch";
import List from "../../List/List";
import ChatItem from "../ChatItem/ChatItem";
import { ChatContext } from "../Chat";

function ChatList(props) {
  const { onItemsLoaded } = props;
  const [currentActiveItem, setCurrentActiveItem] = useState(null);
  const baseUrl = "/chats.json";
  const [queryUrl, setQueryUrl] = useState(baseUrl);
  const value = useContext(ChatContext);
  const refreshChatList = (search = "") => {
    let newQueryUrl = queryUrl + "?random=" + Math.random();
    if (queryUrl.indexOf("?") > 0) {
      newQueryUrl = queryUrl + "&random=" + Math.random();
    }
    setQueryUrl(newQueryUrl);
  };
  value.forceChatListUpdate = refreshChatList;

  const onChangeFilter = (search = "") => {
    let newQueryUrl = baseUrl + "?name=" + search;
    setQueryUrl(newQueryUrl);
  };

  const onClickItem = (id) => {
    props.onClickItem(id);
    setCurrentActiveItem(id);
  };

  const renderItem = (item) => {
    return (
      <ChatItem
        {...item}
        active={currentActiveItem == item.id}
        onClick={onClickItem}
      />
    );
  };

  return (
    <div>
      <ChatListSearch onChangeFilter={onChangeFilter} />
      <List
        showPagination={false}
        renderItem={renderItem}
        baseQuery={queryUrl}
        onItemsLoaded={onItemsLoaded}
      />
    </div>
  );
}

ChatList.propTypes = {};

ChatList.defaultProps = {};

export default ChatList;
