import React, { useRef, useState } from "react";
import { Tooltip } from "react-tippy";

function VideoTooltip(props) {
  const { videoUrl, reflectX, position } = props;
  const [playVideo, setPlayVideo] = useState(false);
  let videoRef = useRef();

  const onShow = () => {
    setPlayVideo(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const onHidden = () => {
    setPlayVideo(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const renderVideo = () => {
    return (
      <video
        autoPlay={playVideo}
        loop
        muted
        className="media"
        ref={videoRef}
        style={{ transform: reflectX ? "scaleX(-1)" : "" }}
      >
        <source src={videoUrl} type="video/mp4" />{" "}
      </video>
    );
  };

  return (
    <Tooltip
      onShow={onShow}
      onHidden={onHidden}
      html={renderVideo()}
      position={position}
      interactiveBorder={0}
    >
      {props.children}
    </Tooltip>
  );
}

VideoTooltip.propTypes = {};

VideoTooltip.defaultProps = {
  reflectX: false,
  videoUrl: "",
};

export default VideoTooltip;
