import React from "react";
import PropTypes from "prop-types";
import style from "./NotificationSetting.module.sass";
import PatientHomeNotification from "./PatientHomeNotification/PatientHomeNotification";
import ReportNotifications from "./ReportNotifications/ReportNotifications";

function NotificationSetting(props){
  const {
    licenses
  } = props;

  return (
  <div className={`${style.NotificationSetting}`}>
    {licenses.includes('euleria_home') &&
      <div>
        <PatientHomeNotification />
        <div className={style.separatorLine}></div>
        <ReportNotifications />
      </div>
    }
  </div>
  );
}

NotificationSetting.propTypes = {
};

NotificationSetting.defaultProps = {
};

export default NotificationSetting