import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import style from "./Select.module.sass"

function Select(props) {
  const {
    grouped,
    openTop,
    options,
    prompt,
    searchable,
    setOption,
    selectedOption,
    translations
  } = props

  const [currentOption, setCurrentOption] = useState(selectedOption || prompt)
  const [showOptions, setShowOptions] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [searchValue, setSearchValue] = useState("")
  const promptOption = { value: "", label: prompt }

  const optionsSearchRef = useRef()

  useEffect(() => {
    setCurrentOption(selectedOption || prompt)
  }, [selectedOption])

  useEffect(() => {
    if (showOptions) {
    setFilteredOptions(options)
    } else if(optionsSearchRef.current) {
      optionsSearchRef.current.value = null
    }
  }, [showOptions])

  useEffect(() => {
    let timeout = setTimeout(() => {
      filterOptions(searchValue)
    }, 300)
    return () => clearTimeout(timeout)
  }, [searchValue])

  const selectOption = (option) => {
    if(optionsSearchRef.current) {
      optionsSearchRef.current.value = null
    }
    setShowOptions(false)
    setCurrentOption(option.label)
    setOption(option)
  }

  const renderOptionsList = (options) => {
    return options.map(option => {
      return (
        <div className={style.option}
             onClick={() => selectOption(option)}
             key={option.key || option.value}>
          {option.label}
        </div>
      )
    })
  }

  const filterOptions = (value) => {
    const searchValue = value.toLowerCase()
    if(grouped) {
      let filtered = Object.entries(options).map(([key, values]) => {
        const filteredValues = values.filter(item => item.label.toLowerCase().includes(searchValue))
        return [key, filteredValues]
      })
      filtered = Object.fromEntries(filtered.filter(([key, values]) => values.length > 0))
      setFilteredOptions(filtered)
    } else {
      let filtered = options.filter(item => item.label.toLowerCase().includes(searchValue))
      setFilteredOptions(filtered)
    }
  }

  return (
    <div className={style.select}>
      <div className={`select kari-select ${style.selectWrapper} ${showOptions ? style.selectFocused : ""}`}
           onClick={() => setShowOptions(!showOptions)}>
        {searchable && <i className={`fa-solid fa-search ${style.searchIcon}`} onClick={() => optionsSearchRef.current.focus()}></i>}
        <div className={`${style.selectedOption} ${searchable && style.searchable}`}>
          {searchable &&
            <input type="text"
                   ref={optionsSearchRef}
                   placeholder={currentOption}
                   className={`form-control ${style.searchBar}`}
                   onClick={(e) => {
                    e.stopPropagation()
                    setShowOptions(true)
                  }}
                   onChange={(e) => setSearchValue(e.target.value)}>
            </input>
          }
          {!searchable && currentOption}
        </div>
        {!showOptions && <i className={`fa-solid fa-angle-down ${style.selectIcon}`}></i>}
        {showOptions && <i className={`fa-solid fa-angle-up ${style.selectIcon}`}></i>}
      </div>
      {showOptions &&
        <div className={`${style.optionsWrapper} ${openTop ? style.openTop : ""}`}>
          {!grouped && prompt &&
            <div className={style.option}
                 onClick={() => selectOption(promptOption)}>
              {promptOption.label}
            </div>}
          {!grouped && renderOptionsList(filteredOptions)}
          {Object.keys(filteredOptions).length == 0 && translations?.notFound}
          {grouped &&
            Object.entries(filteredOptions).map(([key, groupOptions]) => {
              return (
                <div>
                  <div className={style.group} key={key}>{key}</div>
                  {renderOptionsList(groupOptions)}
                </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}

Select.propTypes = {
  grouped: PropTypes.bool,
  openTop: PropTypes.bool,
  options: PropTypes.any.isRequired,
  prompt: PropTypes.string,
  searchable: PropTypes.bool,
  setOption: PropTypes.func.isRequired,
  selected: PropTypes.string
}

Select.defaultProps = {
  grouped: false,
  openTop: false,
  searchable: false
}

export default Select