import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./FeedbackButton.module.sass";
import IcoRecording from "../../../../../assets/images/ico-recording.svg"
import { LiveCallsContext } from "../LiveCalls";

function FeedbackButton(props){
  const {} = props;

  const {translations} = useContext(LiveCallsContext);

  return (
  <div className={`${style.FeedbackButton}`} id={"record-feedback"}>
    <img src={IcoRecording}/>
    <span>
      {translations.recording}
    </span>
  </div>
  );
}

FeedbackButton.propTypes = {
};

FeedbackButton.defaultProps = {
};

export default FeedbackButton