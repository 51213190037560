import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./PatientAttachmentsMenu.module.sass";

function PatientAttachmentsMenu(props) {
  const [showModal, setShowModal] = useState(false);
  const ref = useRef();
  const {
    path,
    file,
    fetchFiles,
    deleteText,
    renameText,
    downloadText,
    onDestroy,
    onRename
  } = props;

  const filePath = `${path}/${file.id}`;

  const destroy = () => {
    setShowModal(false);
    onDestroy();
    fetch(
      filePath,
      { method: 'DELETE' }
    ).then(
      () => fetchFiles()
    );
  }

  const rename = () => {
    setShowModal(false);
    onRename();
  }

  const download = () => {
    fetch(filePath)
      .then(response => response.json())
      .then(result => {
        fetch(result.chino_url)
          .then(response => response.blob())
          .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
          });
      });
  }

  useOnClickOutside(ref, () => setShowModal(false));

  return (
    <div className={style.PatientAttachmentsMenu}>
      <div className={`${style.DotsContainer} ${showModal && style.DotsClicked}`}
            onClick={() => { setShowModal(!showModal) }}>
        •••
      </div>
      {showModal && <div ref={ref} className={style.DropDown}>
        <div className={style.DropDownItem}
          onClick={download}>{downloadText}</div>
        <div className={style.DropDownItem}
             onClick={rename}>{renameText}</div>
        <div className={style.DropDownItem}
          onClick={destroy}>{deleteText}</div>
      </div>
      }
    </div>
  )
}

// solution got from here: https://stackoverflow.com/a/68018429/1897170
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

PatientAttachmentsMenu.propTypes = {
  fetchFiles: PropTypes.func,
  file: PropTypes.object,
  path: PropTypes.string,
  onDestroy: PropTypes.func,
  onRename: PropTypes.func
};

export default PatientAttachmentsMenu;
