import React, { useEffect, useState } from "react";
import styles from "./VersionItem.module.sass";

const VersionItem = (props) => {
  const { version, date, description } = props.version;

  const insertHtml = (html) => {
    return {
      __html: html
    }
  }

  return (
    <div className={styles.versionItem}>
      <header style={{position: 'relative'}}>
        <div className={styles.title}>{`Kari ${version}`}</div>
        <div className={styles.date}>{`${date}`}</div>
      </header>
      <div className={styles.content} dangerouslySetInnerHTML={insertHtml(description)} />
    </div>
  );
};

export { VersionItem as default };
