import { debounce } from "../../../../helpers";

export const HEART_RATE_COLOR_RGBA = "rgba(255, 92, 112, 1)";
export const OXYGEN_SATURATION_COLOR_RGBA = "rgba(100, 202, 241, 1)";

export const maxData = (el1, el2) => {
  // Talvolta el2 può essere true invece che un array.
  if (el2 == true) {
    return [];
  }
  if (el1 && el2) {
    if (el1.length > el2.length) {
      return el1;
    } else {
      return el2;
    }
  } else {
    if (el1) return el1;
    if (el2) return el2;
  }
};

export const configPulseOximeter = (data) => {
  return {
    type: "line",
    data: data,
    plugins: [
      {
        beforeRender: (x) => {
          setupLineColors(x);
        },
      },
      {
        afterUpdate: (chart) => {
          // offsetSaturation(chart); // TODO: remove, as it is useless
        }
      }
    ],
    options: pulseOximeterOptions(data.datasets),
  };
};

export const configEffort = (data) => {
  return {
    type: "line",
    data: data,
    options: effortOptions(data.datasets[0].data),
  };
};

export const generatePulseOximeterData = (heartRateLabel, saturationLabel, labels, heartRateData, saturationData) => {
  // console.log(heartRateData);
  return {
    labels: labels,
    datasets: [
      {
        label: heartRateLabel,
        data: heartRateData,
        borderWidth: 2,
        xAxisID: 'x-axis-pulseoximeter',
        yAxisID: "y-axis-heartrate",
      },
      {
        label: saturationLabel,
        data: saturationData,
        borderWidth: 2,
        xAxisID: 'x-axis-pulseoximeter',
        yAxisID: "y-axis-saturation",
      },
    ],
  };
};

export const generateEffortData = (effortData) => {
  // console.log(effortData);
  return {
    labels: effortData.map((d) => d.x),
    datasets: [
      {
        data: effortData,
        borderWidth: 2,
        xAxisID: 'x-axis-effort',
        yAxisID: 'y-axis-effort',
        // backgroundColor: fillPattern
      },
    ],
  };
};

export const offsetSaturation = (chart) => {
  // We get the dataset and set the offset here
  var dataset = chart.config.data.datasets[1];
  var offset = 0;

  // For every data in the dataset ...
  for (var i = 0; i < dataset._meta[2]?.data.length; i++) {
    // We get the model linked to this data
    var model = dataset._meta[2]?.data[i]._model;

    // And add the offset to the `y` property
    model.y += offset;
    // .. and also to these two properties
    // to make the bezier curve fits the new graph
    model.controlPointNextX += offset;
    model.controlPointPreviousX += offset;
  }
};

export const setupLineColors = (x) => {
  const c = x.chart;
  const dataset = x.data.datasets[0];
  const heartRateGradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
  heartRateGradientFill.addColorStop(1, "rgba(97, 41, 48, 1)"); // bottom
  heartRateGradientFill.addColorStop(0.61, HEART_RATE_COLOR_RGBA);
  const saturationGradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
  saturationGradientFill.addColorStop(1, OXYGEN_SATURATION_COLOR_RGBA);
  saturationGradientFill.addColorStop(0.61, OXYGEN_SATURATION_COLOR_RGBA);
  const heartRateModel =
    x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]]?.dataset._model;
  const saturationModel =
    x.data.datasets[1]._meta[Object.keys(dataset._meta)[0]]?.dataset._model;

  heartRateModel.borderColor = heartRateGradientFill;
  saturationModel.borderColor = saturationGradientFill;

};

export const effortOptions = (effortData) => {
  // build annotations for drawing horizontal lines at specific ordinates
  const valuesForAxes = {
    'x-axis-effort': effortData.map((point) => point.x)
  };
  const annotations = Object.entries(valuesForAxes).map(([scaleID, values]) => {
    return values.map((value, index) => {

      // Multiplier used to adjust the first and the last labels
      let labelAdjustMultiplier = 0;
      if (index == 0) labelAdjustMultiplier = -1;
      if (index == values.length - 1) labelAdjustMultiplier = 1;

      const labelsEnabled = values.length < 10 // to avoid overlapping labels

      return {
        type: "line",
        id: `${value}-line-${index}`,
        mode: "vertical",
        scaleID: scaleID,
        value: value,
        borderColor: "rgba(79,94,112,0.4)",
        borderWidth: 1,
        label: {
          backgroundColor: 'rgba(0,0,0,0)',
          fontFamily: "Mont",
          fontStyle: "bold",
          fontSize: 70,
          fontColor: "rgba(79,94,112,0.3)",
          enabled: labelsEnabled,
          position: 'bottom',
          content: effortData.find((point) => point.x == value).y,
          xAdjust: 20 * labelAdjustMultiplier,
          yPadding: -6
        }
      };
    })
  }).flat();

  const xMaxForTicks = Math.max(...effortData.map((point) => point.x))

  return {
    annotation: {
      drawTime: "afterDatasetsDraw",
      events: ["click"],
      annotations: annotations,
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      line: {
        borderColor: 'rgba(255, 209, 0, 1)',
        tension: 0.1,
      },
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        left: 65,
        right: 65
      }
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: (t, d) => `${Math.round(d.labels[t[0].index] / 60)} min.`,
        label: (t, _d) => `Sforzo percepito: ${t.yLabel}`,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          id: 'x-axis-effort',
          gridLines: {
            display: true,
            drawTicks: true,
            tickMarkLength: 3,
            drawOnChartArea: false,
          },
          ticks: {
            display: true,
            beginAtZero: true, // minimum value will be 0.
            stepSize: 2,
            suggestedMax: xMaxForTicks,
            max: xMaxForTicks,
            callback: () => "",
          },
        },
      ],
      yAxes: [
        {
          id: 'y-axis-effort',
          gridLines: {
            display: true,
            drawTicks: true,
            tickMarkLength: 3,
          },
          display: true,
          ticks: {
            suggestedMax: 10,
            beginAtZero: true, // minimum value will be 0.
            stepSize: 2,
            display: true,
            callback: () => "",
          },
        },
      ],
    },
  };
}

export const pulseOximeterOptions = (datasets) => {
  const labels = datasets.map((ds) => ds.label)
  const [heartRateValues, oxygenSaturationValues] = datasets.map((ds) => ds.data.map((point) => point.y))

  const heartRateMax = Math.max(...heartRateValues);
  const heartRateMin = Math.min(...heartRateValues);
  const heartRateAvg = closestToAvg(heartRateValues);
  const heartRateTicksPositions = [
    // heartRateMax,
    heartRateAvg,
    // heartRateMin
  ];

  const oxygenSaturationMax = Math.max(...oxygenSaturationValues);
  const oxygenSaturationMin = Math.min(...oxygenSaturationValues);
  const oxygenSaturationAvg = closestToAvg(oxygenSaturationValues);
  const oxygenSaturationTickPositions = [
    // oxygenSaturationMax,
    oxygenSaturationAvg,
    // oxygenSaturationMin
  ];

  const xAxisTicksPositions = [0, 120, 240, 360, 480, 600];
  const xMaxForTicks = Math.max(...datasets[0].data.map((point) => point.x))

  // build annotations for drawing horizontal and vertical lines 
  const valuesForAxes = {
    'y-axis-heartrate': heartRateTicksPositions,
    'y-axis-saturation': oxygenSaturationTickPositions,
    'x-axis-pulseoximeter': xAxisTicksPositions
  };
  // console.log(datasets[0].data.map((point) => point.x));
  const annotations = Object.entries(valuesForAxes).map(([scaleID, values]) => {
    return values.map((value, index) => {
      return {
        type: "line",
        id: `${value}-line-${index}`,
        mode: scaleID.startsWith('x') ? "vertical" : "horizontal",
        scaleID: scaleID,
        value: value,
        borderColor: "rgba(79,94,112,0.6)",
        borderWidth: 1,
      };
    })
  }).flat();

  return {
    annotation: {
      drawTime: "afterDatasetsDraw",
      events: ["click"],
      annotations: annotations,
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      borderWidth: 0,
      line: {
        tension: 0.2,
      },
      point: {
        radius: 0,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (t, d) {
          return (
            d.datasets[t.datasetIndex].label +
            ": " +
            d.datasets[t.datasetIndex].data[t.index].y +
            (t.datasetIndex == 0 ? "bpm" : "%")
          );
        },
        title: function (t, d) {
          return "";
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          id: 'x-axis-pulseoximeter',
          gridLines: {
            display: true,
            drawTicks: true,
            tickMarkLength: 3,
            drawOnChartArea: false,
          },
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 10,
            maxTicksLimit: 10,
            callback: function (value, _index, _values) {
              // console.log(_values);
              if (xAxisTicksPositions.includes(value)) return value + " min";
              return "";
            },
          },
        },
      ],
      yAxes: [
        {
          id: 'y-axis-heartrate',
          position: "left",
          scaleLabel: {
            display: true,
            // labelString: labels[0],
            fontColor: HEART_RATE_COLOR_RGBA,
            fontSize: 11,
          },
          gridLines: {
            display: true,
            drawTicks: true,
            tickMarkLength: 3,
            drawOnChartArea: false,
          },
          display: true,
          ticks: {
            // suggestedMax: heartRateMax + 15,
            suggestedMin: heartRateMin - 40,
            beginAtZero: false,
            display: true,
            fontColor: HEART_RATE_COLOR_RGBA,
            fontFamily: 'Mont',
            // fontSize: 11,
            stepSize: 1,
            callback: function (value, _index, _values) {
              if (heartRateTicksPositions.includes(value)) return value + " BPM";
              return "";
            },
          },
        },
        {
          id: 'y-axis-saturation',
          position: "right",
          scaleLabel: {
            display: true,
            // labelString: labels[1],
            fontColor: OXYGEN_SATURATION_COLOR_RGBA,
            fontSize: 11,
          },
          gridLines: {
            display: false,
          },
          display: true,
          ticks: {
            suggestedMax: oxygenSaturationMax + 70,
            suggestedMin: oxygenSaturationMin - 35,
            beginAtZero: false, // minimum value will be 0.
            display: true,
            fontColor: OXYGEN_SATURATION_COLOR_RGBA,
            fontFamily: 'Mont',
            // fontSize: 11,
            stepSize: 1,
            callback: function (value, _index, _values) {
              if (oxygenSaturationTickPositions.includes(value)) return value + "%";
              return "";
            },
          },
        },
      ],
    },
  };
};

// Return the one value in values which is closest to the average
const closestToAvg = (values) => {
  const avg = values.reduce((a, b) => a + b, 0) / values.length;
  return values.reduce((prev, curr) => Math.abs(curr - avg) < Math.abs(prev - avg) ? curr : prev);
};

export const initialsOf = (text) => {
  return text.split(' ').map(w => w[0]).join('').toUpperCase()
}