import React from "react";
import style from "./LinkLoginCode.module.sass";

function LinkLoginCode(props) {
  const { linkLoginCode, linkLoginCodeText } = props;

  const renderLoginCode = () => {
    return (
      linkLoginCode.replace(" ", "").trim().split('').map((c, index) => {
        return (
          <div className={style.SingleCharacter} key={index}>
            {c}
          </div>
        );
      })
    );
  };

  return (
    <div className={`${style.LinkLoginCode}`}>
      <div className={style.TextBox}>
        <div className="mb-2 fw-bold" dangerouslySetInnerHTML={{ __html: linkLoginCodeText.linkLoginCodeText1 }}></div>
        <div className="my-1" dangerouslySetInnerHTML={{ __html: linkLoginCodeText.linkLoginCodeText2 }}></div>
        <div dangerouslySetInnerHTML={{ __html: linkLoginCodeText.linkLoginCodeText3 }}></div>
      </div>
      <div className={style.CodeBox}>
        {renderLoginCode()}
      </div>
    </div>
  );
}

LinkLoginCode.propTypes = {
};

LinkLoginCode.defaultProps = {
};

export default LinkLoginCode
