import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./LiveCalls.module.sass";
import ButtonCall from "./ButtonCall/ButtonCall";
import CallButtons from "./CallButtons/CallButtons";
import VideoGrid from "./VideoGrid/VideoGrid";
import CallNotes from "./CallNotes/CallNotes";
import CallRecordingsForm from "../DetailsCall/CallRecordingsForm/CallRecordingsForm";
import { callApi, getBaseURL } from "../../helpers";

function LiveCalls(props) {
  const {
    callId,
    startUrl,
    stopUrl,
    token,
    channel,
    recordQuery,
    videoName,
    closePath,
    envFayeClientSide,
    form,
    translations,
    newNote,
    callNotesURL,
    callURL,
  } = props;
  // @Andrea: Al posto di usare d-none di boostrap, gestisco i due blocchi (chiamata e callRecording) con questo stato
  const [showCallRecording, setShowCallRecording] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const faye = useRef();
  const agoraclient = useRef();

  useEffect(() => {
    initCall();
  }, []);

  const initCall = () => {
    if (faye.current) return;
    var recorderNotifier = new RecorderNotifier(startUrl, stopUrl);
    agoraclient.current = new AgoraClient(
      token,
      channel,
      recordQuery,
      videoName,
      recorderNotifier
    );

    setTimeout(function () {
      if (rtc.remoteStreams.length == 0 && $(".text-ended").hasClass("d-none")) {
        $(".no-call-hide").addClass("d-none");
        $(".no-call-show").removeClass("d-none");
      }
    }, 30 * 1000);

    faye.current = new Faye.Client(envFayeClientSide);
    faye.current.subscribe("/calls/" + channel, function (data) {
      data = data["data"];
      if (data["type"] == "hangup") {
        alert(data["message"]);
        onCloseCall();
      }
    });
  };

  const onToggleCamera = () => {
    agoraclient.current.toggleCamera();
  };

  const onToggleAudio = () => {
    agoraclient.current.toggleAudio();
  };

  const onToggleScreenShare = () => {
    agoraclient.current.toggleScreenShare();
  };

  const onUpdateHandler = () => {
    window.opener.postMessage(document.location.origin + "/" + callURL, '*');
    window.close();
  };

  const onCloseCall = () => {
    agoraclient.current.close();
    $.post(closePath, function () {
      // window.close();
    });
    setShowCallRecording(true);
  };

  const showCallRecordingContainer = () => {
    return (
      <div className={`${style["call-recording-container"]}`}>
        <div className={`d-flex justify-content-center`}>
          <CallRecordingsForm
            {...form}
            translations={translations}
            updateData={onUpdateHandler}
            isLiveCall={true}
          />
        </div>
      </div>
    );
  };

  const showVideocallContainer = () => {
    return (
      <div className={`${style["videocall-container"]}`}>
        <VideoGrid isRecording={isRecording} />
        <CallNotes newNote={newNote} callId={callId} notesPath={callNotesURL} />
      </div>
    );
  };

  return (
    <div className={`${style.LiveCalls}`}>
      <LiveCallsContext.Provider
        value={{
          translations,
          onToggleCamera,
          onToggleAudio,
          onToggleScreenShare,
          onCloseCall,
          setIsRecording,
        }}
      >
        {showCallRecording
          ? showCallRecordingContainer()
          : showVideocallContainer()}
      </LiveCallsContext.Provider>
    </div>
  );
}

LiveCalls.propTypes = {};

LiveCalls.defaultProps = {};

export const LiveCallsContext = React.createContext();
export default LiveCalls;
