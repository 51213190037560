import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "./ExerciseSequences.module.sass";
import SingleExerciseSequence from "./SingleExerciseSequence/SingleExerciseSequence";
import renderBalanceCharts from "../renderBalanceCharts";

function ExerciseSequences(props) {
  const {
    exerciseGroupedId,
    exerciseGrouped,
    setTodo,
    dataForGraph,
    leftTranslation,
    rightTranslation,
    exerciseType,
    side,
  } = props;
  const sequences = Array.apply(null, Array(setTodo)).map(function (x, i) {
    return null;
  });

  const [showChart, setShowChart] = useState(false);
  const [showChartBalance, setShowChartBalance] = useState(false);
  const [group, setGroup] = useState(0);
  const [currentSequence, setCurrentSequence] = useState(-1);

  const clickOnSequence = (index) => {
    if (index == currentSequence) {
      setCurrentSequence(-1);
      setShowChartBalance(false);
      setShowChart(false);
      setGroup(0);
    } else {
      // se è balance
      if (exerciseType == "BalExerciseResult") {
        setShowChartBalance(true);
      } else {
        setShowChart(true);
      }
      setCurrentSequence(index);
      setGroup(index);
    }
  };

  return (
    <div className={style.exerciseSequence}>
      <div className={style.exerciseSequenceTop}>
        <div className={`row justify-content-center ${style.content}`}>
          {sequences.map((sequence, index) => (
            <div
              className={`${
                index == currentSequence ? style.selected : ""
              } col-12 col-xl-3`}
              key={`sequence_${exerciseGroupedId}_${index}`}
              onClick={() => clickOnSequence(index)}
            >
              <SingleExerciseSequence
                index={index + 1}
                exerciseType={exerciseType}
                sequence={sequence}
                leftTranslation={leftTranslation}
                rightTranslation={rightTranslation}
                exerciseGrouped={exerciseGrouped[index]}
                side={side}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={`${style.exerciseSequenceBottom}`}>
        {showChartBalance && renderBalanceCharts(dataForGraph, group, side)}
        {showChart && renderBalanceCharts(dataForGraph, group, side)}
      </div>
    </div>
  );
}

ExerciseSequences.propTypes = {
  exerciseGroupedId: PropTypes.string,
  setTodo: PropTypes.number,
};

export default ExerciseSequences;
