import React from "react";

const Image = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.6667 5H8.33333C6.48333 5 5 6.48333 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5Z" fill="#FF6E4B" />
      <path d="M16.8889 21.3333L19.1111 24L22.2222 20L26.2222 25.3333H13.7778M28 26.2222V13.7778C28 12.7911 27.2 12 26.2222 12H13.7778C13.3063 12 12.8541 12.1873 12.5207 12.5207C12.1873 12.8541 12 13.3063 12 13.7778V26.2222C12 26.6937 12.1873 27.1459 12.5207 27.4793C12.8541 27.8127 13.3063 28 13.7778 28H26.2222C26.6937 28 27.1459 27.8127 27.4793 27.4793C27.8127 27.1459 28 26.6937 28 26.2222Z" fill="white" />
    </svg>
  );
};

export default Image;
