import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./AddRemoveExercise.module.sass";

const minValue = 1;
const maxValue = 4;

function AddRemoveExercise(props) {
  const { sets, onChangeSets } = props;

  const cssClassForMinus = () => {
    if (sets <= minValue) return style.opacity25;
  };

  const cssClassForPlus = () => {
    if (sets >= maxValue) return style.opacity25;
  };

  const increment = () => {
    if (sets < maxValue) {
      onChangeSets(sets + 1);
    }
  };
  const decrement = () => {
    if (sets > minValue) {
      onChangeSets(sets - 1);
    }
  };

  return (
    <div className={`py-3 text-center ${style.AddRemoveExercise}`}>
      <span
        onClick={decrement}
        className={`me-2 ${style.circle} ${cssClassForMinus()}`}
      >
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.75 7.15H3.25V5.85H9.75V7.15ZM6.5 0C5.64641 0 4.80117 0.168127 4.01256 0.494783C3.22394 0.821439 2.50739 1.30023 1.90381 1.90381C0.684819 3.12279 0 4.77609 0 6.5C0 8.22391 0.684819 9.87721 1.90381 11.0962C2.50739 11.6998 3.22394 12.1786 4.01256 12.5052C4.80117 12.8319 5.64641 13 6.5 13C8.22391 13 9.87721 12.3152 11.0962 11.0962C12.3152 9.87721 13 8.22391 13 6.5C13 5.64641 12.8319 4.80117 12.5052 4.01256C12.1786 3.22394 11.6998 2.50739 11.0962 1.90381C10.4926 1.30023 9.77606 0.821439 8.98744 0.494783C8.19883 0.168127 7.35359 0 6.5 0Z" fill="currentColor"/>
</svg>

      </span>
      <span className={style.sets}>{sets}</span>
      <span
        onClick={increment}
        className={`ms-2 ${style.circle} ${cssClassForPlus()}`}
      >
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.75 7.15H7.15V9.75H5.85V7.15H3.25V5.85H5.85V3.25H7.15V5.85H9.75V7.15ZM6.5 0C5.64641 0 4.80117 0.168127 4.01256 0.494783C3.22394 0.821439 2.50739 1.30023 1.90381 1.90381C0.684819 3.12279 0 4.77609 0 6.5C0 8.22391 0.684819 9.87721 1.90381 11.0962C2.50739 11.6998 3.22394 12.1786 4.01256 12.5052C4.80117 12.8319 5.64641 13 6.5 13C8.22391 13 9.87721 12.3152 11.0962 11.0962C12.3152 9.87721 13 8.22391 13 6.5C13 5.64641 12.8319 4.80117 12.5052 4.01256C12.1786 3.22394 11.6998 2.50739 11.0962 1.90381C10.4926 1.30023 9.77606 0.821439 8.98744 0.494783C8.19883 0.168127 7.35359 0 6.5 0Z" fill="currentColor"/>
          </svg>
      </span>
    </div>
  );
}

AddRemoveExercise.propTypes = {};

AddRemoveExercise.defaultProps = {};

export default AddRemoveExercise;
