import React, { useState, useEffect, useRef } from "react";
import style from "./DoneTable.module.sass";



function DoneTableRow(props) {
  const {
    done,
    typeDone,
    identifier
  } = props

  console.log(props);


  return (
    <div  className={style.tableColumn}>
      <div className={style.valueRow }>{done==true? <div className={style.imgDone}></div> : <div className={style.imgSkip}></div>}</div>
    </div>
  );
}

export default DoneTableRow;
