import React from "react";
import PropTypes from "prop-types";
import style from "./VideoContainer.module.sass";
import { callApi } from "../../../helpers";
import trashHat from "../../../../../assets/images/trash_hat.svg";
import trash from "../../../../../assets/images/empty-trash-bottom.svg";

function VideoContainer(props) {
  const { translations, deleteUrl, url, name, updateData } = props;

  const onClickDeleteCall = () => {
    if (confirm(translations.deleteConfirm)) {
      callApi(deleteUrl, "DELETE", "json", (data) => {
        updateData();
      });
    }
  };

  return (
    <div className={`${style.VideoContainer}`}>
      {url ? (
        <video className={`rounded`} controls>
          <source src={url} type="video/mp4" />
          |Sorry, your browser doesn't support embedded videos.
        </video>
      ) : (
        <div className={style.Disclaimer}>
          {translations.videoDisclaimer}
        </div>
      )}
      <div className={`row ${style.videoDescription}`}>
        <div className={`col-10 px-0`}>
          <div className={`${style.NameText}`}>{name}</div>
        </div>
        <div className={`col-2 position-relative`}>
          <div className={`${style.DeleteCall}`} onClick={onClickDeleteCall}>
            <img src={trashHat} alt="" className={`${style.hat}`} />
            <img src={trash} alt="" className={`${style.bottom}`} />
          </div>
        </div>
      </div>
    </div>
  );
}

VideoContainer.propTypes = {};

VideoContainer.defaultProps = {};

export default VideoContainer;
