import React from "react";
import PropTypes from "prop-types";
import styles from "./Preload.module.sass";

const Preload = (props) => {
  return (
    <div className={styles.preload} >
      <div className={styles.loader} />
    </div>
  );
};

Preload.propTypes = {};

Preload.defaultProps = {};

export { Preload as default };
