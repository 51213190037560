import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import style from "./AuxiliarityMessageInput.module.sass";

function AuxiliarityMessageInput(props) {
  const { placeholder, name, labelText } = props;
  const [message, setMessage] = useState(props.message);
  useEffect(() => {
    document.querySelectorAll('[data-autoresize]').forEach(function (element) {
      element.style.boxSizing = 'border-box';
      var offset = element.offsetHeight - element.clientHeight;
      element.addEventListener('input', function (event) {
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + offset + 'px';
      });
      element.removeAttribute('data-autoresize');
    });
  }, []);

  const onMessageChange = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);
  };


  return (
    <div>
      <div className={`mb-1`}>{labelText}</div>
      <div className={`${style.AuxiliarityMessageInput}`}>
        <textarea data-autoresize rows="2"
          type={"text"}
          value={message}
          placeholder={placeholder}
          name={name}
          onChange={onMessageChange}
        />
      </div>
    </div>
  );
}

AuxiliarityMessageInput.propTypes = {};

AuxiliarityMessageInput.defaultProps = {};

export default AuxiliarityMessageInput;
