import React, { useEffect, useRef, useState } from "react";
import style from "./Tutorial.module.sass";
import TechBox from "./TechBox/TechBox";

function Tutorial(props) {
  const {
    tutorials,
    supportEmail,
    supportText,
    techTitle,
    supportTel,
    whatsappUrl,
    whatsappText,
  } = props;
  const [current, setCurrent] = useState(null);

  const renderTutorialItem = (name) => {
    return <div>{name}</div>;
  };

  const onClickTutorial = (index) => {
    setCurrent(index);
  };

  const renderCurrentTutorial = (tutorial) => {
    return (
      <div className={`flex-column`}>
        <video controls src={tutorial.path} />
        <div className={"mt-2"}>{tutorial?.description}</div>
      </div>
    );
  };

  return (
    <iframe src="https://euleria.health/benvenuto-in-riablo"
            width="100%"
            style={{"marginLeft": "5%", "height": "100vh"}}></iframe>
    // TODO: remove when iframed page ready. Remove Tutorial model also.
    // <div className={`${style.Tutorial}`}>
    //   <div className={`grid-1-2`}>
    //     <div className={`${style.TutorialList} left-list pb-5}`}>
    //       <ul className={`py-4`}>
    //         {tutorials.map((tutorial, index) => {
    //           return (
    //             <li
    //               key={index}
    //               onClick={() => onClickTutorial(index)}
    //               className={`${index == current ? style.active : ""}`}
    //             >
    //               {renderTutorialItem(tutorial.title)}
    //             </li>
    //           );
    //         })}
    //       </ul>
    //       <TechBox
    //         title={techTitle}
    //         supportEmail={supportEmail}
    //         supportText={supportText}
    //         supportTel={supportTel}
    //         whatsappUrl={whatsappUrl}
    //         whatsappText={whatsappText}
    //       />
    //     </div>
    //     <div className={`p-4 ${style.TutorialSelected}`}>
    //       {current !== null && renderCurrentTutorial(tutorials[current])}{" "}
    //     </div>
    //   </div>
    // </div>
  );
}

export default Tutorial;
