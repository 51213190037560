import React, { useContext, useEffect, useRef, Fragment } from "react";
import style from "./EditPlanSummaryBlock.module.sass";
import { EditPlanContext } from "../EditPlan";
import Button from "../../Button/Button";
import TimingBlock from "./TimingBlock/TimingBlock";
import SummuaryExercisesList from "./SummuaryExercisesList/SummuaryExercisesList";
function EditPlanSummaryBlock(props) {
  const { showAlert } = props;
  const { type } = props;
  const { state, dispatcher, removeItem } = useContext(EditPlanContext);
  const contentRef = useRef();

  const renderLists = () => {
    return state.groupedItems.map((group, index) => {
      return (
        <SummuaryExercisesList
          exerciseGroup={group}
          listKey={`group_list_${index}`}
          removeItem={removeItem}
          translations={state.translations}
          firstItem={index == 0}
        />
      );
    });
  };

  const getTotalExercises = () => {
    let total = 0;
    state.groupedItems.map((group) => {
      total += group.items.length;
    });
    return total;
  };

  const renderCustomizeBtn = () => {
    return (
      <Button
        karyBtn
        kariBtnGreenOver
        kariBtnArrowRight
        kariBtnDisable={getTotalExercises() == 0 || state.incompleteCardioSequence}
        href={state.customizeUrl}
        waitContent={"wait"}
      >
        {state?.customizeText}
      </Button>
    );
  };

  return (
    <div className={`${style.EditPlanSummaryBlock}`}>
      <div className={`${style.yourPlan}`}> {state?.translations?.title}</div>
      <div className={`${style.EditPlanSummaryBlockContent} ${state.timeCalculation && style.timeBlockVisible}`} 
           ref={contentRef}>
        <div className={`${state.incompleteCardioSequence && style.reactsItems}`}>
          {renderLists()}
        </div>
        {state.incompleteCardioSequence &&
          <div className={style.warning}>
            {state?.translations?.intensities.warning}
            <span className={style.easy}> {state?.translations?.intensities.low}</span>
            ,
            <span className={style.medium}> {state?.translations?.intensities.medium} </span>
            {state?.translations?.intensities.and}
            <span className={style.difficult}> {state?.translations?.intensities.high}</span>
          </div>
        }
      </div>
      <div>
        { type != "reacts_saved_sequences" &&
          <TimingBlock
            durationText={state.durationText}
            duration={state.duration}
            message={state.durationWarning}
            showAlert={showAlert}
            showTime={state.timeCalculation}
          />
      }
      </div>
      <div className={`${style.buttons}`}>
        {renderCustomizeBtn()}
      </div>
    </div>
  );
}

EditPlanSummaryBlock.propTypes = {};

EditPlanSummaryBlock.defaultProps = {};

export default EditPlanSummaryBlock;
