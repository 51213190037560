import React from "react";

const Main = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.875 6.90625C17.875 7.1263 17.7946 7.31673 17.6338 7.47754C17.473 7.63835 17.2826 7.71875 17.0625 7.71875L0.8125 7.71875C0.592447 7.71875 0.40202 7.63835 0.241211 7.47754C0.0804024 7.31673 0 7.1263 0 6.90625V1.21875C0 0.998697 0.0804024 0.808269 0.241211 0.647461C0.40202 0.486653 0.592447 0.40625 0.8125 0.40625L17.0625 0.40625C17.2826 0.40625 17.473 0.486653 17.6338 0.647461C17.7946 0.808269 17.875 0.998697 17.875 1.21875V6.90625ZM1.625 6.09375L16.25 6.09375V2.03125L1.625 2.03125V6.09375ZM13.0508 15.8438C13.0508 16.0638 12.9704 16.2542 12.8096 16.415C12.6488 16.5758 12.4583 16.6562 12.2383 16.6562H0.8125C0.592447 16.6562 0.40202 16.5758 0.241211 16.415C0.0804024 16.2542 0 16.0638 0 15.8438V10.1563C0 9.91927 0.0804024 9.72461 0.241211 9.57227C0.40202 9.41992 0.592447 9.34375 0.8125 9.34375L12.2383 9.34375C12.4583 9.34375 12.6488 9.41992 12.8096 9.57227C12.9704 9.72461 13.0508 9.91927 13.0508 10.1562L13.0508 15.8438ZM1.625 15.0313H11.4258L11.4258 10.9688H1.625V15.0313ZM26 24.7812C26 25.0013 25.9196 25.1917 25.7588 25.3525C25.598 25.5133 25.4076 25.5938 25.1875 25.5938H0.8125C0.592447 25.5938 0.40202 25.5133 0.241211 25.3525C0.0804024 25.1917 0 25.0013 0 24.7812V19.0938C0 18.8737 0.0804024 18.6833 0.241211 18.5225C0.40202 18.3617 0.592447 18.2812 0.8125 18.2812H25.1875C25.4076 18.2812 25.598 18.3617 25.7588 18.5225C25.9196 18.6833 26 18.8737 26 19.0938V24.7812ZM1.625 23.9688H24.375V19.9062H1.625V23.9688Z" fill="#F5FAFF"/>
    </svg>
  );
};

export default Main;
