import React from "react";
import style from "./SingleExerciseSequence.module.sass";

function LeftRightRepetition(props) {
  const { left, right, repetitionOrDuration } = props;
  return (
    <div className={style.singleExerciseSequenceRight}>
      <div
        className={`${style.singleExerciseSequenceLeftRightValue} ${
          style.singleExerciseSequenceBottom
        } ${
          repetitionOrDuration !== "duration" ? style.singleExerciseScore : ""
        }`}
      >
        <div className={style.singleExerciseSequenceLeftRightValueLeft}>
          <div>
            {left}{" "}
            {repetitionOrDuration == "duration" && left != "-" && (
              <span>s</span>
            )}
          </div>
        </div>
        <div className={style.singleExerciseSequenceLeftRightValueRight}>
          <div>
            {right}{" "}
            {repetitionOrDuration == "duration" && right != "-" && (
              <span>s</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default LeftRightRepetition;
