import React from "react";

const Chats = () => {
  return (
    <svg
      height="26"
      viewBox="0 0 28 26"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m8.92578125 263.25 5.27343755-5.214844h10.9570312c.2734375 0 .4101562-.175781.4101562-.527344v-12.539062c0-.3125-.1367187-.46875-.4101562-.46875h-19.5703125c-.390625 0-.5859375.15625-.5859375.46875v12.539062c0 .117188.06835938.234376.20507812.351563.13671876.117187.26367188.175781.38085938.175781h3.33984375zm17.64062495 4.75v-5.214844h3.3398438c.3125 0 .46875-.175781.46875-.527344v-12.539062c0-.351562-.15625-.527344-.46875-.527344h-1.875v9.785156c0 1.015626-.4882812 1.523438-1.4648438 1.523438h-10.6054687l-2.2851563 2.285156h7.6171876z"
        fill="none"
        stroke="#FFFFFF"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(-4 -243)"
      />
    </svg>
  );
};

export default Chats;
