import React, { useEffect, useRef, useState } from "react";
import { strengthSingleRepetitionChart } from "../../strengthCharts";
import style from "./CompareRepetitionsCharts.module.sass"

function CompareRepetitionsCharts(props) {
  const {
    filter,
    hasSide,
    left,
    leftData,
    measure,
    repetitionChartId,
    right,
    rightData
  } = props

  const [selectedLeftRepetition, setSelectedLeftRepetition] = useState(0)
  const [selectedRightRepetition, setSelectedRightRepetition] = useState(0)

  const chartContainer = useRef()

  useEffect(() => {
    drawSelectedRepetitionChart()
  }, [selectedLeftRepetition, selectedRightRepetition])

  const getRepetitonChartData = () => {
    let repetitionChartData = {
      samples: [],
      colors: ["#FFA411", "#A778FF"],
      maxValue: 0
    }
    if(!hasSide) {
      repetitionChartData.colors = ["#04CBFF"]
    }
    if(leftData.samples) {
      repetitionChartData.samples.push(leftData.samples[selectedLeftRepetition])
      repetitionChartData.maxValue = Math.max(leftData.maxValue, repetitionChartData.maxValue)
    }
    if(rightData.samples) {
      repetitionChartData.samples.push(rightData.samples[selectedRightRepetition])
      repetitionChartData.maxValue = Math.max(rightData.maxValue, repetitionChartData.maxValue)
    }
    return repetitionChartData
  }

  const drawSelectedRepetitionChart = () => {
    let canvas;
    let canvasContainerRef = document.getElementById(repetitionChartId);
    if (canvasContainerRef) {
      canvas = canvasContainerRef.querySelector("canvas");
      if (canvas) {
        canvas.remove();
      }
      canvas = document.createElement("canvas");
      canvasContainerRef.appendChild(canvas);
    }
    chartContainer.current = new Chart4(
      canvas,
      strengthSingleRepetitionChart(
        repetitionChartId, 
        getRepetitonChartData(),
        measure
      )
    )
  }

  const switchThumbClass = (selectedRepetition, index) => {
    if(selectedRepetition == index) {
      return { opacity: "1" }
    } else {
      return { opacity: "0" }
    }
  }

  const renderSwitchBar = (side, selectedSideRepetition, repetitions) => {
    return (
      <div className={style.bar}>
        {
          [...Array(repetitions)].map((rep, index) => {
            return (
              <div className={style.thumb}
                    onClick={() => side == 'left' ? setSelectedLeftRepetition(index) : setSelectedRightRepetition(index)}
                    style={switchThumbClass(selectedSideRepetition, index)}
                    key={`${index}-${side}`}>
              </div>
            )
          })
        }
      </div>
    )
  }

  const renderTriesNumbersRow = (side, selectedSideRepetition, repetitions) => {
    return (
      <div className={style.triesNumbersRow}>
        <div className={style.try}>{filter.try}</div>
        {
          [...Array(repetitions)].map((rep, index) => {
            return (
              <div className={`${style.tryNumber} ${selectedSideRepetition == index && style.selectedTry}`} 
                    key={`rep-${side}-${index}`}>
                {index + 1}
              </div>
            )
          })
        }
      </div>
    )
  }

  const painValueColor = (painValue) => {
    if(painValue != "-") {
      if(painValue <= 3) {
        return style.low
      } else if(painValue >= 4 && painValue <= 7) {
        return style.medium
      } else {
        return style.high
      }
    }
  }

  return (
    <div className={style.compareRepetitions}>
      <div className={style.filtersRow}>
        {leftData.samples && <div className={style.filterBlock}>
          <div className={style.switchBarRow}>
            {hasSide && 
              <div className={`${style.side} ${style.left}`}>{leftData.label}</div>
            }
            {leftData.samples.length > 1 && renderSwitchBar("left", selectedLeftRepetition, leftData.samples.length)}
          </div>
          {renderTriesNumbersRow("left", selectedLeftRepetition, leftData.samples.length)}
          <div className={style.separatorRow}></div>
          <div className={style.painRow}>
            <div className={style.label}>{left.pain.label}</div>
            <div className={`${style.value} ${painValueColor(leftData.painValues[selectedLeftRepetition])}`}>{leftData.painValues[selectedLeftRepetition]}</div>
            <div className={style.of}>{left.pain.of}</div>
            <div className={style.maxValue}>{left.pain.maxValue}</div>
          </div>
        </div>}

        {rightData.samples && <div className={style.filterBlock}>
          <div className={style.switchBarRow}>
            <div className={`${style.side} ${style.right}`}>{rightData.label}</div>
            {rightData.samples.length > 1 && renderSwitchBar("right", selectedRightRepetition, rightData.samples.length)}
          </div>
          {renderTriesNumbersRow("right", selectedRightRepetition, rightData.samples.length)}
          <div className={style.separatorRow}></div>
          <div className={style.painRow}>
            <div className={style.label}>{right.pain.label}</div>
            <div className={`${style.value} ${painValueColor(rightData.painValues[selectedRightRepetition])}`}>{rightData.painValues[selectedRightRepetition]}</div>
            <div className={style.of}>{right.pain.of}</div>
            <div className={style.maxValue}>{left.pain.maxValue}</div>
          </div>
        </div>}
      </div>
      <div className={style.chartRow}
           id={repetitionChartId}
           ref={chartContainer}>
      </div>
    </div>
  )
}

export default CompareRepetitionsCharts