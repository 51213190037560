import React, { useEffect, useRef, useState } from "react";
import style from "./Calls.module.sass";
import Calendar from "./Calendar/Calendar";
import NewCallBlock from "./NewCallBlock/NewCallBlock";
import CallsList from "./CallsList/CallsList";
import { callApi } from "../../helpers";

function Calls(props) {
  const { calendarURL, callsURL, newCall, offsetDate } = props;
  const getTodayDate = () => {
    const d = new Date();
    const year = d.getFullYear() + "";
    let month = d.getMonth() + 1 + "";
    let day = d.getDate() + "";
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    return "" + year + "-" + month + "-" + day;
  };
  const todayDate = getTodayDate();
  const [currentDate, setCurrentDate] = useState(props.currentDate ? props.currentDate : todayDate);
  const [editCall, setEditCall] = useState(null);
  const [randomURLParams, setRandomURLParams] = useState(0);

  const onClickSpecial = (date) => {
    setCurrentDate(date);
  };

  const onCancelNewCall = () => {
    setEditCall(null);
  };

  const forceUpdate = () => {
    setRandomURLParams(randomURLParams + 1);
  };

  const updateCall = (call, editUrl, updateUrl) => {
    callApi(editUrl, "GET", "json", (data) => {
      setEditCall({ ...data, updateUrl });
    });
  };

  const createNewCall = (date, id, locale) => {
    callApi(
      newCall.newCallURL,
      "POST",
      "json",
      (data) => {
        forceUpdate();
      },
      null,
      null,
      {
        body: JSON.stringify({
          scheduled_date: date,
          patient_id: id,
        }),
      }
    );
  };

  const onUpdateCall = (date, id, url, locale) => {
    callApi(
      url,
      "PATCH",
      "json",
      (data) => {
        setEditCall(null);
        forceUpdate();
      },
      null,
      null,
      {
        body: JSON.stringify({
          scheduled_date: date,
          patient_id: id,
        }),
      }
    );
  };

  return (
    <div className={`${style.Calls}`}>
      <div className={`${style.CallsContent}`}>
        <div>
          <Calendar
            calendarURL={`${calendarURL}`}
            onClickSpecial={onClickSpecial}
            offsetDate={offsetDate}
            randomURLParams={randomURLParams}
            currentDate={currentDate}
          />
        </div>
        <div>
          <CallsList
            url={`${callsURL}?r=${randomURLParams}&date=${currentDate}`}
            urlFuture={`${callsURL}?r=${randomURLParams}`}
            forceUpdate={forceUpdate}
            updateCall={updateCall}
          />
        </div>
      </div>
      <NewCallBlock
        {...newCall}
        editCall={editCall}
        onCancel={onCancelNewCall}
        onUpdateCall={onUpdateCall}
        onCreateNewCall={createNewCall}
      />
    </div>
  );
}

Calls.propTypes = {};

Calls.defaultProps = {};

export default Calls;
