import React from "react";
import style from "./SummaryBlockItem.module.sass";

import CloseIcon from "./assets/cross_circle.svg";
import DisabledCloseIcon from "./assets/cross_circle_gray.svg";
import LinearIcon from "./assets/cross_circle.svg";
import IntervalIcon from "./assets/cross_circle.svg";
import SequenceTypology from "../../../Sequence/SequenceTypology";

function SummaryBlockItem(props) {
  const { id, intensity, tool, title, deleteUrl, removeItem, showClose, showIcon, iconCode, undeletable, undeletableTooltip } = props;

  const onClickRemove = () => {
    if(!undeletable) {
      removeItem(deleteUrl);
    }
  };

  const renderCloseBnt = () => {
    return (
      <div className={`${style.closeIcon} ${undeletable && style.undeletable} ${undeletable && 'auto-tooltip'}`} 
           onClick={onClickRemove}
           title={undeletable && undeletableTooltip}>
        <img src={undeletable ? DisabledCloseIcon : CloseIcon} />
      </div>
    );
  };

  const renderIcon = () => {
    return (
      <span>
        <img class="me-2" src={retrieveIcon()} />
      </span>
    );
  }

  const retrieveIcon = () => {
    switch (iconCode) {
      case 'linear': return LinearIcon;
      case 'interval': return IntervalIcon;
      default: return IntervalIcon;
    }
  }

  return (
    <div className={`${style.SummaryBlockItem}`}>
      <div className={`${style.name}`}>
        {showIcon && renderIcon()}
        <div className={style.title} style={tool || intensity ? {"marginBottom": "5px"} : {}}>{title}</div>
        {tool && <SequenceTypology code={tool.code} name={tool.title} />}
        {intensity && <SequenceTypology code={intensity.code} name={intensity.title} />}
      </div>
      {showClose && renderCloseBnt()}
    </div>
  );
}

SummaryBlockItem.propTypes = {};

SummaryBlockItem.defaultProps = {};

export default SummaryBlockItem;
