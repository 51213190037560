import React from "react";

const KariPlans = (props) => {
  return (
    <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0459 0.189561C15.3169 0.0250501 15.6556 0.0199506 15.9314 0.176231L30.9314 8.67623C31.2771 8.87212 31.4474 9.27635 31.3461 9.66056C31.2448 10.0448 30.8973 10.3125 30.5 10.3125H28.375V25.9375H26.625V8.24739L15.5153 1.95187L5.375 8.10847L5.375 25.9375H3.625V10.3125H1.5C1.10755 10.3125 0.763108 10.0512 0.657361 9.67327C0.551615 9.29534 0.710434 8.89323 1.04589 8.68956L15.0459 0.189561Z" fill="#F1F9FE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.66098 8.9375C8.8649 8.9375 8.21954 9.59438 8.21954 10.4047V14.0726C8.21954 14.8829 8.8649 15.5398 9.66098 15.5398H13.2646C14.0606 15.5398 14.706 14.8829 14.706 14.0726V10.4047C14.706 9.59438 14.0606 8.9375 13.2646 8.9375H9.66098ZM9.66098 10.4047H13.2646V14.0726H9.66098V10.4047Z" fill="#F1F9FE"/>
      <path d="M19.951 14.7486L23.6249 10.4415L22.5952 9.48769L19.3818 13.255L18.06 12.0153L17.1384 13.0825L18.9753 14.8053C19.1117 14.9332 19.2913 14.9991 19.4745 14.9885C19.6576 14.9778 19.8291 14.8915 19.951 14.7486Z" fill="#F1F9FE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.21954 19.2888C8.21954 18.4785 8.86489 17.8216 9.66098 17.8216H13.2646C14.0606 17.8216 14.706 18.4785 14.706 19.2888V22.9567C14.706 23.767 14.0606 24.4239 13.2646 24.4239H9.66098C8.86489 24.4239 8.21954 23.767 8.21954 22.9567V19.2888ZM13.2646 19.2888H9.66098V22.9567H13.2646V19.2888Z" fill="#F1F9FE"/>
      <path d="M19.951 23.6327L23.6249 19.3256L22.5952 18.3718L19.3818 22.139L18.06 20.8994L17.1384 21.9665L18.9753 23.6894C19.1117 23.8173 19.2913 23.8832 19.4745 23.8726C19.6576 23.8619 19.8291 23.7756 19.951 23.6327Z" fill="#F1F9FE"/>
    </svg>

  );
};

export default KariPlans;
