import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./CallButtons.module.sass";
import ButtonCall from "../ButtonCall/ButtonCall";
import IcoVideo from "../../../../../assets/images/ico-video.svg";
import IcoVideoNo from "../../../../../assets/images/ico-video-no.svg";
import IcoMic from "../../../../../assets/images/ico-mic.svg";
import IcoMicNo from "../../../../../assets/images/ico-mic-no.svg";
import IcoShare from "../../../../../assets/images/ico-share.svg";
import IcoShareNo from "../../../../../assets/images/ico-share-no.svg";
import IcoRec from "../../../../../assets/images/ico-rec.svg";
import IcoStop from "../../../../../assets/images/ico-stop.svg";
import EndCallButton from "../EndCallButton/EndCallButton";
import { LiveCallsContext } from "../LiveCalls";

function CallButtons(props) {
  const {} = props;
  const [isRecording, setIsRecording] = useState(false);
  const {
    onToggleCamera,
    onToggleAudio,
    onToggleScreenShare,
    onCloseCall,
    setIsRecording: setIsRecordingMain,
  } = React.useContext(LiveCallsContext);

  const onClickRecording = () => {
    setIsRecordingMain(!isRecording);
    setIsRecording(!isRecording);
  };

  return (
    <div className={`${style.CallButtons}`}>
      <ButtonCall
        imgOn={IcoVideo}
        imgOff={IcoVideoNo}
        onClick={onToggleCamera}
      />
      <ButtonCall imgOn={IcoMic} imgOff={IcoMicNo} onClick={onToggleAudio} />
      <div style={{ margin: "0" }}>
        <EndCallButton onClick={onCloseCall} />
      </div>
      <ButtonCall
        imgOn={IcoShare}
        imgOff={IcoShareNo}
        onClick={onToggleScreenShare}
      />
      <ButtonCall
        imgOn={IcoRec}
        imgOff={IcoStop}
        onClick={onClickRecording}
        customClass={"record"}
      />
    </div>
  );
}

CallButtons.propTypes = {};

CallButtons.defaultProps = {};

export default CallButtons;
