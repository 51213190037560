import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./Sequences.module.sass";
import Sequence from "./Sequence/Sequence";
import { PersonalSequencePageContext } from "../PersonalSequencePage";

function Sequences(props) {
  const { state, setState } = useContext(PersonalSequencePageContext);
  const { personalSequences, translations } = state;
  const onChangeSequenceName = (newName, index, groupIndex) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.personalSequences[groupIndex].items[index].name = newName;
    setState(newState);
  };

  return (
    <div className={`${style.Sequences}`}>
      <ul>
        {personalSequences.map((groupedPersonalSequences, groupIndex) => {
          return (
            groupedPersonalSequences.items.length > 0  && 
              <div>
                {groupedPersonalSequences.label &&
                  <div className={style.programType}>
                    {groupedPersonalSequences.label}
                  </div>
                }
                {groupedPersonalSequences.items.map((item, index) => {
                  return (
                    <Sequence
                      {...item}
                      key={`session_${item.id}_${item.type}`}
                      translations={translations ? translations : null}
                      index={index}
                      groupIndex={groupIndex}
                      onChangeName={onChangeSequenceName}
                    />
                  )
                })}
              </div>
          )
        })}
      </ul>
    </div>
  );
}

Sequences.propTypes = {};

Sequences.defaultProps = {};

export default Sequences;
