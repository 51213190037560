import React, { useContext, useState } from "react";
import style from "./BulkChangeActions.module.sass";
import LegendLabel from "../../Sequence/LegendLabel";
import RadiosBlock from "./RadiosBlock/RadiosBlock";
import { CustomizationsPageContext } from "../CustomizationsPage";
import { callApi, camelize } from "../../../helpers";

function BulkChangeActions(props) {
  const { switchLicense } = props;
  const { state, dispatcher } = useContext(CustomizationsPageContext);
  const { bulkActions } = state;
  const [selectedOption, setSelectedOption] = useState('home')
  const [trainingTime, setTrainingTime] = useState(state.sequence.trainingDuration)

  const update_global_property = (property, value) => {
    let apiUrl = bulkActions.urls.update
    if(state.sequence.type == 'cardio_personal_sequence' || property == "duration" || state.sequence.type == 'reacts_saved_sequences') {
      apiUrl = apiUrl + "?sequence[" + property + "]=" + value
    } else {
      apiUrl = apiUrl + "?" + property + "=" + value
    }
    callApi(
      apiUrl,
      bulkActions.updateMethod,
      "json",
      (data) => {
        const { duration, durationText, durationWarning, bulk } = data;
        console.log('data', data)
        dispatcher({
          type: "update_global_property",
          value,
          property: camelize(property),
          updatedExercises: bulk['exercises'],
          bulkActionsProperty: property == "xclinic_exercise_typology_id" ? "typology" : property.split("_")[0],
          duration,
          durationText,
          durationWarning
        });
      }
    );
  };

  const onChangeSide = (value) => {
    update_global_property("side_id", value);
  };

  const onChangeTypology = (value) => {
    update_global_property("xclinic_exercise_typology_id", value);
  };

  const onChangeDifficulty = (value) => {
    update_global_property("difficulty_id", value);
  };

  const changeLicense = (license) => {
    if(selectedOption != license) {
      setSelectedOption(license)
      switchLicense(state.licenseSwitch.url)
    }
  }

  const renderEmptyColumn = () => {
    if(state.sequence.trainingDuration == undefined && !state.licenseSwitch) {
      return (
        <div className="col-sm-4"></div>
      )
    }
  }

  const renderLicenseSwitch = () => {
    if(state.licenseSwitch != null) {
      return (
        <div className={`col-sm-4 ${style.switchContainer}`}>
          <div className={style.switchTitle}>{bulkActions?.translations?.title}</div>
          <div className={style.switchRow}>
            <div className={style.switch}>
              {state.licenseSwitch && 
                state.licenseSwitch.licenseLabels.map((licenseLabel) => {
                  return (
                    <div className={`${style.option} ${selectedOption == licenseLabel && style.selected}`}
                        onClick={() => changeLicense(licenseLabel)}>
                      {bulkActions?.translations[licenseLabel]}
                    </div>
                  )
                }) 
              }
            </div>
            <div className={`auto-tooltip ${style.info}`}
                 title={bulkActions?.translations?.switchInfo}
            ></div>
          </div>
        </div>
      )
    }
  }

  const incrementTrainingTime = () => {
    if(trainingTime < state.sequence.trainingDurationMax) {
      let newValue = (trainingTime * 60) + state.sequence.trainingDurationMin * 60
      update_global_property('duration', newValue)
      setTrainingTime(newValue / 60)
    }
  }

  const decrementTrainingTime = () => {
    if(trainingTime > state.sequence.trainingDurationMin) {
      let newValue = (trainingTime * 60) - state.sequence.trainingDurationMin * 60
      update_global_property('duration', newValue)
      setTrainingTime(newValue / 60)
    }
  }

  const renderTrainingTime = () => {
    if(state.sequence.trainingDuration != undefined ) {
      return (
        <div className={`col-sm-4 ${style.trainingTimeContainer}`}>
          <div className={style.trainingTitle}>{state.sequence.trainingText}</div>
          <div className={style.trainingTogglerRow}> 
            <div className={style.timeToggler}>
              <div className={style.button} onClick={() => decrementTrainingTime()}>-</div>
              <div className={style.timeValue}>{state.sequence.trainingDuration}</div>
              <div className={style.button} onClick={() => incrementTrainingTime()}>+</div>
            </div>
            <div className={style.timeMeasureLabel}>{state.sequence.trainingUnit}</div>
          </div>
        </div>
      )
    }
  }

  return (
    <div
      className={`${style.BulkChangeActions} row mb-3 text-center align-padding-right`}
    >
      {renderEmptyColumn()}
      {renderLicenseSwitch()}
      {renderTrainingTime()}
      <div className={`col-sm-1`}>
        <LegendLabel label={bulkActions?.translations?.set} />
      </div>
      <div className={`col-sm-2`}>
        {!props.isReacts &&
          <RadiosBlock
            {...bulkActions.side}
            onChangeRadio={onChangeSide}
            title={bulkActions?.translations?.side}
            message={bulkActions?.translations?.sideNotice}
            selected={bulkActions.side.selected}
          />
        }
      </div>
      {bulkActions.typology &&
        <div className={`col-sm-2 offset-sm-1`}>
          <RadiosBlock
            {...bulkActions.typology}
            onChangeRadio={onChangeTypology}
            title={bulkActions?.translations?.typology}
            selected={bulkActions.typology.selected}
          />
        </div>
      }
      {bulkActions.difficulty &&
        <div className={`col-sm-2 offset-sm-1`}>
          <RadiosBlock
            {...bulkActions.difficulty}
            onChangeRadio={onChangeDifficulty}
            title={bulkActions?.translations?.difficulty}
            selected={bulkActions.difficulty.selected}
          />
        </div>
      }
      {/* <div className={`col-sm-1`}>
        <LegendLabel label={bulkActions?.translations?.repetitionLabel} />
      </div> */}
    </div>
  );
}

BulkChangeActions.propTypes = {};

BulkChangeActions.defaultProps = {};

export default BulkChangeActions;
