import React from "react";
import PropTypes from "prop-types";
import style from "./SingleExerciseSequence.module.sass";
import LeftRightScore from "./LeftRightScore";
import LeftRightRepetition from "./LeftRightRepetition";
function SingleExerciseSequence(props) {
  const {
    index,
    exerciseGrouped,
    exerciseType,
    leftTranslation,
    rightTranslation,
    side,
  } = props;

  function score() {
    let painLeft = 0;
    let painRight = 0;
    let scoreLeft = "-";
    let scoreRight = "-";
    if (side == "none") {
      let scoreNone = "-";
      if (exerciseGrouped) {
        scoreNone = exerciseGrouped.score.none;
      }
      return (
        <div className={style.singleExerciseScoreOrDuration}>
          <div>
            {scoreNone}
            {scoreNone != "-" && <span>XP</span>}
          </div>
        </div>
      );
    } else {
      if (exerciseGrouped != undefined) {
        if (exerciseGrouped?.score?.left != undefined) {
          scoreLeft = exerciseGrouped?.score?.left;
        }
        if (exerciseGrouped?.score?.right != undefined) {
          scoreRight = exerciseGrouped?.score?.right;
        }
        if (exerciseGrouped?.pain?.left) {
          painLeft = exerciseGrouped?.pain?.left;
        }
        if (exerciseGrouped?.pain?.right) {
          painRight = exerciseGrouped?.pain?.right;
        }
      }
      return (
        <LeftRightScore
          leftText={leftTranslation}
          rightText={rightTranslation}
          scoreLeft={scoreLeft}
          scoreRight={scoreRight}
          painLeft={painLeft}
          painRight={painRight}
        />
      );
    }
  }

  function renderSingleRepetition(reps, repetitionOrDuration) {
    const renderSeconds = repetitionOrDuration == "duration" && reps != "-";
    return (
      <div className={style.singleExerciseScoreOrDurationDown}>
        <div>
          {reps}
          {renderSeconds && <span>s</span>}
        </div>
      </div>
    );
  }

  function repetionOrDuration() {
    let repetitionOrDuration = "repetition";
    if (exerciseType == "BalExerciseResult") {
      repetitionOrDuration = "duration";
    }
    let repsLeft = "-";
    let repsRight = "-";
    if (exerciseGrouped && exerciseType == "BalExerciseResult") {
      if (
        exerciseGrouped != undefined &&
        exerciseGrouped?.duration?.none == undefined
      ) {
        if (exerciseGrouped?.duration?.left != undefined) {
          repsLeft = exerciseGrouped?.duration?.left;
        }
        if (exerciseGrouped?.duration?.right != undefined) {
          repsRight = exerciseGrouped?.duration?.right;
        }
      }
    } else if (exerciseGrouped) {
      if (
        exerciseGrouped != undefined &&
        exerciseGrouped?.repetition?.none == undefined
      ) {
        if (exerciseGrouped?.repetition?.left != undefined) {
          repsLeft = exerciseGrouped?.repetition?.left;
        }
        if (exerciseGrouped?.repetition?.right != undefined) {
          repsRight = exerciseGrouped?.repetition?.right;
        }
      }
    }
    if (side !== "none") {
      return (
        <LeftRightRepetition
          left={repsLeft}
          right={repsRight}
          exerciseType={""}
          repetitionOrDuration={repetitionOrDuration}
        />
      );
    } else {
      let repsNone = "-";
      if (exerciseGrouped?.duration?.none  != undefined) {
        repsNone = exerciseGrouped.duration.none;
      }
      if (exerciseGrouped?.repetition?.none  != undefined) {
        repsNone = exerciseGrouped.repetition.none;
      }
      return renderSingleRepetition(repsNone, repetitionOrDuration);
    }
  }

  return (
    <div className={style.singleExerciseSequence}>
      <div className={style.singleExerciseSequenceTop}>
        <div className={style.singleExerciseSequenceLeft}>
          <div>
            {`${index}° `}
            <span>Set</span>
          </div>
        </div>
        {score()}
      </div>
      <div className={style.singleExerciseSequenceBottom}>
        {" "}
        <div className={style.singleExerciseSequenceLeft}>
          <div>{exerciseType == "BalExerciseResult" ? "duration" : "reps"}</div>
        </div>
        {repetionOrDuration()}
      </div>
    </div>
  );
}

SingleExerciseSequence.propTypes = {
  exerciseType: PropTypes.string,
  index: PropTypes.number,
  exerciseGrouped: PropTypes.object,
};

SingleExerciseSequence.defaultProps = {
  exerciseType: "sequence",
  index: 0,
  leftTranslation: "Da tradurre",
  rightTranslation: "Da tradurre",
  exerciseGrouped: null,
};

export default SingleExerciseSequence;
