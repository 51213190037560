export const maxData = (el1, el2) => {
  // Talvolta el2 può essere true invece che un array.
  if (el2 == true) {
    return [];
  }
  if (el1 && el2) {
    if (el1.length > el2.length) {
      return el1;
    } else {
      return el2;
    }
  } else {
    if (el1) return el1;
    if (el2) return el2;
  }
};

export const configChart = (data, borderColor1=undefined) => {
  return {
    type: "line",
    data: data,
    plugins: [
      {
        beforeRender: (chartData) => {
          // configurazione del plugin vedi https://www.chartjs.org/docs/2.9.4/developers/plugins.html
          graphicsSetup(chartData, borderColor1);
        },
      },
    ],
    options: graphicsOptions(),
  };
};

export const updateData = (index, enable, data, chart) => {
  if (chart.current) {
    chart.current.data.datasets[index].data = data.map(
      (val) => val * (enable ? 1 : 0)
    );
    chart.current.update();
  }
};

export const generateData = (label1, label2, labels, el1, el2) => {
  // Talvolta el2 può essere true invece che un array.
  if (el2 == true) {
    el2 = [];
  }
  return {
    labels: labels,
    datasets: [
      {
        label: label1,
        fill: false,
        data: el1,
        borderWidth: 2,
      },
      {
        label: label2,
        fill: false,
        data: el2,
        borderWidth: 2,
      },
    ],
  };
};

export const graphicsSetup = (chartData, borderColor) => {
  const chart = chartData.chart;
  const side = chart.canvas.dataset.side;
  const dataset = chartData.data.datasets[0];
  const model = chartData.data.datasets[0]._meta[Object.keys(dataset._meta)[0]]?.dataset._model;
  const model2 = chartData.data.datasets[1]._meta[Object.keys(dataset._meta)[0]]?.dataset._model;
  const customGradientFill1 = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
  customGradientFill1.addColorStop(0, borderColor[0])
  customGradientFill1.addColorStop(1, borderColor[1])
  const gradientFill2 = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
  gradientFill2.addColorStop(1, "rgba(105, 35, 130,1)");
  gradientFill2.addColorStop(0.61, "rgba(216, 106, 255, 1)");
  model.borderColor = customGradientFill1
  model2.borderColor = gradientFill2
};

export const graphicsOptions = () => {
  return {
    annotation: {
      drawTime: "afterDatasetsDraw",
      events: ["click"],
      annotations: [
        {
          type: "line",
          id: "90-line-1",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 90,
          borderColor: "rgba(79,94,112,0.6)",
          borderWidth: 2,
        },
        {
          type: "line",
          id: "0-line-2",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 0,
          borderColor: "rgba(79,94,112,0.6)",
          borderWidth: 2,
        },
        {
          type: "line",
          id: "90-line-3",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: -90,
          borderColor: "rgba(79,94,112,0.6)",
          borderWidth: 2,
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    bezierCurve: false,
    elements: {
      borderWidth: 0,
      line: {
        tension: 0,
      },
      point: {
        radius: 0,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (t, d) {
          return (
            d.datasets[t.datasetIndex].label +
            " " +
            d.datasets[t.datasetIndex].data[t.index] +
            "°"
          );
        },
        title: function (t, d) {
          return "";
        },
      },
    },

    hover: {
      mode: "index",
      intersect: false,
    },

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            beginAtZero: true, // minimum value will be 0.
          },
        },
      ],
      yAxes: [
        {
          labelling: {
            display: false,
          },
          gridLines: {
            display: true,
            drawBorder: false,
            tickWidth: 10,
            lineWidth: 1,
            tickLength: 5,
          },
          display: true,
          tickWidth: 10,
          ticks: {
            tickWidth: 10,
            tickLength: 5,
            // stepsize: 0.5,
            // OR //
            beginAtZero: true, // minimum value will be 0.
            display: true,
            callback: function (value, index, values) {
              if (value == 90 || value == 0 || value == -90) return value + "°";
              return "";
            },
            stepSize: 10,
            fixedStepSize: 10,
          },
        },
      ],
    },
  };
};

//NON UTILIZZATA - se non verrà ripresa togliere
export const generateHeartRateData = (heartRateData) => {
  return {
    labels: heartRateData.map((d) => d.x),
    datasets: [
      {
        data: heartRateData,
        borderWidth: 2,
        xAxisID: 'x-axis-heartrate',
        yAxisID: 'y-axis-heartrate',
      },
    ],
  };
};

//NON UTILIZZATA - se non verrà ripresa togliere
export const configHeartRate = (data, duration, minHeartRate, maxHeartRate) => {
  return {
    type: "line",
    data: data,
    options: heartRateOptions(data.datasets[0].data, duration, minHeartRate, maxHeartRate)
  };
};

//NON UTILIZZATA - se non verrà ripresa togliere
export const heartRateOptions = (heartRateData, duration, minHeartRate, maxHeartRate) => {
  let maxData = Math.max(...heartRateData.map((el) => el.y))
  maxData = Math.max(...[maxData, maxHeartRate])
  maxData = maxData + parseInt(maxData * 0.1)

  return {
    annotation: {
      drawTime: "afterDatasetsDraw",
      events: ["click"],
      annotations: [
        {
          type: "line",
          id: `min-heartrate-threshold`,
          scaleID: 'y-axis-heartrate',
          value: minHeartRate,
          borderColor: '#B7B7B7',
          borderWidth: 1,
          mode: 'horizontal'
        },
        {
          type: "line",
          id: `max-heartrate-threshold`,
          scaleID: 'y-axis-heartrate',
          value: maxHeartRate,
          borderColor: '#B7B7B7',
          borderWidth: 1,
          mode: 'horizontal'
        }
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      line: {
        borderColor: "rgba(255, 209, 0, 1)",
        tension: 0.1,
      },
      point: {
        radius: 0,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: () => 'Battito cardiaco',
        label: (t, _d) => `Valore: ${t.yLabel} bpm`,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          id: 'x-axis-heartrate',
          gridLines: {
            display: true,
            drawTicks: true,
            tickMarkLength: 3,
            drawOnChartArea: false,
          },
          ticks: {
            display: true,
            beginAtZero: true,
            suggestedMax: duration,
            max: duration,
            maxTicksLimit: 5,
            maxRotation: 0,
            callback: function(value) {
              let time = value / 60
              if(time - parseInt(time) == 0) {
                return `${time} min`
              } else {
                return `${time.toFixed(1)} min`
              }
            }
          },
        },
      ],
      yAxes: [
        {
          id: 'y-axis-heartrate',
          gridLines: {
            display: true,
            drawTicks: true,
            tickMarkLength: 3,
          },
          display: true,
          ticks: {
            suggestedMin: 0,
            suggestedMax: maxData,
            min: 0,
            max: maxData,
            beginAtZero: false,
            display: true,
            stepSize: 1,
            callback: function(value) {
              if(value == 0 || value == maxData || value == minHeartRate || value == maxHeartRate) {
                return `${value} bpm`
              }
            }
          },
        },
      ],
    },
  };
}

const reactsDatasets = (heartRateData) => {
  return heartRateData.map((data, index) => {
    return {
      data: data,
      borderWidth: 2,
      xAxisID: `x-axis-heartrate-${index}`,
      yAxisID: `y-axis-heartrate-${index}`,
    }
  })
}

export const generateReactsHeartRateData = (heartRateData) => {
  let labels = heartRateData.map((samples) => samples.map((elem) => elem.x)).flat()

  return {
    labels: labels,
    datasets: reactsDatasets(heartRateData),
  };
};

export const configReactsHeartRate = (data, duration, sampledDuration, minHeartRate, maxHeartRate, alertHeartRate, alertTime, thresholdLabels) => {
  return {
    type: "line",
    data: data,
    options: reactsHeartRateOptions(data, duration, sampledDuration, minHeartRate, maxHeartRate, alertHeartRate, alertTime, thresholdLabels)
  };
};

const xAxesLabelValue = (duration, value) => {
  if(duration < 120) {
    return `${value} sec`
  } else if ( duration < 420) {
    let minutes = parseInt(value / 60)
    let seconds = value % 60

    if(minutes > 0) {
      seconds = seconds.toString().padStart(2, '0')
      if(seconds > 0) {
        return `${minutes}:${seconds} min`
      } else {
        return `${minutes} min`
      }
    } else {
      return `${seconds} sec`
    }
  } else {
    return `${(value / 60).toFixed(0).replace(/\.0$/, '')} min`
  }
}

const ReactsXAxes = (duration, datasetsLength) => {
  let xAxes = [{
    display: true,
    id: 'x-axis-heartrate-0',
    gridLines: {
      display: true,
      drawTicks: true,
      tickMarkLength: 3,
      drawOnChartArea: true,
      color: "#4E5E70"
    },
    ticks: {
      display: true,
      beginAtZero: true,
      suggestedMax: duration,
      max: duration,
      maxTicksLimit: 7,
      maxRotation: 0,
      callback: function(value) {
        return xAxesLabelValue(duration, value)
      }
    },
  }]
  if(datasetsLength > 1) {
    for(let i = 0; i < datasetsLength; i++) {
      xAxes.push({
        display: true,
        id: `x-axis-heartrate-${i + 1}`,
        gridLines: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
          beginAtZero: true,
          suggestedMax: duration,
          max: duration,
          maxTicksLimit: 5,
          maxRotation: 0
        },
      })
    }
  }

  return xAxes
}

const reactsYAxes = (max, minHeartRate, maxHeartRate, alertHeartRate, datasetsLength, thresholdLabels) => {
  let yAxes = [{
    id: 'y-axis-heartrate-0',
    gridLines: {
      display: true,
      drawTicks: true,
      tickMarkLength: 3,
    },
    display: true,
    ticks: {
      suggestedMin: 0,
      suggestedMax: max,
      min: minHeartRate - 20,
      max: max,
      beginAtZero: false,
      display: true,
      stepSize: 1,
      callback: function(value) {
        if(value == minHeartRate - 20) {
          return `bpm`
        } else {
          switch (value) {
            case minHeartRate:
              return thresholdLabels["min"]
            case maxHeartRate:
              return thresholdLabels["max"]
            case alertHeartRate:
              return thresholdLabels["alert"]
            case max:
              return max
          }
        }
      }
    },
  }]
  if(datasetsLength > 1) {
    for(let i = 0; i < datasetsLength; i++) {
      yAxes.push({
        id: `y-axis-heartrate-${i + 1}`,
        gridLines: {
          display: false,
          drawTicks: true,
          tickMarkLength: 3,
        },
        display: true,
        ticks: {
          suggestedMin: 0,
          suggestedMax: max,
          min: minHeartRate - 20,
          max: max,
          beginAtZero: false,
          display: false,
          stepSize: 1
        },
      })
    }
  }

  return yAxes
}

export const reactsHeartRateOptions = (heartRateData, duration, sampledDuration, minHeartRate, maxHeartRate, alertHeartRate, alertTime, thresholdLabels) => {
  let values = heartRateData.datasets.map((samples) => samples.data.map((elem) => elem.y)).flat()
  let maxData = Math.max(...values)
  if(alertHeartRate) {
    maxData = Math.max(...[maxData, alertHeartRate])
  }
  maxData = Math.max(...[maxData, maxHeartRate])
  maxData = maxData + parseInt(maxData * 0.2)

  return {
    annotation: {
      drawTime: "afterDatasetsDraw",
      events: ["click"],
      annotations: [
        {
          type: "line",
          id: `min-heartrate-threshold`,
          scaleID: 'y-axis-heartrate-0',
          value: minHeartRate,
          borderColor: '#4E5E70',
          borderWidth: 1,
          mode: 'horizontal'
        },
        {
          type: "line",
          id: `max-heartrate-threshold`,
          scaleID: 'y-axis-heartrate-0',
          value: maxHeartRate,
          borderColor: '#FFD35A',
          borderWidth: 1,
          mode: 'horizontal'
        },
        {
          type: "line",
          id: `alert-heartrate-threshold`,
          scaleID: 'y-axis-heartrate-0',
          value: alertHeartRate,
          borderColor: '#FF4F4F',
          borderWidth: 1,
          mode: 'horizontal'
        },
        {
          type: "line",
          id: `alert-time-heartrate-threshold`,
          scaleID: 'x-axis-heartrate-0',
          value: alertTime,
          borderColor: '#FF4F4F',
          borderWidth: 1,
          mode: 'vertical'
        },

      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      line: {
        borderColor: "#F1F9FE",
        tension: 0.1,
      },
      point: {
        radius: 0,
      },
    },
    tooltips: {
      mode: "nearest",
      intersect: false,
      callbacks: {
        title: () => 'Battito cardiaco',
        label: (t, _d) => `Valore: ${t.yLabel} bpm`,
      },
    },
    scales: {
      xAxes: ReactsXAxes(sampledDuration, heartRateData.datasets.length),
      yAxes: reactsYAxes(maxData, minHeartRate, maxHeartRate, alertHeartRate, heartRateData.datasets.length, thresholdLabels)
    },
  };
}
