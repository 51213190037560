import React, { useState, useEffect } from "react";
import { callApi } from "../../helpers";
import Select from "../Select/Select";
import style from "./ActivitiesPreview.module.sass";


function ActivitiesPreview(props) {
  const {
    activeLicenses,
    activityTypes,
    licenses,
    path,
    placeholder,
    prompt } = props;

  const [activities, setActivities] = useState([])
  const [currentOption, setCurrentOption] = useState("")
  const licenseOptions = licenses.length > 1 ? licenses : []
  const selectOptions = licenseOptions.concat(activityTypes)

  useEffect(() => {
    let key = licenses.map(license => license.value).includes(currentOption) ? "license" : "actable_type"
    let fullPath = path + `?${key}=${currentOption}`
    callApi(fullPath, "GET", "json", setActivities)
  }, [currentOption])

  const formattedDate = (date) => {
    if (date.day != null) {
      return (
        <span>
          <span className={style.day}>{date.day}</span>
          <br />
          <span className={style.month}>{date.month}</span>
        </span>
      )
    } else {
      return (
        <span>
          {date}
        </span>
      )
    }
  }

  const goToActivityPath = (path) => {
    window.location.href = path
  }

  const setOptions = () => {
    if (activeLicenses.includes("euleria_lab")) {
      return licenses.concat(activityTypes)
    }
    return licenses
  }

  const activitiesWrapperClass = () => {
    if (activeLicenses.length > 1) {
      return style.activities
    }
    return style.noSelectActivities
  }

  return (
    <div>
      {selectOptions.length > 1 &&
        <div className={style.selectWrapper}>
          <Select
            options={selectOptions}
            prompt={prompt}
            setOption={(option) => setCurrentOption(option.value)}
          />
        </div>
      }
      {selectOptions.length <= 1 &&
        <div className={style.noSelect}></div>
      }
      <div className={activitiesWrapperClass()}>
        {
          activities.map(activity => {
            const separatorClassName = activity.type == 'Call' ? undefined : activity.license
            return (
              <div className={`row ${style.activityPreview}`}
                id={`activity-${activity.activityId}`}
                key={activity.activityId}
                style={{ height: "75px" }}
                onClick={() => goToActivityPath(activity.path)}>
                <div className={`col-sm-3 ${style.activityTime} text-center`}>
                  {formattedDate(activity.dateTime)}
                  <span className={`${style.separator} ${style[`${separatorClassName}`]}`}></span>
                </div>
                <div className={`col-sm-9 ${style.activityDescription}`}>
                  <div className={`${style.activityPatient}`}>
                    {activity.patientNameLastname}
                  </div>
                  <div className={`${style.activityAction} auto-tooltip pointer-event-auto`}
                    data-toggle="tooltip"
                    title={activity.patientNameLastname}>
                    {activity.translatedMessage}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      {activities.length == 0 &&
        <div className={style.placeholder}>
          {placeholder}
        </div>
      }
    </div>
  );
}

export default ActivitiesPreview;
