import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./Commands.module.sass";

function Commands(props){
  const {} = props;

  console.warn("Check all the 'move-out' part and delete animation inside SavedSequence. Sfruttare lo stesso component");

  return (
  <div className={`${style.Commands}`}>
    <div className={`${style.pencil} move-out`}>

    </div>
  </div>
  );
}

Commands.propTypes = {
};

Commands.defaultProps = {
};

export default Commands