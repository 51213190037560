import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./ChatItem.module.sass";
import { callApi } from "../../../helpers";
import { ChatContext } from "../Chat";

function ChatItem(props) {
  const { index, id, chatReadUrl, parsedDate, name, message, toRead, active } =
    props;
  const [readed, setReaded] = useState(!toRead);

  const value = useContext(ChatContext);

  useEffect(() => {
    value.chatListItems[id] = {
      setReaded: setReaded,
    };
  }, []);

  const onClick = () => {
    props.onClick(id);
    sendRead();
  };

  const sendRead = () => {
    callApi(chatReadUrl, "POST", "");
    setReaded(true);
  };

  let rootClasses = style.ChatItem + " clickable";
  if (!readed) {
    rootClasses += " " + style["ChatItem--toRead"];
  }
  if (active) {
    rootClasses += " " + style.active;
  }

  return (
    <div
      className={rootClasses}
      style={{ "--order-in-list": index }}
      onClick={onClick}
    >
      <div className={`${style.leftPart} d-flex`}>
        <div className={`${style.patientName}`}>{name}</div>
        {message && (
          <div className={`${style.lastMessage}`} id={`last-message-${id}`}>
            {message}
          </div>
        )}
      </div>
      <div className={`${style.rightPart} d-flex`}>
        {parsedDate && (
          <div className={`${style.time}`} id={`last-message-time-${id}`}>
            {parsedDate}
          </div>
        )}
      </div>
    </div>
  );
}

ChatItem.propTypes = {};

ChatItem.defaultProps = {
  toRead: false,
};

export default ChatItem;
