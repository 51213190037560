function changeDirection(direction) {
  if(direction == 'asc') {
    return 'desc'
  } else if(direction == 'desc') {
    return 'asc'
  }
}

function sortByContentType(files, direction) {
  if(direction == 'asc') {
    return sortAscByContentType(files)
  } else if(direction == 'desc') {
    return sortDescByContentType(files)
  }
}

function sortByName(files, direction) {
  if(direction == 'asc') {
    return sortAscByName(files)
  } else if(direction == 'desc') {
    return sortDescByName(files)
  }
}

function sortByDate(files, direction) {
  if(direction == 'asc') {
    return sortAscByDate(files)
  } else if(direction == 'desc') {
    return sortDescByDate(files)
  }
}

function sortAscByContentType(files) {
  return (
    files.sort((a, b) => {
      const contentTypeA = a.content_type
      const contentTypeB = b.content_type
      if(contentTypeA < contentTypeB) {
        return -1
      }
      if(contentTypeA > contentTypeB) {
        return 1
      }
      return 0
    })
  )
}

function sortDescByContentType(files) {
  return (
    files.sort((a, b) => {
      const contentTypeA = a.content_type
      const contentTypeB = b.content_type
      if(contentTypeA > contentTypeB) {
        return -1
      }
      if(contentTypeA < contentTypeB) {
        return 1
      }
      return 0
    })
  )
}

function sortAscByName(files) {
  return (
    files.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()
      if(nameA < nameB) {
        return -1
      }
      if(nameA > nameB) {
        return 1
      }
      return 0
    })
  )
}

function sortDescByName(files) {
  return (
    files.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()
      if(nameA > nameB) {
        return -1
      }
      if(nameA < nameB) {
        return 1
      }
      return 0
    })
  )
}

function sortAscByDate(files) {
  return (
    files.sort((a, b) => {
      const dateA = a.created_at
      const dateB = b.created_at
      if(dateA < dateB) {
        return -1
      }
      if(dateA > dateB) {
        return 1
      }
      return 0
    })
  )
}

function sortDescByDate(files) {
  return (
    files.sort((a, b) => {
      const dateA = a.created_at
      const dateB = b.created_at
      if(dateA > dateB) {
        return -1
      }
      if(dateA < dateB) {
        return 1
      }
      return 0
    })
  )
}

export {
  changeDirection,
  sortByContentType,
  sortByName,
  sortByDate
};