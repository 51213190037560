import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./SingleNote.module.sass";

function SingleNote(props){
  const {date, note} = props;

  return (
  <div className={`${style.SingleNote}`}>
    <span className={`small`}>{date}</span>
    {note}
  </div>
  );
}

SingleNote.propTypes = {
};

SingleNote.defaultProps = {
};

export default SingleNote