import ReactComponentsHandler from "react/reactComponentsHandler";

// NOTA BENE QUESTA CLASSE POTRA ESSERE TOLTA DOPO
// CHE AVREMO PORTATO LE ANIMAZIONI E TUTTO IN REACT
export default class DailySequenceCollector{
  static callSessionDetailExercises(sender, url){
    const parent = $(sender).parent();
    const exercises = parent.find(".exercise");

    if(parent.hasClass("expanded")){
      DailySequenceCollector.compress(parent, exercises);
      return;
    }
    if(exercises.length > 0){
      DailySequenceCollector.expand(parent, exercises);
      return;
    }
    const loader = parent.find(".run-loader");
    const progress = parent.find(".progress");
    DailySequenceCollector.showPreload(loader, progress);
    DailySequenceCollector.getData(url, parent, () => {
      const exercises = parent.find(".exercise");
      DailySequenceCollector.hidePreload(loader, progress);
      DailySequenceCollector.expand(parent, exercises);
    })
  }

  static showPreload(loader, progress){
    $(progress).css("opacity", 0.1);
    $(loader).removeClass('d-none');
    DailySequenceCollector.animateRunner(loader);
  }

  static animateRunner(loader){
    const speed = 3;
    TweenLite.to(loader, speed, {
      left: '70%',
      ease: Power1.easeInOut,
      onComplete: () => {
        if(!$(loader).hasClass('d-none')){
          console.log("loop");
          console.log(loader);
          TweenLite.to(loader, speed, {
            left: '0%',
            ease: Power1.easeInOut,
            onComplete: () => {
              DailySequenceCollector.animateRunner(loader);
            }
          })
        }
      }
    })
  }

  static hidePreload(loader, progress){
    $(progress).css("opacity", 1);
    $(loader).addClass('d-none');
  }

  static getData(url, parent, onCompleteFcn){
    $.ajax(url, {
      type: 'GET',
      dataType: 'html',
      error: (jqXHR, textStatus, errorThrown) => {
        console.error("AJAX Error: #{textStatus}")
      },
      success: (data, textStatus, jqXHR) => {
        DailySequenceCollector.onDataSuccess(data, textStatus, jqXHR, parent, onCompleteFcn)
      }

    })
  }

  static onDataSuccess(data, textStatus, jqXHR, parent, onCompleteFcn){
    $(parent).find(".main-content").html(data)
    if(onCompleteFcn){
      onCompleteFcn();
    }
    // success render react
    const reactComponentsHandler = new ReactComponentsHandler();
    reactComponentsHandler.insertReactComponents()
  }

  static getTimeline(parent, exercises){
    let timeline = new TimelineMax();
    timeline.fromTo(parent.find("ul"), .15, {
      height: 0
    },
    {
      height: 'auto'
    }, "openMainBlock");

    timeline.staggerFromTo(exercises, .15, {
      height: 0,
      pointerEvents: 'none'
    },
    {
      height: 'auto',
      pointerEvents: 'all'
    }, 0.025, "openMainBlock");
    timeline.staggerFromTo(exercises, .15, {
      opacity: 0
    },
    {
      opacity: 1
    }, 0.025);
    timeline.staggerFromTo(parent.find(".download"), .15, {
      opacity: 0,
      pointerEvents: 'none'
    },
    {
      opacity: 1,
      pointerEvents: 'all'
    },'-=0.03');
    return timeline;
  }

  static expand(parent, exercises){
    let timeline = DailySequenceCollector.getTimeline(parent, exercises);
    parent.addClass('expanded');
    timeline.play();
  }

  static compress(parent, exercises){
    let timeline = DailySequenceCollector.getTimeline(parent, exercises);
    timeline.eventCallback("onReverseComplete", () => {
      parent.removeClass('expanded');
    })

    timeline.progress(1,true);
    timeline.reverse();

    // Nascondi gli eventuali chart aperti
    TweenLite.to($(parent).find('.chart-exercise'), .15, {opacity: 0, height: 0})
  }
}
