import React from "react";
import iconPain from './../Score/assets/pain.svg'
import brokenHeart from '../../../../../assets/images/heart-broken.svg'

function SliderStatus(props){

  const hasPain = props.hasPain
  const hasPainTooltip = props.hasPainTooltip
  const aboveAlert = props.aboveAlert
  
  return (
    <div>
      { hasPain &&
        <img className="auto-tooltip pointer-events-auto" src={iconPain}
              data-toggle="tooltip"
              style={{position: 'relative', top: '-2px'}}
              title={hasPainTooltip}/>
      }
      { aboveAlert &&
        <img src={brokenHeart}
             style={{position: 'relative', top: '-2px'}}/>
      }
    </div>
  )
}

export default SliderStatus