import React, { Fragment } from "react";
import ExercisesBar from "../../ExercisesBar/ExercisesBar";
import classes from "./KariConnectExerciseResult.module.sass";

function KariConnectExerciseResult(props) {
  const { exercisesPair, index, rightShortText, leftShortText, colors } = props;

  const renderPair = (pairs, labelLeft, labelRight) => {
    return (
      <div className={`${classes.KariConnectExerciseResult}`}>
        <div className={`row`}>
          <div className={`col p-0`}>
            <div className={classes.exerciseBarcontainer}>
              <ExercisesBar exercises={pairs}
                            label={index}
                            showPain={true}
                            noBorder={true}
                            colors={colors}/>
            </div>
          </div>
        </div>
        <div className={`row`}>
          <div className={`${classes.label} col col-6`}>{labelLeft}</div>
          <div className={`${classes.label} col col-6`}>{labelRight}</div>
        </div>
      </div>
    );
  };

  const renderBalance = () => {
    return (
      <Fragment>
        <ExercisesBar
          exercises={[exercisesPair[0]]}
          label={index}
          showPain={true}
          noBorder={true}
          colors={colors}
        />
      </Fragment>
    );
  };

  const renderBar = () => {
    const side1 = exercisesPair[0].side;
    const side2 = exercisesPair[1]?.side;
    if (side1 == "left" && side2 == "right") {
      const pairs = [exercisesPair[0], exercisesPair[1]];
      return renderPair(pairs, leftShortText, rightShortText);
    } else if (side1 == "left") {
      const pairs = [exercisesPair[0], { pain_value: null }];
      return renderPair(pairs, leftShortText);
    } else if (side1 == "right") {
      const pairs = [{ pain_value: null }, exercisesPair[0]];
      return renderPair(pairs, "", rightShortText);
    } else if (side1 == "none") {
      return renderBalance();
    }
    console.log("Side non gestito!!!", side);
  };
  return (
    <div className={classes.KariConnectExerciseResult}>
      <div className="row">
        <div className={`${classes.index} col`}>{index}°</div>
      </div>
      {renderBar()}
    </div>
  );
}

KariConnectExerciseResult.propTypes = {};

KariConnectExerciseResult.defaultProps = {};

export default KariConnectExerciseResult;
