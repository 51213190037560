import React, { useState, useEffect, useRef } from "react";
import style from "./DoneTable.module.sass";



function DoneTableColumn(props) {
  const {
    done,
    done1,
    done2,
    done3,
    typeDone,
    identifier
  } = props

  console.log(props);


  return (
    <div  className={style.tableColumn}>
      <div className={style.valueColumn }>{done1==true? <div className={style.imgDone}></div> : <div className={style.imgSkip}></div>}</div>
      <div className={style.valueColumn}>{done2==true? <div className={style.imgDone}></div>: <div className={style.imgSkip}></div>}</div>
      <div className={style.valueColumn}>{done3==true? <div className={style.imgDone}></div> : <div className={style.imgSkip}></div>}</div>

    </div>
  );
}

export default DoneTableColumn;
