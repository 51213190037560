import React from "react";

const EuleriaLabSequences = () => {
  return (
    <svg width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 0C11.9944 0 11.03 0.368749 10.3189 1.02513C9.60781 1.6815 9.20833 2.57174 9.20833 3.5C9.20833 4.42826 9.60781 5.3185 10.3189 5.97487C11.03 6.63125 11.9944 7 13 7C14.0056 7 14.97 6.63125 15.6811 5.97487C16.3922 5.3185 16.7917 4.42826 16.7917 3.5C16.7917 2.57174 16.3922 1.6815 15.6811 1.02513C14.97 0.368749 14.0056 0 13 0ZM13 2C13.431 2 13.8443 2.15804 14.149 2.43934C14.4538 2.72064 14.625 3.10218 14.625 3.5C14.625 3.89782 14.4538 4.27936 14.149 4.56066C13.8443 4.84196 13.431 5 13 5C12.569 5 12.1557 4.84196 11.851 4.56066C11.5462 4.27936 11.375 3.89782 11.375 3.5C11.375 3.10218 11.5462 2.72064 11.851 2.43934C12.1557 2.15804 12.569 2 13 2ZM5.95833 3C5.24004 3 4.55116 3.26339 4.04325 3.73223C3.53534 4.20107 3.25 4.83696 3.25 5.5C3.25 6.44 3.82417 7.25 4.6475 7.68C5.0375 7.88 5.48167 8 5.95833 8C6.435 8 6.87917 7.88 7.26917 7.68C7.67 7.47 8.00583 7.17 8.255 6.81C7.46417 5.86 7.04167 4.7 7.04167 3.5C7.04167 3.41 7.04167 3.31 7.04167 3.22C6.71667 3.08 6.34833 3 5.95833 3ZM20.0417 3C19.6517 3 19.2833 3.08 18.9583 3.22C18.9583 3.31 18.9583 3.41 18.9583 3.5C18.9583 4.7 18.5358 5.86 17.745 6.81C17.875 7 18.0158 7.15 18.1783 7.3C18.3517 7.45 18.525 7.58 18.7308 7.68C19.1208 7.88 19.565 8 20.0417 8C20.5183 8 20.9625 7.88 21.3525 7.68C22.1758 7.25 22.75 6.44 22.75 5.5C22.75 4.83696 22.4647 4.20107 21.9567 3.73223C21.4488 3.26339 20.76 3 20.0417 3ZM13 9C10.465 9 5.41667 10.17 5.41667 12.5V14H20.5833V12.5C20.5833 10.17 15.535 9 13 9ZM5.1025 9.55C3.01167 9.78 0 10.76 0 12.5V14H3.25V12.07C3.25 11.06 3.9975 10.22 5.1025 9.55ZM20.8975 9.55C22.0025 10.22 22.75 11.06 22.75 12.07V14H26V12.5C26 10.76 22.9883 9.78 20.8975 9.55ZM13 11C17.745 11 16.9275 12 18 12.5H8C9.0725 12 8.255 11 13 11Z" fill="#F0F0F0" />
    </svg>
  );
};

export default EuleriaLabSequences;
