import React, { useEffect, useRef } from "react";
import style from "./OutdoorResultsChart.module.sass";

import { generateReactsHeartRateData, configReactsHeartRate } from "../ExerciseSequence/ExerciseSequenceChart/utils";
function OutdoorResultsChart(props) {
  const {
    colors,
    duration,
    hasHeartRateData,
    heartRateData,
    maxHeartRate,
    minHeartRate,
    alertHeartRate,
    thresholdLabels,
    workRanges,
    title,
    noFc
   } = props;
  const chart = useRef(null);
  const canvasContainerRef = useRef();

  useEffect(() => {
    let canvas;
    if (canvasContainerRef.current && hasHeartRateData) {
      canvas = canvasContainerRef.current.querySelector("canvas");
      if (canvas) {
        canvas.remove();
      }
      canvas = document.createElement("canvas");
      canvasContainerRef.current.appendChild(canvas);
      chart.current = new Chart(
        canvas,
        configReactsHeartRate(
          generateReactsHeartRateData(heartRateData),
          duration,
          duration,
          minHeartRate,
          maxHeartRate,
          alertHeartRate,
          workRanges,
          thresholdLabels
        )
      );
    }
  }, [props]);

  return (
    <div>
      {hasHeartRateData && 
        <div className={style.reactsExerciseResultChart}>
          <div className={style.workRanges} style={{"color": colors.workRanges.inRange}}>
            <div className={style.workRange}>
              <div className={style.value}>{workRanges.inRange.value}</div>
              <div className={style.label}>{workRanges.inRange.label}</div>
            </div>
            <div className={style.workRange} style={{"color": colors.workRanges.aboveMax}}>
              <div className={style.value}>{workRanges.aboveMax.value}</div>
              <div className={style.label}>{workRanges.aboveMax.label}</div>
            </div>
            <div className={style.workRange} style={{"color": colors.workRanges.aboveAlert, "paddingRight": "0px"}}>
              <div className={style.value}>{workRanges.aboveAlert.value}</div>
              <div className={style.label}>{workRanges.aboveAlert.label}</div>
            </div>
          </div>
          <div className={`${style.title}`}>{title}</div>
          <div className={`${style.chartContainer}`} ref={canvasContainerRef}></div>
        </div>
      }
      {!hasHeartRateData && 
        <div className={style.noFc}>{noFc}</div>
      }
    </div>
  );
}

export default OutdoorResultsChart;
