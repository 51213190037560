import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { callApi } from "../../helpers";
import style from "./StripeLoaderPayment.module.sass";

function StripeLoaderPayment(props) {
  const { url, dotsDelay } = props;
  const [showLoader, setShowLoader] = useState(false);
  const dotsRef = useRef();
  const totDots = useRef(0);

  useEffect(() => {
    setTimeout(() => {
      callStripe();
    }, 2000);
    updateDots();
  }, []);

  const updateDots = () => {
    totDots.current++;
    if (totDots.current > 3) {
      totDots.current = 0;
    }
    let dots = "";
    for (let a = 0; a < totDots.current; a++) {
      dots += ".";
    }
    if (dotsRef.current) {
      dotsRef.current.innerHTML = dots;
    }
    setTimeout(updateDots, dotsDelay);
  };

  const callStripe = () => {
    setShowLoader(true);
    callApi(url, "GET", "json", onCallSuccess);
  };

  const redoCall = (delay = 500) => {
    setTimeout(() => {
      callStripe();
    }, delay);
  };

  const onCallSuccess = (data) => {
    console.log(data);
    if (data.error) {
      redoCall();
    } else {
      // setShowLoader(false);
      // Do other call to...
    }
  };

  const renderLoader = () => {
    return (
      <span>
        Loading<span ref={dotsRef}></span>
      </span>
    );
  };

  let rootClass = style.StripeLoaderPayment;

  if (showLoader) {
    rootClass += " " + style.visible;
  }

  return <div className={rootClass}>{renderLoader()}</div>;
}

StripeLoaderPayment.defaultProps = {
  dotsDelay: 500,
};

export default StripeLoaderPayment;
