const differenceChartOptions = () => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'disabled'
      }
    },
    animation: {
      duration: 0,
    },
    borderColor: 'rgba(255, 255, 255, 0.0)',
    cutout: 43,
    rotation: 180,
  }
}


const differenceChartOptionsPDF = () => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'disabled'
      }
    },
    animation: {
      duration: 0,
    },
    borderColor: 'rgba(255, 255, 255, 0.0)',
    cutout: 60,
    rotation: 180,
  }
}

export const strengthDifferenceChart = (leftData, rightData, borderColor = ["#28333F", "#28333F"]) => {
  return {
    type: 'doughnut',
    responsive: false,
    data: {

      datasets: [{
        data: [leftData.value, rightData.value],
        backgroundColor: [leftData.color, rightData.color],

        borderWidth: 10,
        borderRadius: 5
      }],
    },
    options: differenceChartOptions()
  }
}

export const strengthDifferenceChartPDF = (leftData, rightData, borderColor = ["#28333F", "#28333F"], left_color=leftData.color, right_color=rightData.color) => {
  return {
    type: 'doughnut',
    responsive: false,
    data: {

      datasets: [{
        data: [leftData.value, rightData.value],
        backgroundColor: [left_color, right_color],

        borderWidth: 10,
        borderRadius: 5
      }],
    },
    options: differenceChartOptionsPDF()
  }
}

export const strengthOneSideDifferenceChart = () => {
  return {
    type: 'doughnut',
    data: {
      labels: [100],
      datasets: [{
        data: [100],
        backgroundColor: ["rgba(78, 94, 112, 0.5)"],
        hoverBackgroundColor: ["rgba(78, 94, 112, 0.5)"],
        borderWidth: 0
      }],
    },
    options: differenceChartOptions()
  }
}

export const strengthOneSideDifferenceChartPDF = () => {
  return {
    type: 'doughnut',
    data: {
      labels: [100],
      datasets: [{
        data: [100],
        backgroundColor: ["rgba(78, 94, 112, 0.5)"],
        hoverBackgroundColor: ["rgba(78, 94, 112, 0.5)"],
        borderWidth: 0
      }],
    },
    options: differenceChartOptionsPDF()
  }
}
const drawVerticalLineOnHover = (chart) => {
  if(chart.tooltip._active && chart.tooltip._active.length) {
    const ctx = chart.ctx
    ctx.save()
    const activePoint = chart.tooltip._active[0]
    ctx.beginPath()
    ctx.moveTo(activePoint.element.x, chart.chartArea.top)
    ctx.lineTo(activePoint.element.x, chart.chartArea.bottom)
    ctx.lineWidth = 2
    ctx.strokeStyle = 'white'
    ctx.stroke()
    ctx.restore()
  }
}

const strengthChartsDatasets = (data, chartId, avgSamples, sdSamples, avgActive,avgForPDF) => {

  let datasets = []
  data.samples.map((sample, index) => {
    datasets.push({
      data: sample,
      borderColor: avgForPDF ? data.pdfColors[index]  : avgActive  ? data.avgColors[index] : data.colors[index],
      backgroundColor: avgForPDF ? data.pdfColors[index]  : avgActive  ? data.avgColors[index] : data.colors[index],
      borderWidth: 2,
      fill: false,
      xAxisID: `x-${chartId}`,
      yAxisID: `y-${chartId}`,
    })
  })
  if(avgActive) {
    avgSamples.map((sample) => {
      datasets.push({
        data: sample,
        borderColor: avgForPDF ?  "#000" :"#FFF" ,
        backgroundColor: avgForPDF ? "#000" :"#FFF" ,
        borderWidth: 1.5,
        fill: false,
        xAxisID: `x-${chartId}`,
        yAxisID: `y-${chartId}`
      })
    })
    sdSamples.map((sample, index) => {
      datasets.push({
        data: sample,
        borderColor: avgForPDF ? "rgba(78, 94, 112, 0.3)" : "rgba(78, 94, 112, 0.5)",
        backgroundColor: avgForPDF ?"rgba(78, 94, 112, 0.3)" : "rgba(78, 94, 112, 0.5)",
        borderWidth: 1,
        fill: index == 0 ? '+1' : false,
        xAxisID: `x-${chartId}`,
        yAxisID: `y-${chartId}`
      })
    })
  }
  return datasets
}

const strengthChartsScalesOption = (chartId, maxValueX, maxValueY, isLeft, isOnlySide, measure, avgForPDF=false) => {
  let scales = {}

  scales[`x-${chartId}`] = {
    max: maxValueX,
    beginAtZero: true,
    ticks: {
      max: maxValueX,
      beginAtZero: true,
      padding: 5,
      color: "#778BA1",
      font: {
        weight: '600',
        size: 11
      },
      callback: function(value) {
        if(value == (maxValueX - 1)) {
          if(isLeft && !isOnlySide) {
            return " "
          } else {
            return "[s]"
          }
        } else if(value % 10 == 0) {
          return `${value / 10}`
        }
      }
    },
    grid: {
      color: avgForPDF ? "#96A5B6" : '#4E5E70',
    }
  }

  scales[`y-${chartId}`] = {
    max: maxValueY,
    beginAtZero: true,
    ticks: {
      beginAtZero: true,
      stepSize: parseInt(maxValueY / 5),
      max: maxValueY,
      padding: 5,
      color: "#778BA1",
      font: {
        weight: '600',
        size: 11
      },
      callback: function(value) {
        if(true) {
          if(value == maxValueY){
            return `[${measure}]`
          } else {
            return `${value}`
          }
        } else {
          return " "
        }
      }
    },
    grid: {
      color: avgForPDF ? "#96A5B6" : '#4E5E70',
    }
  }

  return scales
}

export const strengthLeftRepetitionsChart = (chartId, data, avgSamples, sdSamples, avgActive, avgLabel, sdLabel, isOnlySide, measure,avgForPDF=false) => {
  const tooltipLineLeft = {
    id: `tooltipLineLeft-${chartId}`,
    beforeDraw: chart => { drawVerticalLineOnHover(chart) }
  }
  
  return {
    type: "line",
    data: {
      labels: avgSamples[0].map((sample) => sample.x),
      datasets: strengthChartsDatasets(data, chartId, avgSamples, sdSamples, avgActive,avgForPDF)
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.1
        },
        point: {
          radius: 0,
        }
      },
      animation: {
        duration: 0
      },
      scales: strengthChartsScalesOption(chartId, data.samples[0].length, data.maxValue, true, isOnlySide, measure,avgForPDF),
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            title: () => '',
            label: (t) => {
              if(avgActive) {
                let datasetCount = data.samples.length + avgSamples.length + sdSamples.length
                if(t.datasetIndex == datasetCount - 3) {
                  return ` ${avgLabel} ${t.parsed.y} ${measure}`
                } else if(t.datasetIndex == datasetCount - 2) {
                  return ` ${sdLabel} ${(t.parsed.y - avgSamples[0][t.dataIndex].y).toFixed(1)} ${measure}`
                } else {
                  return ""
                }
              } else {
                return `${t.parsed.y} ${measure}`
              }
            }
          }
        }
      }
    },
    plugins: [tooltipLineLeft],
  }

}

export const strengthRightRepetitionsChart = (chartId, data, avgSamples, sdSamples, avgActive, avgLabel, sdLabel, isOnlySide, measure,avgForPDF=false) => {
  const tooltipLineRight = {
    id: `tooltipLineRight-${chartId}`,
    beforeDraw: chart => { drawVerticalLineOnHover(chart) }
  }
  
  return {
    type: "line",
    data: {
      labels: avgSamples[0].map((sample) => sample.x),
      datasets: strengthChartsDatasets(data, chartId, avgSamples, sdSamples, avgActive,avgForPDF)
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.1
        },
        point: {
          radius: 0,
        }
      },
      animation: {
        duration: 0
      },
      scales: strengthChartsScalesOption(chartId, data.samples[0].length,  data.maxValue, false, isOnlySide, measure,avgForPDF),
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            title: () => '',
            label: (t) => {
              if(avgActive) {
                let datasetCount = data.samples.length + avgSamples.length + sdSamples.length
                if(t.datasetIndex == datasetCount - 3) {
                  return ` ${avgLabel} ${t.parsed.y} ${measure}`
                } else if(t.datasetIndex == datasetCount - 2) {
                  return ` ${sdLabel} ${(t.parsed.y - avgSamples[0][t.dataIndex].y).toFixed(1)} ${measure}`
                } else {
                  return ""
                }
              } else {
                return `${t.parsed.y} ${measure}`
              }
            }
          }
        }
      }
    },
    plugins: [tooltipLineRight]
  }
}

export const strengthSingleRepetitionChart = (chartId, data, measure) => {
  const tooltipLineCompare = {
    id: `tooltipLineCompare-${chartId}`,
    beforeDraw: chart => { drawVerticalLineOnHover(chart) }
  }

  let scales = {}

  scales[`x-${chartId}`] = {
    max: data.samples[0].length,
    beginAtZero: true,
    ticks: {
      max: data.samples[0].length,
      beginAtZero: true,
      padding: 5,
      color: "#778BA1",
      font: {
        weight: '600',
        size: 11
      },
      callback: function(value) {
        if(value == (data.samples[0].length - 1)) {
          return "[s]"
        } else if(value % 10 == 0) {
          return `${value / 10}`
        }
      }
    },
    grid: {
      color: '#4E5E70',
    }
  }

  scales[`y-${chartId}`] = {
    max: data.maxValue,
    beginAtZero: true,
    ticks: {
      beginAtZero: true,
      stepSize:5,
      max: data.maxValue,
      padding: 5,
      color: "#778BA1",
      font: {
        weight: '600',
        size: 11
      },
      callback: function(value) {
        if(value == data.maxValue){
          return `[${measure}]`
        } else {
          return `${value}`
        }
      }
    },
    grid: {
      color: '#4E5E70',
    }
  }
  
  return {
    type: 'line',
    data: {
      labels: data.samples[0].map((sample) => sample.x),
      datasets: data.samples.map((sample, index) => {
        return {
          data: sample,
          borderColor: data.colors[index],
          backgroundColor: data.colors[index],
          borderWidth: 2,
          fill: false,
          xAxisID: `x-${chartId}`,
          yAxisID: `y-${chartId}`,
        }
      })
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.1
        },
        point: {
          radius: 0,
        }
      },
      animation: {
        duration: 0
      },
      scales: scales,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            title: () => '',
            label: (t) => `${t.parsed.y} ${measure}`
          }
        }
      }
    }, 
    plugins: [tooltipLineCompare]
  }
}