import React, { useEffect, useRef } from "react"
import style from "./StrengthDifferenceChartPDF.module.sass"
import { strengthDifferenceChartPDF, strengthOneSideDifferenceChartPDF } from "../strengthCharts";

function StrengthResult(props) {

  const {
    hasSide,
    license,
    left,
    right,
    avgStrengthLabel,
    measure,
    index,
    difference,
    peakAvgTime,
    borderColor
  } = props

  const chart = useRef(null);

  useEffect(() => {
    if(hasSide) {
      let canvas;
      let canvasContainerRef = document.getElementById(index);
      if (canvasContainerRef) {
        canvas = canvasContainerRef.querySelector("canvas");

        if (canvas) {
          canvas.remove();
  
          
        }
        
        canvas = document.createElement("canvas");
        canvas.id = 'chart'+index;
       canvasContainerRef.appendChild(canvas);
      }

      if(difference.value != "-") {
        

      chart.current=new Chart4(canvas, strengthDifferenceChartPDF(left, right, borderColor, "#ED9B03", "#7932FF"))

      } else {
        chart.current= new Chart4(canvas, strengthOneSideDifferenceChartPDF())
      }
    }
  }, [])

  const renderAvgPeakTime = () => {
    if(hasSide) {
      return (
        <div className={style.peakRow}>
          <div className={style.peakValue}>{left.peakTime} {left.peakTime != "-" && " s"}</div>
          <div className={style.label}>{peakAvgTime}</div>
          <div className={style.peakValue}>{right.peakTime} {right.peakTime != "-" && " s"}</div>
        </div>
      )
    } else {
      return (
        <div className={style.peakRow}>
          <div className={style.label}>{peakAvgTime}</div>
          <div className={style.peakValue}>{left.peakTime}</div>
        </div>
      )
    }
  }

  // const painValueColor = (painValue) => {
  //   if(painValue != "-") {
  //     if(painValue <= 3) {
  //       return style.low
  //     } else if(painValue >= 4 && painValue <= 7) {
  //       return style.medium
  //     } else {
  //       return style.high
  //     }
  //   }
  // }

  const renderLeftSideData = () => {
    return (
      <div className={style.sideData}>
        {hasSide && 
          <div className={style.side} style={{color: "#ED9B03"}}>
            {left.label}
          </div>
        }
        {hasSide && 
          <div className={`${style.avgStrength} ${style.left}`}>
          <div className={style.label}>{avgStrengthLabel}</div>
          <div className={style.value}>
            {left.value != "-" ? left.value + ` ${measure}` : left.value}
          </div>
        </div>
        }
        {!hasSide && 
          <div className={`${style.avgStrength} ${style.center}`}>
          <div className={style.label}>{avgStrengthLabel}</div>
          <div className={style.value}>
            {left.value != "-" ? left.value + ` ${measure}` : left.value}
          </div>
        </div>
        }      
        {/* {license == "euleria_lab" && 
          <div className={`${style.maxPain} ${style.left}`}>
            <div className={style.label}>{left.pain.maxLabel}</div>
            <div className={`${style.value} ${painValueColor(left.pain.value)}`}>
              {left.pain.value}
            </div>
            <div className={style.of}>{left.pain.of}</div>
            <div className={style.of}>{left.pain.maxValue}</div>
          </div>
        } */}
      </div>
    )
  }

  const renderDifferenceChartContainer = () => {
    const diff_color = difference.color == "#FFA411" ? "#ED9B03" : "#7932FF"
    return (
      <div className={style.chartData} id={index} ref={chart}>
        <div className={style.chartBorder}>
          <div className={style.value}>{difference.value}</div>
          {difference.value != "-" && 
            <div className={style.sideLabel} style={{color: diff_color}}>{difference.side}</div>
          }
        </div>
      </div>
    )
  }
  const renderRightSideData = () => {
    return (
      <div className={style.sideData}>
        <div className={style.side} style={{color: "#7932FF"}}>
          {right.label}
        </div>
        <div className={`${style.avgStrength} ${style.right}`}>
          <div className={style.value}>
            {right.value != "-" ? right.value + ` ${measure}` : right.value}
          </div>
          <div className={style.label}>{avgStrengthLabel}</div>
        </div>
        {/* {license == "euleria_lab" &&<div className={style.maxPain}>
          <div className={`${style.value} ${painValueColor(right.pain.value)}`}>
            {right.pain.value}
          </div>
          <div className={style.of}>{right.pain.of}</div>
          <div className={style.of}>{right.pain.maxValue}</div>
          <div className={style.label}>{right.pain.maxLabel}</div>
        </div>} */}
      </div>
    )
  }

  
  return (
    <div className={style.chartColumn}>
      <div className={`${style.dataRow} ${!hasSide && style.noSide}`}>
        {renderLeftSideData()}
        {hasSide && renderDifferenceChartContainer()}
        {hasSide && renderRightSideData()}
      </div>
      {license == "euleria_lab" && peakAvgTime && renderAvgPeakTime()}
    </div>
  )
}

export default StrengthResult