import React from "react";
import style from "./ThresholdMissingModal.module.sass";
import Modal from "../../Modal/Modal";

function ThresholdMissingModal(props) {
  const {
    closeModal,
    editPatientUrl,
    translations
  } = props


  const editPatient = () => {
    window.location.href = editPatientUrl
  }

  return (
    <Modal
      open={true}
      background={true}
      closeOnBackgroundClick={false}
    >
      <div className={style.ThresholdModalContainer}>
        <div className={style.title}>{translations?.title}</div>
        <div className={style.subTitle}>
          {translations?.subtitle}
        </div>
        <div className={style.buttonsWrapper}>
          <div className={style.button}
               onClick={closeModal}>
            {translations?.cancel}
          </div>
          <div className={`${style.button} ${style.editPatient}`}
               onClick={editPatient}>
            {translations?.editPatient}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ThresholdMissingModal;