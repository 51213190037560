import React from "react"
import style from "./PatientForm.module.sass"

function MedicairFields(props) {
  const {
    errors,
    fieldsTranslations,
    fieldLabelStyle,
    selectUseOxygen,
    toggleFieldLabel,
    useOxygen,
    patient
  } = props

  const radioClass = (linkUseOxygen) => {
    if(linkUseOxygen == useOxygen) {
      return `${style.radioButton} ${style.selectedRadio}`
    }
    return style.radioButton
  }

  const inputErrorClass = (inputName) => {
    if(errors != undefined && errors[inputName] != undefined){
      return style.inputWithError
    }
    return ''
  }

  return (
    <span>
      <div style={{marginBottom: '15px'}}>
        <span style={{color: '#F5FAFF', marginRight: '10px'}}>
          {fieldsTranslations.useOxygen}
        </span>
        <span className={`${style.radioWrapper} ${inputErrorClass('use_oxygen')}`}>
          <a href='javascript:void(0)'
             onClick={(e) => selectUseOxygen(e, false)}
             className={radioClass(false)}>
              {fieldsTranslations.useOxygenNo}
          </a>
          <a href='javascript:void(0)'
             onClick={(e) => selectUseOxygen(e, true)}
             className={radioClass(true)}>
              {fieldsTranslations.useOxygenYes}
          </a>
        </span>
      </div>

      <div>
        <div id="o2_quantity-label" style={fieldLabelStyle(patient.o2Quantity)}>
          <span className={style.floatPlaceholder}>
            {fieldsTranslations.o2Quantity}
          </span>
        </div>
        <input type='text'
               placeholder={fieldsTranslations.o2Quantity}
               className={`${style.fullWidthInput} ${inputErrorClass('o2_quantity')}`}
               id='o2_quantity'
               name='o2_quantity'
               defaultValue={patient.o2Quantity}
               onChange={(e) => toggleFieldLabel(e)}/>
        <div className={style.inputError}>{errors["o2_quantity"]}</div> 
      </div>
      <div>
        <div id='o2_quantity_during_exercise-label' style={fieldLabelStyle(patient.o2QuantityDuringExercise)}>
          <span className={style.floatPlaceholder}>
            {fieldsTranslations.o2QuantityDuringExercise}
          </span>
        </div>
        <input type='text'
               placeholder={fieldsTranslations.o2QuantityDuringExercise}
               className={`${style.fullWidthInput} ${inputErrorClass('o2_quantity_during_exercise')}`}
               id='o2_quantity_during_exercise'
               name='o2_quantity_during_exercise'
               defaultValue={patient.o2QuantityDuringExercise}
               onChange={(e) => toggleFieldLabel(e)}/>
        <div className={style.inputError}>{errors["o2_quantity_during_exercise"]}</div> 
      </div>
      <div>
        <div id='o2_fi-label' style={fieldLabelStyle(patient.o2Fi)}>
          <span className={style.floatPlaceholder}>
            {fieldsTranslations.o2Fi}
          </span>
        </div>
        <input type='text'
               placeholder={fieldsTranslations.o2Fi}
               className={`${style.fullWidthInput} ${inputErrorClass('o2_fi')}`}
               id='o2_fi'
               name='o2_fi'
               defaultValue={patient.o2Fi}
               onChange={(e) => toggleFieldLabel(e)}/>
        <div className={style.inputError}>{errors["o2_fi"]}</div> 
      </div>
      <div>
        <div id='o2_fi_during_exercise-label' style={fieldLabelStyle(patient.o2FiDuringExercise)}>
          <span className={style.floatPlaceholder}>
            {fieldsTranslations.o2FiDuringExercise}
          </span>
        </div>
        <input type='text'
               placeholder={fieldsTranslations.o2FiDuringExercise}
               className={`${style.fullWidthInput} ${inputErrorClass('o2_fi_during_exercise')}`}
               id='o2_fi_during_exercise'
               name='o2_fi_during_exercise'
               defaultValue={patient.o2FiDuringExercise}
               onChange={(e) => toggleFieldLabel(e)}/>
        <div className={style.inputError}>{errors["o2_fi_during_exercise"]}</div> 
      </div>
    </span>
  )
  
}

export default MedicairFields