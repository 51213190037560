import React, { Fragment } from "react";
import Image from "./icons/files/Image";
import Audio from "./icons/files/Audio";
import Video from "./icons/files/Video";
import Document from "./icons/files/Document";
import Generic from "./icons/files/Generic";
import Pdf from "./icons/files/Pdf";
import Zip from "./icons/files/Zip";
import style from "./PatientAttachments.module.sass";

function iconFor(contentType) {
  if (contentType.startsWith('audio')) return Audio();
  else if (contentType.startsWith('video')) return Video();
  else if (contentType.startsWith('image')) return Image();
  else if (contentType === 'application/pdf') return Pdf();
  else if (['application/gzip', 'application/x-7z-compressed', 'application/zip'].includes(contentType)) return Zip();
  else if (contentType.startsWith('application') || contentType.startsWith('text')) return Document();
  else return Generic();
}

function FileElement({fetchFiles,
                      file,
                      formUpdateRef,
                      path,
                      submitFormUpdate,
                      uploadedAtText}) {
  
  const formUpdate = (file) => {
    return (
      <form action={`${path}/${file.id}`}
        acceptCharset="UTF-8"
        method="patch"
        ref={formUpdateRef}
        onSubmit={submitFormUpdate}
      >
        <input required="required"
          className="form-control"
          style={{ color: "white", backgroundColor: "transparent" }}
          type="text"
          name="document[name]"
          autoFocus
          defaultValue={file.name}
          onBlur={fetchFiles}
        // AUTOFOCUS
        ></input>
        <input type="submit"
          name="commit"
          value="Submit"
          style={{ display: "none" }}
        ></input>
      </form>
    )
  };

  const fileName = (file) => {
    if (file.renaming) {
      return formUpdate(file)
    } else {
      return file.name
    };
  };

  return (
    <Fragment>
      <div className="me-1">
        {iconFor(file.content_type)}
      </div>
      <div className="ms-1">
        <div className={`${style.fileName}`}>
          {fileName(file)}
        </div>
        <div className={`${style.fileDate}`}>
          {`${uploadedAtText}: ${file.translated_short_date}`}
        </div>
      </div>
    </Fragment>
  )
  
}

export default FileElement