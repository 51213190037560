import React from "react";

const SequenceTypology = (props) => {
  const backgroundColor = () => {
    if(props.code == 'TYPE_BAL') {
      return '#A778FF'
    } else if (props.code == 'TYPE_ISO' || props.code == 'TYPE_REP') {
      return '#00D4A1'
    } else if (props.code == 'FREE_BODY') {
      return '#00D79C'
    } else if (props.code == 'BED') {
      return '#A778FF'
    } else if (props.code == 'WALL_BARS') {
      return '#B7DCFE'
    } else if (props.code == 'PARALLEL_BARS') {
      return '#FFD35A'
    } else if (props.code == 'MIRROR') {
      return '#FF8197'
    } else if (props.code == 1) {
      return '#00D79C'
    } else if (props.code == 2) {
      return '#FBCF58'
    } else if (props.code == 3) {
      return '#FF8197'
    }
  }

  return (
    <div className={`badge badge-secondary text-uppercase`}
         style={{color: backgroundColor(), backgroundColor: '#16181C'}}>
      {props.name}
    </div>
  );
};

export { SequenceTypology as default };
