import React from "react";

const XclinicPlans = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9083 19.5817C16.9083 18.7305 17.5862 18.0404 18.4225 18.0404H22.2081C23.0444 18.0404 23.7223 18.7305 23.7223 19.5817V23.4349C23.7223 24.2861 23.0444 24.9761 22.2081 24.9761H18.4225C17.5862 24.9761 16.9083 24.2861 16.9083 23.4349V19.5817ZM22.2081 19.5817H18.4225V23.4349H22.2081V19.5817Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M33.0916 19.6204L29.2322 24.145C29.1042 24.2951 28.924 24.3858 28.7316 24.397C28.5392 24.4082 28.3505 24.3389 28.2073 24.2046L26.2776 22.3947L27.2458 21.2737L28.6343 22.5759L32.0099 18.6184L33.0916 19.6204Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9083 28.9145C16.9083 28.0632 17.5862 27.3732 18.4225 27.3732H22.2081C23.0444 27.3732 23.7223 28.0632 23.7223 28.9145V32.7676C23.7223 33.6189 23.0444 34.3089 22.2081 34.3089H18.4225C17.5862 34.3089 16.9083 33.6189 16.9083 32.7676V28.9145ZM22.2081 28.9145H18.4225V32.7676H22.2081V28.9145Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M33.0916 28.9532L29.2322 33.4778C29.1042 33.6279 28.924 33.7186 28.7316 33.7298C28.5392 33.7409 28.3505 33.6717 28.2073 33.5373L26.2776 31.7275L27.2458 30.6064L28.6343 31.9087L32.0099 27.9512L33.0916 28.9532Z" fill="#F5FAFF"/>
      <path d="M21.5 15.5L19 13H11.5L15 16.5" stroke="#F5FAFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.5 15.5L31 13H34.75H38.5L35 16.5" stroke="#F5FAFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.5 37L31 39.5L38.5 39.5L35 36" stroke="#F5FAFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.5 37L19 39.5L15.25 39.5L11.5 39.5L15 36" stroke="#F5FAFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default XclinicPlans;
