import React from "react";

const ToolLabel = (props) => {
  const backgroundColor = () => {
    if (props.code == 'TREADMILL') {
      return '#FB5872'
    } else if (props.code == 'EXERCISE_BIKE') {
      return '#58DEFB'
    } else if (props.code == 'HAND_PEDAL') {
      return '#ECFF74'
    } else if (props.code == 'FOOT_PEDAL') {
      return '#40FDAE'
    }
  }

  return (
    <div className={`badge badge-secondary text-uppercase`}
      style={{ color: backgroundColor(), backgroundColor: '#16181C' }}>
      {props.title}
    </div>
  );
};

export { ToolLabel as default };
