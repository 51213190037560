import React, { useEffect, useRef } from "react";
import style from "./ExerciseSequenceChart.module.sass";
import LeftRightChooser from "./LeftRightChooser/LeftRightChooser";

import { maxData, generateData, updateData, configChart } from "./utils";
function ExerciseSequenceChartBalance(props) {
  let { title, xData, yData, xLabel, yLabel, side, borderColor1 } = props;

  const chart = useRef(null);
  const canvasContainerRef = useRef();
  const labels = maxData(xData, yData).map(() => "");
  xLabel = side == "none" ? "" : xLabel + ":";
  const data = generateData(xLabel, yLabel + ":", labels, xData, yData);

  const onEnableLeft = (enable) => {
    updateData(0, enable, xData, chart);
  };
  const onEnableRight = (enable) => {
    updateData(1, enable, yData, chart);
  };

  useEffect(() => {
    let canvas;
    if (canvasContainerRef.current) {
      canvas = canvasContainerRef.current.querySelector("canvas");
      if (canvas) {
        canvas.remove();
      }
      canvas = document.createElement("canvas");
      canvas.dataset.side = side;
      canvasContainerRef.current.appendChild(canvas);
      chart.current = new Chart(canvas, configChart(data, borderColor1));
    }
  }, [props]);

  return (
    <div className={`${style.exerciseSequenceChart}`}>
      <div
        className={`${style.exerciseSequenceChartTitle}`}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <div className={`${style.chartContainer}`} ref={canvasContainerRef}></div>
      {xData.length > 0 && yData.length > 0 ? (
        <LeftRightChooser
          onEnableLeft={onEnableLeft}
          onEnableRight={onEnableRight}
          leftText={xLabel}
          rightText={yLabel}
        />
      ) : null}
    </div>
  );
}

export default ExerciseSequenceChartBalance;
