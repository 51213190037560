import React, { useState } from "react";
import style from "./ProfessionalPage.module.sass";
import Patients from "../Patients/Patients";
import NotificationsBoxPreview from "../NotificationsBoxPreview/NotificationsBoxPreview";
import ActivitiesPreview from "../ActivitiesPreview/ActivitiesPreview";

function ProfessionalPage(props) {
  const {
    activities,
    createPatient,
    firstAccess,
    licenses,
    notifications,
    patients } = props;

  const [selectedTab, setSelectedTab] = useState("activities")

  const professionalSidebar = (tab) => {
    if (tab == "notifications") {
      return (
        <div>
          <div style={{height: "40px"}}></div>
          <NotificationsBoxPreview {...notifications} />
        </div>
      )
    }else if (tab == "activities") {
      return (
        <ActivitiesPreview {...activities} activeLicenses={licenses}/>
      )
    }
  }

  function activeTab(tab) {
    if (tab == selectedTab) {
      return <div className={style.ActiveTab}></div>
    }
  }


  return (
    <div className={`${style.ProfessionalPage}`}>
      <div className={`row text-white`}>
        <div className={`col-sm-4 ${style.smallCol}`}>
          <div className={style.smallColWrapper}>
            <div className={`${style.NotificationsTabs}`}>
              <div
                className={style.NotificationsTab}
                onClick={() => setSelectedTab("activities")}>
                {activities.title}
                {activeTab("activities")}
              </div>
              {licenses.includes("euleria_home") &&
                <React.Fragment>
                  <div className={`${style.Separator}`}></div>
                  <div
                    className={style.NotificationsTab}
                    onClick={() => setSelectedTab("notifications")}>
                    {notifications.title}
                    {activeTab("notifications")}
                  </div>
                </React.Fragment>
              }
            </div>
            {professionalSidebar(selectedTab)}
          </div>
        </div>
        <div className={`col-sm-8`}>
          <Patients
            {...patients}
            createPatient={createPatient}
            firstAccess={firstAccess}
            licenses={licenses} />
        </div>
      </div>
    </div>
  );
}

ProfessionalPage.propTypes = {};

ProfessionalPage.defaultProps = {};

export default ProfessionalPage;
