import React, { useEffect, useRef } from "react";

const ProgressBarTooltip = (props) => {
  const { enabled, date, labelDate, tooltip, tooltipValue } = props;

  const rootRef = useRef(null);

  useEffect(() => {
    if (rootRef.current && tooltip) {
      $(rootRef.current).tooltip();
    }
  }, []);

  const content = () => {
    if (enabled){
      return `<span>${labelDate} <strong>${date}</strong></span><br/>
              <span><strong>${tooltipValue}XP</strong></span>`;
    }
  };

  return (
    <div data-html="true" ref={rootRef} data-placement="top" title={content()}>
      {props.children}
    </div>
  );
};

export default ProgressBarTooltip;
