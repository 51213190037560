import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./AuxiliarityVideoInputName.module.sass";
import { callApi } from "../../../helpers";

function AuxiliarityVideoInputName(props) {
  const { placeholder, name, checkUrl } = props;
  const [value, setValue] = useState(props.value);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    checkOnDb(value);
  }, [value]);

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const checkOnDb = (newValue) => {
    callApi(`${checkUrl}?name=${newValue}`, "GET", "json", (data) => {
      if (data.errors.length > 0 && !errors) {
        setErrors(data.errors);
      } else {
        setErrors(false);
      }
    });
  };

  const printErrors = (errors) => {
    return errors.reduce((prevVal, currentVal, index) => {
      let val = prevVal + currentVal;
      if (index < errors.length - 1) {
        val += " ";
      }
      return val;
    }, "");
  };

  return (
    <div className={`${style.AuxiliarityVideoInputName}`}>
      <input
        type={"text"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoFocus="autofocus"
        name={name}
        maxLength="25"
      />
      <p className={`pt-2 mb-0 text-danger`}>
        {errors.length > 0 ? printErrors(errors) : null}
      </p>
    </div>
  );
}

AuxiliarityVideoInputName.propTypes = {};

AuxiliarityVideoInputName.defaultProps = {};

export default AuxiliarityVideoInputName;
