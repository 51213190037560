import React, { useState } from "react";
import { callApi } from "../../helpers";
import style from "./StrengthResult.module.sass"
import StrengthResultRepetitionsCharts from "./StrengthResultRepetitionCharts/StrengthResultRepetitionsCharts";
import StrengthDifferenceChart from "./StrengthDifferenceChart/StrengthDifferenceChart";
import StrengthDifferenceChartPDF from "./StrengthDifferenceChart/StrengthDifferenceChartPDF";
function StrengthResult(props) {

  const {
    angle,
    avgStrengthLabel,
    color,
    difference,
    hasSide,
    index,
    left,
    license,
    measure,
    movement,
    muscle,
    peakAvgTime,
    repetitionsCharts,
    right
  } = props


  const [chartVisibility, setChartVisibility] = useState(false)
  const [angleValue, setAngleValue] = useState(angle.value)

  const onChangeAngle = (e) => {
    const newValue = e.target.value;
    setAngleValue(newValue);
  };

  const onBlur = (e) => {
    const newValue = e.target.value
    callApi(angle.updateUrl, "PATCH", "json", null, null, null, {
      body: JSON.stringify({strength_muscle_result: { measurement_angle: newValue}})
    })
  }

  const renderMuscleResultInfo = () => {
    return (
      <div className={style.resultRow}>
        <div className={style.sideColumn}> 
          <div className={style.muscleName} style={{color: color}}>
            {muscle}
          </div>
        </div>
        <div className={style.sideColumn}>
          <div className={style.movementRow}>
            <div className={style.label}>{movement.label}</div>
            <div className={style.value}>{movement.value}</div>
          </div>
          {angle.value && 
            <div className={style.angleRow}>
              <div className={style.label}>{angle.label}</div>
              <div className={`${style.value} ${license == 'xclinic' && style.xclinic}`}>
                <input
                  className={license == 'xclinic' && style.xclinic}
                  type={"number"}
                  value={angleValue}
                  maxLength="3"
                  onChange={onChangeAngle}
                  onBlur={onBlur}
                />
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  const painValueColor = (painValue) => {
    if(painValue != "-") {
      if(painValue <= 3) {
        return style.low
      } else if(painValue >= 4 && painValue <= 7) {
        return style.medium
      } else {
        return style.high
      }
    }
  }

  const renderLeftSideData = () => {
    return (
      <div className={style.sideData}>
        {hasSide && 
          <div className={style.side} style={{color: left.color}}>
            {left.label}
          </div>
        }
        <div className={`${style.avgStrength} ${style.left}`}>
          <div className={style.label}>{avgStrengthLabel}</div>
          <div className={style.value}>
            {left.value != "-" ? left.value + ` ${measure}` : left.value}
          </div>
        </div>
        {false && license == "euleria_lab" && 
          <div className={`${style.maxPain} ${style.left}`}>
            <div className={style.label}>{left.pain.maxLabel}</div>
            <div className={`${style.value} ${painValueColor(left.pain.value)}`}>
              {left.pain.value}
            </div>
            <div className={style.of}>{left.pain.of}</div>
            <div className={style.of}>{left.pain.maxValue}</div>
          </div>
        }
      </div>
    )
  }

  const renderDifferenceChartContainer = () => {
    return (
      <div className={style.chartData} id={index} ref={chart}>
        <div className={style.chartBorder}>
          <div className={style.value}>{difference.value}</div>
          {difference.value != "-" && 
            <div className={style.sideLabel} style={{color: difference.color}}>{difference.side}</div>
          }
        </div>
      </div>
    )
  }

  const renderRightSideData = () => {
    return (
      <div className={style.sideData}>
        <div className={style.side} style={{color: right.color}}>
          {right.label}
        </div>
        <div className={`${style.avgStrength} ${style.right}`}>
          <div className={style.value}>
            {right.value != "-" ? right.value + ` ${measure}` : right.value}
          </div>
          <div className={style.label}>{avgStrengthLabel}</div>
        </div>
        {false && license == "euleria_lab" &&<div className={style.maxPain}>
          <div className={`${style.value} ${painValueColor(right.pain.value)}`}>
            {right.pain.value}
          </div>
          <div className={style.of}>{right.pain.of}</div>
          <div className={style.of}>{right.pain.maxValue}</div>
          <div className={style.label}>{right.pain.maxLabel}</div>
        </div>}
      </div>
    )
  }

  const toggleChartVisibility = () => {
    if(license == "euleria_lab") {
      setChartVisibility(!chartVisibility)
    }
  }

  return (
    <div className={style.strengthResult}>
      {renderMuscleResultInfo()}
      <div className={`${style.differenceChartRow} ${!hasSide && style.noSide} ${license == "xclinic" && style.xclinic}`} 
           onClick={() => toggleChartVisibility()}>
        <StrengthDifferenceChart
          hasSide={hasSide}
          left={left}
          right={right}
          difference={difference}
          peakAvgTime={peakAvgTime}
          measure={measure}
          license={license}
          avgStrengthLabel={avgStrengthLabel}
          index={index}
        />
      </div>

      {chartVisibility && license == 'euleria_lab' &&
        <StrengthResultRepetitionsCharts
           {...repetitionsCharts}
           hasSide={hasSide}
           left={left}
           right={right}
           leftChartId={index + "-left"}
           rightChartId={index + "-right"}
           repetitionChartId={index + "-repetition"}
           measure={measure}
        />
      }
    </div>
  )
}

export default StrengthResult