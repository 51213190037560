import React, { useState } from "react";
import style from './SorterMenu.module.sass'
import ArrowUp from "./icons/ArrowUp"
import ArrowDown from "./icons/ArrowDown"

function SorterMenu({ contentTypeDirection,
                      dateDirection,
                      nameDirection,
                      sortBy,
                      sorterLabel 
                    }) {
  const [lastSorterClicked, setLastSorterClicked] = useState(['date'])

  const handleClick = (e, sorter) => {
    sortBy(e, sorter)
    setLastSorterClicked(sorter)
  }

  const sorterClasses = (sorter) => {
    if(sorter == lastSorterClicked){
      return `${style.attachmentsSortersCol} ${style.lastSorterClicked}`
    }
    return style.attachmentsSortersCol
  }

  const arrowForSorting = (direction) => {
    if(direction == 'asc') {
      return ArrowUp()
    } else if(direction == 'desc') {
      return ArrowDown()
    }
  }

  return (
    <div className={`${style.attachmentsSortersRow}`}>
      <a className={sorterClasses('content_type')}
          onClick={(e) => handleClick(e, 'content_type')}
          href='#'>
        {arrowForSorting(contentTypeDirection)} {sorterLabel.contentType}
      </a>
      <a className={sorterClasses('name')}
          onClick={(e) => handleClick(e, 'name')}
          href='#'>
        {arrowForSorting(nameDirection)} {sorterLabel.name}
      </a>
      <a className={sorterClasses('date')}
          onClick={(e) => handleClick(e, 'date')}
          href='#'>
          {arrowForSorting(dateDirection)} {sorterLabel.date}
      </a>
    </div>
  )

}

export default SorterMenu