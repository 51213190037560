import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./PatientStatsChart.module.sass";

function PatientStatsChart(props) {
  const {
    explanation,
    title,
    labels,
    dataLabel,
    scoreLabel,
    scores,
    minutes,
    timeLabel,
    fillColorTop,
    fillColorBottom,
  } = props;
  const titleRef = useRef();
  const chartId = "graphHome";
  const fillColorPattern = [
    [0, fillColorTop],
    [0.3, fillColorTop],
    [1, `rgba(${fillColorBottom}, 0)`],
  ];

  useEffect(() => {
    $(titleRef.current).tooltip();
    initChart();
  }, []);

  const initChart = () => {
    $("#" + chartId).remove();
    $("#cont-" + chartId).append('<canvas id="' + chartId + '"><canvas>');
    const ctx = document.getElementById(chartId).getContext("2d");
    Chart.defaults.global.defaultFontFamily = "Mont";
    Chart.defaults.global.defaultFontSize = 13;
    Chart.defaults.global.tooltips.titleFontFamily = "Mont";
    Chart.defaults.global.tooltips.bodyFontFamily = "Mont";
    Chart.defaults.global.tooltips.displayColors = false;
    const dataMonth = [];
    const months = {
      JAN: ".01",
      FEB: ".02",
      MAR: ".03",
      APR: ".04",
      MAY: ".05",
      JUN: ".06",
      JUL: ".07",
      AUG: ".08",
      SEP: ".09",
      OCT: ".10",
      NOV: ".11",
      DEC: ".12",
    };
    let currentMonth = "";
    if (labels) {
      labels.map((label) => {
        if (isNaN(label)) {
          currentMonth = months[label];
          dataMonth.push(`1${currentMonth}`);
        } else {
          dataMonth.push(`${label}${currentMonth}`);
        }
      });
    }
    const type = "line";
    const data = {
      labels: labels,
      datasets: [
        {
          label: dataLabel,
          data: dataMonth,
          showLine: false,
          pointBackgroundColor: "rgba(255,255,255,0)",
        },
        {
          label: scoreLabel,
          data: scores,
          pointBackgroundColor: "#F0F0F0",
          tension: 0.5,
        },
        {
          label: timeLabel,
          data: minutes,
          showLine: false,
          pointBackgroundColor: "rgba(255,255,255,0)",
        },
      ],
    };
    const options = {
      hover: {
        mode: "point",
        intersect: true,
      },
      tooltips: {
        titleSpacing: 0,
        titleFontSize: 0,
        mode: "index",
        position: "nearest",
        intersect: false,
      },
      spanGaps: true,
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              suggestedMin: 0,
              max: 100,
              padding: 100,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              maxRotation: 0,
            },
            gridLines: {
              color: "rgba(25, 29, 33, 1)",
              lineWidth: 2,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
    const chart = new Chart(ctx, { type, data, options });
    const fillColorGradient = ctx.createLinearGradient(
      0,
      0,
      0,
      $("#" + chartId).height() - 30
    );
    fillColorPattern.map((pair) => {
      fillColorGradient.addColorStop(pair[0], pair[1]);
    });
    chart.data["datasets"][1]["backgroundColor"] = fillColorGradient;
    chart.update();
  };

  return (
    <div className={`${style.PatientStatsChart}`}>
      <div className={`my-4`} title={explanation} ref={titleRef}>
        {title}
      </div>
      <div className={``} style={{ height: "200px" }}>
        <div className={``} id={"cont-graphHome"}>
          <canvas id="graphHome" height="300"></canvas>
        </div>
      </div>
    </div>
  );
}

PatientStatsChart.propTypes = {};

PatientStatsChart.defaultProps = {};

export default PatientStatsChart;
