import React from "react";
import PropTypes from "prop-types";
import styles from "./FullBackgroundLayer.module.sass";

const FullBackgroundLayer = (props) => {
  const { backgroundColor, clickOnBackground, opacity } = props;
  const backgroundStyle = {};
  if (backgroundColor) backgroundStyle["backgroundColor"] = backgroundColor;
  if (props.background) {
    backgroundStyle["opacity"] = opacity;
  } else {
    backgroundStyle["opacity"] = 0;
  }
  if (clickOnBackground) {
    backgroundStyle["cursor"] = "pointer"
  }else{
    backgroundStyle["cursor"] = "default"
  }

  const defaultClickOnBackground = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (clickOnBackground) {
      clickOnBackground()
    }
  }

  return (
    <div
      className={styles.fullBackgroundLayer}
      style={backgroundStyle}
      onClick={(e) => defaultClickOnBackground(e)}
    />
  );
};

FullBackgroundLayer.propTypes = {
  background: PropTypes.bool,
  backgroundColor: PropTypes.string,
  opacity: PropTypes.number
};

FullBackgroundLayer.defaultProps = {
  /** Add or not the background of the modal */
  background: true,
  /** In case background is true, set his color */
  backgroundColor: "rgb(0,0,0)",
  /** Set opacity */
  opacity: 1
};

export { FullBackgroundLayer as default };
