import React, { useEffect, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import style from "./CustomizationsPage.module.sass";
import CustomizationsPageHeader from "./CustomizationsPageHeader/CustomizationsPageHeader";
import BulkChangeActions from "./BulkChangeActions/BulkChangeActions";
import { initState, reducer } from "./CustomizationsPageState";
import ExercisesContent from "./ExercisesContent/ExercisesContent";
import { callApi } from "../../helpers";
function CustomizationsPage(props) {
  const { url } = props;
  const [state, dispatcher] = useReducer(reducer, { url }, initState);

  useEffect(() => {
    callApi(url, "GET", "json", onSuccess);
  }, []);

  const onSuccess = (data) => {
    dispatcher({
      type: "update_state",
      state: data,
    });
  };


  const refreshState = (onEndFcn = null) => {
    callApi(state.url, "GET", "json", (data) => {
      dispatcher({
        type: "update_state",
        state: data,
      });
      if (onEndFcn) {
        onEndFcn();
      }
    });
  };

  const switchLicense = (url) => {
    state.url = url
    callApi(url, "GET", "json", onSuccess)
  }

  return (
    <div className={`${style.CustomizationsPage}`}>
      <div className={style.title}>{state.translations?.title}</div>
      <CustomizationsPageContext.Provider value={{ state, dispatcher, refreshState }}>
        {state.districts != undefined && (
          <div className={`container-fluid`}>
            <CustomizationsPageHeader />
            <BulkChangeActions switchLicense={switchLicense} isReacts={props.isReacts} />
            <ExercisesContent />
          </div>
        )}
      </CustomizationsPageContext.Provider>
    </div>
  );
}

CustomizationsPage.propTypes = {};

CustomizationsPage.defaultProps = {};

export const CustomizationsPageContext = React.createContext({});

export default CustomizationsPage;
