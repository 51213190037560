import React, { useContext } from "react";
import style from "./SingleExerciseSequence.module.sass";
import iconPain from "./../../Score/assets/pain.svg";
import { ExerciseResultContext } from "../../ExerciseResult/ExerciseResult";

function LeftRightScore(props) {
  const { scoreLeft, leftText, scoreRight, rightText, painLeft, painRight } =
    props;

  const value = useContext(ExerciseResultContext);

  const painIcon = () => {
    return (
      <img
        className={`${style.singleExerciseSequenceLeftRightValuePain} auto-tooltip pointer-events-auto`}
        src={iconPain}
      />
    );
  };
  return (
    <div className={style.singleExerciseSequenceRight}>
      <div className={style.singleExerciseSequenceLeftRightValue}>
        <div
          className={`${style.singleExerciseSequenceLeftRightValueLeft} ${
            painLeft > 0 ? style.singleExerciseSequenceLeftRightValueWithPain : ''
          }`}
        >
          <div>{painLeft == 0 ? scoreLeft : "-"}</div>
          <div>{leftText}</div>
          {painLeft > 0 && painIcon()}
        </div>
        <div
          className={`${style.singleExerciseSequenceLeftRightValueRight} ${
            painRight > 0 ?style.singleExerciseSequenceLeftRightValueWithPain : ''
          }`}
        >
          <div>{painRight == 0 ? scoreRight : "-"}</div>
          <div>{rightText}</div>
          {painRight > 0 && painIcon()}
        </div>
      </div>
    </div>
  );
}

export default LeftRightScore;
