import {
  cleanAllFilters,
  cleanFilterBlock,
  initInitialState,
  updateFilters,
} from "./filterHelpers";

const reducer = (state, action) => {
  const newState = JSON.parse(JSON.stringify(state));
  const { currentFilters, jsonDb, currentType } = newState;
  const baseJSONPath = "//items";
  let updatedFirstSelectedCategory = "";
  let doUpdateFilters = true;
  switch (action.type) {
    case "add_filter":
      currentFilters[action.categoryId].push(parseInt(action.filterId));
      currentFilters[action.categoryId] = [
        ...new Set(currentFilters[action.categoryId]),
      ];
      newState.firstSelectedCategory = action.firstSelectedCategory;
      action.dispatcherEditPlanHeader({
        type: "add_filter",
      });
      updatedFirstSelectedCategory = action.firstSelectedCategory;
      break;
    case "remove_filter":
      currentFilters[action.categoryId] = [
        ...new Set(currentFilters[action.categoryId]),
      ].filter((item) => item !== parseInt(action.filterId));
      if (
        action.categoryId == state.firstSelectedCategory &&
        currentFilters[action.categoryId].length == 0
      ) {
        newState.firstSelectedCategory = -1;
        cleanFilterBlock(newState.filters, currentFilters, "body_parts");
        cleanFilterBlock(newState.filters, currentFilters, "movements");
        const totToRemove =
          state.currentFilters["body_parts"].length +
          state.currentFilters["movements"].length;
        action.dispatcherEditPlanHeader({
          type: "remove_tot_filter",
          tot: totToRemove,
        });
      } else {
        newState.firstSelectedCategory = action.firstSelectedCategory;
        action.dispatcherEditPlanHeader({
          type: "remove_filter",
        });
      }
      updatedFirstSelectedCategory = action.firstSelectedCategory;
      break;
    case "clean_filters_block":
      cleanFilterBlock(newState.filters, currentFilters, action.categoryId);
      if (action.categoryId == state.firstSelectedCategory) {
        newState.firstSelectedCategory = -1;
        cleanFilterBlock(newState.filters, currentFilters, "body_parts");
        cleanFilterBlock(newState.filters, currentFilters, "movements");
        const totToRemove =
          state.currentFilters["body_parts"].length +
          state.currentFilters["movements"].length;
        action.dispatcherEditPlanHeader({
          type: "remove_tot_filter",
          tot: totToRemove,
        });
      } else {
        action.dispatcherEditPlanHeader({
          type: "remove_tot_filter",
          tot: action.totToRemove,
        });
      }
      updatedFirstSelectedCategory = newState.firstSelectedCategory;
      break;
    case "clean_all":
      if (Object.entries(jsonDb).length > 0) {
        cleanAllFilters(newState.filters, currentFilters);
        newState.firstSelectedCategory = -1;
        action.dispatcherEditPlanHeader({
          type: "remove_all",
        });
        updatedFirstSelectedCategory = newState.firstSelectedCategory;
      } else {
        doUpdateFilters = false;
      }
      break;
    case "change_current_type":
      newState.currentType = action.value;
      cleanAllFilters(newState.filters, currentFilters);
      updatedFirstSelectedCategory = newState.firstSelectedCategory;
      break;
    case "init_filters":
      newState.filters = {};
      if (action.exercises) {
        newState.jsonDb["exercises"] = action.exercises.data;
        newState.filters["exercises"] = {
          title: action.exercises.title,
          subtitle: action.exercises.subtitle,
          categories: action.exercises.categories,
          name: action.exercises.name,
        };
      }
      if (action.activities) {
        newState.jsonDb["activities"] = action.activities.data;
        newState.filters["activities"] = {
          title: action.activities.title,
          subtitle: action.activities.subtitle,
          categories: action.activities.categories,
          name: action.activities.name,
        };
      }
      newState.filters["items"] = action.filtersGroups;
      initInitialState(newState);
      break;
  }

  if (action.type !== "init_filters" && doUpdateFilters) {
    updateFilters(
      newState.filters,
      currentFilters,
      baseJSONPath,
      jsonDb,
      currentType,
      updatedFirstSelectedCategory
    );
  }

  state = { ...state, ...newState };
  return state;
};

const initialState = {
  jsonDb: {},
  filters: {},
  currentType: "exercises",
  currentFilters: {},
};

export { reducer, initialState };
