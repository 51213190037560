import React, { useEffect, useRef, useState } from "react";
import style from "./AllRepetitionsCharts.module.sass"
import { strengthLeftRepetitionsChart, strengthRightRepetitionsChart } from "../../strengthCharts";

function AllRepetitionsCharts(props) {
  const {
    avgLabel,
    avgTrendVisible,
    hasSide,
    leftChartId,
    leftData,
    measure,
    rightChartId,
    rightData,
    sdLabel,
    trend,
    avgForPDF
  } = props

  const [showAvgTrend, setShowAvgTrend] = useState(avgTrendVisible || false)

  const leftChart = useRef()
  const rightChart = useRef()

  let chartLeft = {}
  let chartRight = {}

  useEffect(() => {
    if(leftData.samples) {
      drawLeftChart()
    }
    if(rightData.samples) {
      drawRightChart()
    }
    if(leftData.samples && rightData.samples) {
      chartLeft.canvas.onmousemove = (e) => {
        drawTooltipOnTheOtherChart(e, chartLeft, chartRight, leftData)
      }
      chartRight.canvas.onmousemove = (e) => {
        drawTooltipOnTheOtherChart(e, chartRight, chartLeft, rightData)
      }
    }
  }, [showAvgTrend])

  const drawTooltipOnTheOtherChart = (e, thisChart, otherChart, data) => {
    const points = thisChart.getElementsAtEventForMode(e, 'nearest', { intersect: true}, true)
    if(points[0]) {
      const datapoint = points[0].index
      let activeElements = data.samples.map((sample, i) => {
        return { datasetIndex: i, index: datapoint}
      })
      if(showAvgTrend) {
        data.avgSamples.map((sample, i) => {
          activeElements.push({
            datasetIndex: data.samples.length + i,
            index: datapoint
          })
        })
        data.sdSamples.map((sample, i) => {
          activeElements.push({
            datasetIndex: data.samples.length + data.avgSamples.length + i,
            index: datapoint
          })
        })
      }
      otherChart.tooltip.setActiveElements(activeElements)
      otherChart.setActiveElements(activeElements)
      otherChart.update()
    } else {
      otherChart.tooltip.setActiveElements([], { x: 0, y: 0 })
      otherChart.setActiveElements([], { x: 0, y: 0 })
    }
  }

  const setChartCanvas = (chartId) => {
    let canvas
    let chartContainer = document.getElementById(chartId)
    if (chartContainer) {
      canvas = chartContainer.querySelector("canvas");
      if (canvas) {
        canvas.remove();
      }
      canvas = document.createElement("canvas");
      chartContainer.appendChild(canvas);
    }
    return canvas
  }

  const drawLeftChart = () => {
    let samples = []
    let sdSamples = []
    if(showAvgTrend) {
      samples = leftData.avgSamples
      sdSamples = leftData.sdSamples
    } else {
      samples = leftData.samples
    }
    let canvas = setChartCanvas(leftChartId)
    chartLeft = new Chart4(
      canvas, 
      strengthLeftRepetitionsChart(
        leftChartId, 
        leftData, 
        samples, 
        sdSamples, 
        showAvgTrend,
        avgLabel, 
        sdLabel,
        rightData.samples ? false : true,
        measure,
        avgForPDF
      )
    )
    leftChart.current = chartLeft
  }

  const drawRightChart = () => {
    let samples = []
    let sdSamples = []
    if(showAvgTrend) {
      samples = rightData.avgSamples
      sdSamples = rightData.sdSamples
    } else {
      samples = rightData.samples
    }
    let canvas = setChartCanvas(rightChartId)
    chartRight = new Chart4(
      canvas,
      strengthRightRepetitionsChart(
        rightChartId, 
        rightData, 
        samples, 
        sdSamples, 
        showAvgTrend,
        avgLabel,
        sdLabel,
        leftData.samples ? false : true,
        measure,
        avgForPDF
      )
    )
    rightChart.current = chartRight
  }

  const chartContainerWidth = () => {
    if(leftData.samples && rightData.samples){
      return { width: "47.5%" }
    } else {
      return { width: "47.5%", marginLeft: "30%" }
    }
  }

  const getChartClass = () => {
    return avgForPDF ? `${style.chartPDF}` : style.chart;
  }

  return (
    <div>
      <div className={style.charts}>
        {leftData.samples && 
          <div style={chartContainerWidth()}>
            {hasSide && 
              <div className={style.sideLabel}>{leftData.label}</div>
            }
            <div className={getChartClass()} 
                id={leftChartId}
                ref={leftChart}>
            </div>
          </div>
        }
        {rightData.samples && 
          <div style={chartContainerWidth()}>
            {hasSide &&
              <div className={style.sideLabel}>{rightData.label}</div>
            }
            <div className={getChartClass()} 
                id={rightChartId}
                ref={rightChart}>
            </div>
          </div>
        }
      </div>
      {!avgTrendVisible && 
        <div className={style.avgTrendSwitch}>
          <div className={style.label}>{trend}</div>
          <div className={`${style.switch} ${showAvgTrend && style.toggled}`} onClick={() => setShowAvgTrend(!showAvgTrend)}>
            <div className={style.thumb}></div>
          </div>
        </div>
      }
    </div>
  )
}

export default AllRepetitionsCharts