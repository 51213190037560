import React from "react";
import ExerciseSequenceChartBalance from "./ExerciseSequence/ExerciseSequenceChart/ExerciseSequenceChartBalance";

export default (dataForGraph, group, side=null) => {
  return dataForGraph.map((data, index) => {
    let xData = [];
    let yData = [];
    let xLabel = ""
    let yLabel = ""
    if (data.results[0]?.samples[group]) {
      if (data.results[0]?.side == "left" || data.results[0]?.side == "none") {
        xData = data.results[0]?.samples[group];
        xLabel = data.results[0]?.title;
      } else {
        yData = data.results[0]?.samples[group];
        yLabel = data.results[0]?.title;
      }
    }
    if (data.results[1]?.samples[group]) {
      if (data.results[1]?.side == "right" || data.results[0]?.side == "none") {
        yData = data.results[1]?.samples[group];
        yLabel = data.results[1]?.title;
      } else {
        xData = data.results[1]?.samples[group];
        xLabel = data.results[1]?.title;

      }
    }
    let colors = data.colors[0][index]
    return (
      <ExerciseSequenceChartBalance
        title={data.title}
        xData={xData}
        yData={yData}
        xLabel={xLabel}
        yLabel={yLabel}
        side={side}
        borderColor1={[colors[0], colors[1]]}
      />
    );
  });
}