import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./NewCallBlock.module.sass";
import BookNewCall from "../BookNewCall/BookNewCall";

function NewCallBlock(props) {
  const { translations, editCall, onCancel } = props;
  const [newCallOpen, setNewCallOpen] = useState(false);

  const onClickButton = () => {
    setNewCallOpen(true);
  };

  const onClickCloseNewCall = () => {
    setNewCallOpen(false);
    onCancel();
  };

  useEffect(() => {
    if (editCall) setNewCallOpen(true);
  }, [editCall]);

  const onCreateNewCall = (date, patient, locale) => {
    setNewCallOpen(false);
    props.onCreateNewCall(date, patient, locale);
  };

  const onUpdateCall = (date, patient, updateUrl, locale) => {
    setNewCallOpen(false);
    props.onUpdateCall(date, patient, updateUrl, locale);
  };

  let rootClasses = `${style.NewCallBlock}`;
  if (newCallOpen) {
    rootClasses += ` ${style.ShowBookNewCall}`;
  }

  return (
    <div className={rootClasses}>
      <div className={`${style.buttonNewCall}`} onClick={onClickButton}>
        {translations.button}
      </div>
      <div className={`${style.NewCall}`}>
        <BookNewCall
          onClickClose={onClickCloseNewCall}
          {...props}
          editCall={editCall}
          onUpdateCall={onUpdateCall}
          onCreateNewCall={onCreateNewCall}
        />
      </div>
    </div>
  );
}

NewCallBlock.propTypes = {};

NewCallBlock.defaultProps = {};

export default NewCallBlock;
