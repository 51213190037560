import React from "react";
import KariConnectExerciseResult from "./KariConnectExerciseResult/KariConnectExerciseResult";

function KariConnectExerciseResults(props) {
  const { rightShortText, leftShortText, exerciseGrouped, colors } = props;
  const renderCollection = exerciseGrouped.map((exercise, index) => (
    <div className={`col-3 col`} key={`${exercise.id}-${index}`}>
      <KariConnectExerciseResult
        index={index + 1}
        exercisesPair={exercise}
        rightShortText={rightShortText}
        leftShortText={leftShortText}
        colors={colors}
      />
    </div>
  ));
  return (
    <div>
      <div className={`row`}>{renderCollection}</div>
    </div>
  );
}

KariConnectExerciseResults.propTypes = {};

KariConnectExerciseResults.defaultProps = {};

export default KariConnectExerciseResults;
