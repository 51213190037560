import React, { useState } from "react";
import { callApi } from "../../../../helpers";

function SavedSequenceInputName(props) {
  const { draft, placeholder, updateUrl } = props;
  const [value, setValue] = useState(props.value);
  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const onBlur = (e) => {
    const newValue = e.target.value;
    updateDb(newValue);
  };

  const updateDb = (newValue) => {
    callApi(updateUrl, "PATCH", "json", null, null, null, {
      body: JSON.stringify({sequence: { name: newValue } })
    });
  };

  return (
    <input
      type={"text"}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
      maxLength="25"
      style={draft ? { width: "77%" } : {}}
    />
  );
}

SavedSequenceInputName.propTypes = {};

SavedSequenceInputName.defaultProps = {};

export default SavedSequenceInputName;
