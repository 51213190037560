import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./SelectNewCall.module.sass";

function SelectNewCall(props) {
  const { name, options } = props;
  const [selected, setSelected] = useState(props.selected);

  const onChange = (e) => {
    setSelected(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return (
    <span className={`${style.Select}`} data-type="select">
      <select value={selected} onChange={onChange}>
        {options.map((option, index) => {
          return (
            <option value={option.value} key={`${name}_${index}`}>
              {option.text}
            </option>
          );
        })}
      </select>
    </span>
  );
}

SelectNewCall.propTypes = {};

SelectNewCall.defaultProps = {};

export default SelectNewCall;
