import React, { useState, useEffect, useRef } from "react";
import { callApi } from "../../../helpers";
import style from "./UpperLimbResultDetailNoSide.module.sass"
import DoneTableColumn from "./DoneTable/DoneTableColumn";


function UpperLimbResultDetailNoSide(props) {

  const {
    index,
    movement
   
    } = props


  return (

    
    <div className={`${style.resultRow}`}>
    < div className={`${style.movementTitle}`}>
      {movement.code}
      { movement.label && 
      <div className={style.label}>
              {movement.label}
        </div>
       
      }
    </div>
    <div className={`${style.value}`}  >
      {movement.rep_avg}
    </div>
    <div className={`${style.voidSpace} ` }>
     &nbsp;
    </div>
    
    <div className={`${style.done} ` }> 
    < DoneTableColumn
                  done1={movement.done_1}
                  done2={movement.done_2}
                  done3={movement.done_3}
                 
                  identifier={`${index}-${movement.code}`}/> 
    </div>
                  
  </div>
  
  )
}

export default UpperLimbResultDetailNoSide