import React, { useEffect, useRef } from "react";
import style from "./Note.module.sass";

function Note(props) {
  const { date, note } = props;

  return (
    <div className={`${style.Note}`}>
      <span className={`${style.Time}`}>{date}</span>
      {note}
    </div>
  );
}

export default Note;
