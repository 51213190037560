import React, { useState, useEffect, useRef } from "react";
import { callApi } from "../../../helpers";
import style from "./UpperLimbResultDetail.module.sass"
import LineAsimmetry from "./LineAsimmetry/LineAsimmetry";

function UpperLimbResultDetail(props) {

  const {
    index,
    movement,
    sideInjuredId
  } = props

  const chart = useRef(null);

  const [chartVisibility, setChartVisibility] = useState(false)
  const color_left = sideInjuredId==1 ?  "#70BBFF" : "#B7DCFE";
  const color_right = sideInjuredId==2 ?  "#70BBFF" :"#B7DCFE";
  console.log(sideInjuredId);


  useEffect(() => {
    if(true) {
  
      const listLeft= document.querySelectorAll("#leftValue");    
      for (let i = 0; i < listLeft.length; i++) {
        listLeft[i].style.color = color_left;
      }
      const listRight = document.querySelectorAll("#rightValue");    
      for (let i = 0; i < listRight.length; i++) {
        listRight[i].style.color = color_right;
      }

    }
  }, [])



  if (movement.left!="°" && movement.right!="°") {
  return (
    <div className={style.resultRow}>
    < div className={`${style.movementTitle} ${style.tooltip}`}>
      {movement.code}
      { movement.label && 
      <div className={style.label}>
              {movement.label}
        </div>
       
      }
      { movement.hover && 
          < div className={`${style.info} `}>
              <span className={style.tooltiptext}>
                {movement.hover}
              </span>
          </div> 
      }
    </div>
    <div className={`${style.valueLeft}`}  id="leftValue" >
      {movement.left}
    </div>
    <div className={style.valueLSI} >
      { movement.lsi && < LineAsimmetry
                  value={movement.lsi} 
                  identifier={`${index}-${movement.code}`}/>
      }
      {!movement.lsi && <div className={style.void}>&nbsp;</div>
      }
    </div>
    <div className={`${style.valueRight} ` } id="rightValue" >
      {movement.right}
    </div>
  </div>
  )

}else
{
  return (
    <div className={style.resultRow}>
    
  </div>
  )
}

}
export default UpperLimbResultDetail
