import React, { useState, useEffect, useRef } from "react";
import { callApi } from "../../../helpers";
import style from "./UpperLimbResultDetailNoSide.module.sass"


function UpperLimbResultDetailNoSide(props) {

  const {
    index,
    movement
    } = props


  return (

  <div className={style.resultRow}>
    <div className ={`${style.repColumn}`}>
    <div className={style.title} >
        {movement.round_1}
      </div>
      <div  className={style.value}>
        {movement.rep_1} {movement.repetition}
      </div>
    </div>

    <div className ={style.repColumn} >
    <div className={style.title} >
        {movement.round_2}
      </div>
      <div  className={style.value}>

        {movement.rep_2} {movement.repetition}
      </div>
    </div>
    <div className ={style.repColumn} >
    <div className={style.title} >
        {movement.round_3}
      </div>
      <div  className={style.value}>
        {movement.rep_3} {movement.repetition}
      </div>
    </div>

    <div className ={`${style.repColumn} ${style.last}`}>
      <div className={style.title} >
        {movement.round_avg}
      </div>
      <div  className={style.value}>
        {movement.rep_avg} {movement.repetition}
      </div>
    </div>
  </div>
  
  )
}

export default UpperLimbResultDetailNoSide