import React, { useState } from "react";
import style from "./Patients.module.sass";
import PatientListHeader from "./PatientListHeader/PatientListHeader";
import PatientBox from "../PatientBox/PatientBox";
import List from "../List/List";
import FirstAccess from "../FirstAccess/FirstAccess";


function Patients(props) {
  const { 
    createPatient, 
    firstAccess, 
    licenses,
    searchSelectOptions, 
    searchPatientsPlaceholderText } = props;

  const baseUrl = "/patients";
  const [queryUrl, setQueryUrl] = useState(
    baseUrl + "?name=&order_by=last_sequence_date&direction=desc"
  );

  const onChangeFilter = (newOrder, search = "", direction = "") => {
    let newQueryUrl =
      baseUrl +
      "?name=" +
      search +
      "&order_by=" +
      newOrder +
      "&direction=" +
      direction;
    setQueryUrl(newQueryUrl);
  };

  const archiveReload = () => {
    setQueryUrl(queryUrl + " ")
  }

  const renderPatientItem = (item) => {
    return <PatientBox {...item} licenses={licenses} archiveRedirect={archiveReload}/>;
  };

  return (
    <div className={`${style.PatientsList}`}>
      <div className={"row mb-4"}>
        <PatientListHeader
          createPatient={createPatient}
          onChangeFilter={onChangeFilter}
          searchSelectOptions={searchSelectOptions}
          searchPatientsPlaceholderText={searchPatientsPlaceholderText}
        />
      </div>
      {firstAccess.check ? (
        <FirstAccess {...firstAccess} />
      ) : (
        <div className={style.List}>
          <List baseQuery={queryUrl} renderItem={renderPatientItem} />
        </div>
      )}
    </div>
  );
}

export default Patients;
