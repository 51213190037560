import React from "react";

const IconInfo = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 16.8333C13.0833 16.8333 16.8333 13.0833 16.8333 8.5C16.8333 3.91667 13.0833 0.166666 8.5 0.166666C3.91667 0.166666 0.166667 3.91667 0.166667 8.5C0.166667 13.0833 3.91667 16.8333 8.5 16.8333ZM7.66667 4.33333H9.33333V6H7.66667V4.33333ZM10.1667 12.6667H6.83333V11H7.66667V9.33333H6.83333V7.66667H9.33333V11H10.1667V12.6667Z" fill="#FAE038"/>
    </svg>
  )
}

export default IconInfo
