import ReactComponentsHandler from "react/reactComponentsHandler";

export default class ChangeOrderExercises {
  constructor(el) {
    this.el = el;
    this.initButtons();
  }

  initButtons() {
    this.el
      .querySelectorAll(".customizations__exercise")
      .forEach((exercise) => {
        const arrowUp = exercise.querySelector(".move-up");
        const arrowDown = exercise.querySelector(".move-down");
        arrowUp.addEventListener(
          "click",
          this.clickOnArrowUp.bind(this, exercise)
        );
        arrowDown.addEventListener(
          "click",
          this.clickOnArrowDown.bind(this, exercise)
        );
      });
  }

  findIndexInsideList(exercise) {
    const parent = exercise.parentNode;
    const exercises = parent.querySelectorAll(".customizations__exercise");
    const isElement = (element) => element == exercise;
    const index = [...exercises].findIndex(isElement);
    return index;
  }

  switchOrder(exercise, direction) {
    const currentOrder = parseInt(exercise.dataset.order);
    const customizationsExercises = this.el.querySelectorAll(
      ".customizations__exercise"
    );
    const prevNode = customizationsExercises[currentOrder + direction];
    const newOrderExercise = currentOrder + direction;
    prevNode.dataset.order = currentOrder;
    exercise.dataset.order = newOrderExercise;
    this.sendAjax(
      {
        id: exercise.dataset.id,
        order: newOrderExercise,
      },
      {
        id: prevNode.dataset.id,
        order: currentOrder,
      }
    );
    // if (direction == 1) prevNode.after(exercise);
    // else prevNode.before(exercise);
  }

  clickOnArrowUp(exercise) {
    const order = exercise.dataset.order;
    if (order > 0) {
      Preload.show();
      this.switchOrder(exercise, -1);
    }
  }

  getTotExercises() {
    return this.el.querySelectorAll(".customizations__exercise").length;
  }

  clickOnArrowDown(exercise) {
    const totExercises = this.getTotExercises();
    const order = exercise.dataset.order;
    if (order < totExercises - 1) {
      Preload.show();
      this.switchOrder(exercise, 1);
    }
  }

  updateList() {
    const customizationsExercises = this.el.querySelectorAll(
      ".customizations__exercise"
    );
  }

  lightChangedExercise(el) {
    el.classList.add("customizations__exercise--actived");
    setTimeout(() => {
      el.classList.remove("customizations__exercise--actived");
    }, 1100);
  }

  sendAjax(ex1, ex2) {
    const urlOrder = $("*[data-url-order]").data("urlOrder");
    $.ajax(urlOrder, {
      type: "POST",
      data: {
        ex1,
        ex2,
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error("AJAX Error: #{textStatus}");
      },
      success: (data) => {
        let response = $(data);
        response = response[0].querySelector(".customizations__exercise-list");
        const scroll = $(".customizations__exercise-list").scrollTop();
        $(".customizations__exercise-list").parent().html(response);
        setTimeout(() => {
          $(".customizations__exercise-list").scrollTop(scroll);
          const el = $(response).find("[data-id='" + ex1.id + "']");
          this.lightChangedExercise(el[0]);
        }, 0);
        const sequencesAreaEl = document.querySelector(".sequences-area__edit");
        sequencesAreaEl.__instance.initChangeOrderExercises();
        sequencesAreaEl.__instance.onResize();
        // success render react
        const reactComponentsHandler = new ReactComponentsHandler();
        reactComponentsHandler.insertReactComponents()
        Preload.hide();
      },
    });
  }
}
