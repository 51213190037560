import React, { Fragment, useEffect, useRef, useState } from "react";
import ExerciseName from "../ExerciseName/ExerciseName";
import ExerciseVideo from "../ExerciseVideo/ExerciseVideo";
import ProgressBarContainer from "../ProgressBar/ProgressBarContainer";
import Score from "../Score/Score";
import classes from "./ReactsExerciseResult.module.sass";
import { generateReactsHeartRateData, configReactsHeartRate } from "../ExerciseSequence/ExerciseSequenceChart/utils";
import check from "../../../../../assets/images/check-green.svg"
import heartBroken from "../../../../../assets/images/heart-broken.svg"

function ReactsExerciseResult(props) {
  const {
    chartData,
    colors,
    dataForVideo,
    dataForScore,
    duration,
    exercise,
    index,
    progressBarData,
    repetitions,
    warmup,
    isAboveAlert,
    workRanges
  } = props

  const [chartVisibility, setChartVisibility] = useState(false)
  const chart = useRef(null);
  const data = generateReactsHeartRateData(chartData.heartRateData);
  const isWarmup = duration.done == 0 && warmup.duration > 0

  useEffect(() => {
    let canvas;
    let canvasContainerRef = document.getElementById(index);
    if (canvasContainerRef) {
      canvas = canvasContainerRef.querySelector("canvas");
      if (canvas) {
        canvas.remove();
      }
      canvas = document.createElement("canvas");
      canvasContainerRef.appendChild(canvas);
      chart.current = new Chart(
        canvas,
        configReactsHeartRate(
          data,
          duration.done,
          duration.sampled,
          chartData.minHeartRate,
          chartData.maxHeartRate,
          chartData.alertHeartRate,
          chartData.alertTime,
          chartData.thresholdLabels
        )
      );
    }
  }, [chartVisibility]);

  const handleClick = () => {
    setChartVisibility(!chartVisibility)
  }

  const renderBar = () => {
    return (
      <div className={`${classes.cell} ${classes.score}`}>
        <div className="me-1">
          <Score {...dataForScore} colors={colors} />
        </div>

        <div style={{ marginRight: "30px" }}>
          <ProgressBarContainer
            main={progressBarData.main}
            minors={progressBarData.minors}
          />
        </div>
        {isAboveAlert &&
          <img src={heartBroken} />
        }
      </div>
    );
  };

  const formattedDuration = (time) => {
    let minutes = parseInt(time / 60)
    let seconds = time % 60

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }

  const renderReactsExerciseResultChart = () => {
    return (
      <div className={classes.reactsExerciseResultChart}>
        <div className={classes.workRanges} style={{ "color": colors.workRanges.inRange }}>
          <div className={classes.workRange}>
            <div className={classes.value}>{workRanges.inRange.value}</div>
            <div className={classes.label}>{workRanges.inRange.label}</div>
          </div>
          <div className={classes.workRange} style={{ "color": colors.workRanges.aboveMax }}>
            <div className={classes.value}>{workRanges.aboveMax.value}</div>
            <div className={classes.label}>{workRanges.aboveMax.label}</div>
          </div>
          <div className={classes.workRange} style={{ "color": colors.workRanges.aboveAlert, "paddingRight": "0px" }}>
            <div className={classes.value}>{workRanges.aboveAlert.value}</div>
            <div className={classes.label}>{workRanges.aboveAlert.label}</div>
          </div>
        </div>
        <div className={classes.chartContainer} id={index}></div>
      </div>
    )
  }

  const renderExerciseResult = () => {
    return (
      <div
        className={`${classes.reactsExerciseResult} text center`}
        onClick={handleClick}
      >
        {renderBar()}
        <div className={`${classes.cell} ${classes.duration}`}>
          <div className={classes.label}>{duration.label}</div>
          <div className={classes.value}>
            <div>{formattedDuration(duration.done)}</div>
            <div className={classes.durationSeparator}> {duration.separator} </div>
            <div>{formattedDuration(duration.total)}</div>
          </div>
        </div>
        <div className={`${classes.cell} ${classes.blankSpace}`}>
        </div>
        <div className={`${classes.cell} ${classes.repetitions}`}>
          <div className={classes.label}>{repetitions.label}</div>
          <div className={classes.value}>
            <div>{repetitions.done}</div>
            <div className={classes.separator}></div>
            <div>{repetitions.total}</div>
          </div>
        </div>
        <div className={`${classes.cell} ${classes.exerciseInfo}`}>
          <div>
            <ExerciseName colors={colors} {...exercise} />
          </div>
          <div className={`ms-1`}>
            <ExerciseVideo {...dataForVideo} />
          </div>
        </div>
      </div>
    )
  }

  const renderWarmupResult = () => {
    return (
      <div
        className={`${classes.warmupResult} text-center`}
        onClick={handleClick}
      >
        <div className={classes.completed}>
          <img src={check} height="20px"></img>
          <div style={{ marginLeft: "12px" }}>{warmup.completed}</div>
        </div>
        <div className={classes.label}>{warmup.label}</div>

        <div className={classes.duration}>
          {warmup.duration > 60 && parseInt(warmup.duration / 60) + " " + warmup.timeLabel}</div>
      </div>
    )
  }

  return (
    <div className={classes.reactsExerciseResultContainer} key={index}>
      {isWarmup ? renderWarmupResult() : renderExerciseResult()}
      {!isWarmup && chartVisibility && renderReactsExerciseResultChart()}
    </div>
  )
}

export default ReactsExerciseResult;
