import React, { Fragment} from "react";
import PropTypes from "prop-types";
import style from "./ExerciseFooterCSVDownload.module.sass";

function ExerciseFooterCSVDownload(props) {
  const {
    colors,
    downloadCSCVText,
    downloadCSCVURL
  } = props;



  const renderDownloadPdf = () => {
    return (
      <Fragment>
        <div>
          {downloadCSCVURL &&
            <a href={downloadCSCVURL} className="text-decoration-none" style={{color: colors.primaryColor}}>
              <i className="fas fa-cloud-download-alt"></i> {downloadCSCVText}
            </a>
          }
        </div>
        <div>
         
        </div>
      </Fragment>
    )
  }

  return (
    <div className={`${style.exerciseFooterCSVDownload}`}>
      {renderDownloadPdf()}
    </div>
  );
}

ExerciseFooterCSVDownload.propTypes = {
  downloadCSCVText: PropTypes.string,
  downloadCSCVURL: PropTypes.string,

};

export default ExerciseFooterCSVDownload;
