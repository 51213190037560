import * as Defiant from "defiant.js/dist/defiant.js";

const addExercisesQuery = (key, element) => {
  let queryString = "";
  if (key == "body_parts") {
    queryString += `(contains(body_parts_movements_string,"[*${element}*"))`;
  } else if (key == "movements") {
    queryString += `(contains(body_parts_movements_string,"*${element}*]"))`;
  } else {
    // queryString += `(contains(${key}_string,"*${element}*"))`;
  }
  return queryString;
};

const generateQuery = (currentFilters, firstSelectedCategory) => {
  const baseJSONPath = "//items";
  let queryString = `${baseJSONPath}[`;
  let firstFilter = true;
  Object.keys(currentFilters).forEach(function (key) {
    const ids = currentFilters[key];
    let addFilter = false;
    addFilter = key == "body_parts" || key == "movements";
    if (firstSelectedCategory == "body_parts" && key == "movements") {
      addFilter = false;
    }
    if (firstSelectedCategory == "movements" && key == "body_parts") {
      addFilter = false;
    }
    if (addFilter) {
      if (ids.length > 0) {
        if (!firstFilter) queryString += " and (";
        queryString += "(";
        ids.forEach((element, index) => {
          queryString += addExercisesQuery(key, element);
          if (index != ids.length - 1) {
            queryString += " or ";
          }
        });
        queryString += ")";
        if (!firstFilter) queryString += ")";
        firstFilter = false;
      }
    }
  });
  queryString += "]";
  if (queryString == `${baseJSONPath}[]`) {
    queryString = baseJSONPath;
  }
  return queryString;
};

const fillActiveFilters = (currentType, found) => {
  let activeFilters = {};
  if (currentType == "exercises") {
    activeFilters["body_parts"] = [];
    activeFilters["movements"] = [];
    activeFilters["tools"] = [];
    activeFilters["work_positions"] = [];
  }
  Object.keys(found).forEach(function (key) {
    const obj = found[key];
    Object.keys(obj).forEach(function (key) {
      if (key == "body_parts_movements_string") {
        if (obj[key] != "") {
          const splittedString = obj[key].split("*");
          let counter = 0;
          splittedString.map((_string, index) => {
            if (
              _string !== "[" &&
              _string !== `]` &&
              _string !== "," &&
              _string !== `][`
            ) {
              if (counter % 2 == 0) {
                activeFilters["body_parts"].push(_string);
              } else {
                activeFilters["movements"].push(_string);
              }
              counter++;
            }
          });
        }
      }
      if (key == "tools") {
        activeFilters["tools"].push(...obj[key]);
      }
      if (key == "work_positions") {
        activeFilters["work_positions"].push(...obj[key]);
      }
    });
  });
  if (currentType == "exercises") {
    activeFilters["body_parts"] = [...new Set(activeFilters["body_parts"])]
      .filter((item) => item != -1)
      .map((item) => parseInt(item));
    activeFilters["movements"] = [...new Set(activeFilters["movements"])]
      .filter((item) => item != -1)
      .map((item) => parseInt(item));
    activeFilters["tools"] = [...new Set(activeFilters["tools"])]
      .filter((item) => item != -1)
      .map((item) => parseInt(item));
    activeFilters["work_positions"] = [
      ...new Set(activeFilters["work_positions"]),
    ]
      .filter((item) => item != -1)
      .map((item) => parseInt(item));
  }
  return activeFilters;
};

const updateItems = (item, currentFilters, activeFilters, notFiltered) => {
  const categoryId = item.categoryId;
  for (let a = 0; a < item.filters.length; a++) {
    const filter = item.filters[a];
    const itemId = filter.id;
    const currentFiltersByCategory = currentFilters[categoryId];
    if (currentFiltersByCategory) {
      const checked = currentFiltersByCategory.includes(itemId);
      filter.checked = checked;
    }

    let enabled = true;
    if (
      (categoryId == "body_parts" || categoryId == "movements") &&
      !notFiltered
    ) {
      const activeFiltersByCategory = activeFilters[categoryId];
      if (activeFiltersByCategory) {
        enabled = activeFiltersByCategory.includes(itemId);
      }
    }
    filter.enabled = enabled;
  }
};

const updateFilters = (
  filters,
  currentFilters,
  baseJSONPath,
  jsonDb,
  currentType,
  firstSelectedCategory
) => {
  let queryString = generateQuery(currentFilters, firstSelectedCategory);
  const found = Defiant.search(jsonDb[currentType], queryString);
  const notFiltered = queryString == baseJSONPath;
  let foundClean = [...found];
  if (!notFiltered) {
    foundClean = [...found].map((item) => {
      const bodyPartsMovementsNew = [];
      for (let a = 0; a < item["body_parts_movements"].length; a++) {
        const couple = item["body_parts_movements"][a];
        const bodyPartId = couple.body_part_id;
        const movementId = couple.movement_id;
        const bodyPartPresent =
          currentFilters["body_parts"].includes(bodyPartId);
        const movementPresent =
          currentFilters["movements"].includes(movementId);
        // se bodyPartId è dentro currentFilter body part lo tengo
        // se movementId è dentro current filter movement lo tengo
        if (bodyPartPresent || movementPresent) {
          bodyPartsMovementsNew.push(couple);
        }
      }
      item["body_parts_movements"] = [...bodyPartsMovementsNew];

      let bpmString = ``;
      item["body_parts_movements"].map((bpm) => {
        const bodyPartId = `*${bpm.body_part_id}*`;
        const movementId = `*${bpm.movement_id}*`;
        bpmString += `[${bodyPartId},${movementId}]`;
      });
      item["body_parts_movements_string"] = bpmString;

      return item;
    });
  }
  const activeFilters = !notFiltered
    ? fillActiveFilters(currentType, found)
    : {};
  Object.keys(filters).forEach(function (key) {
    const obj = filters[key];
    obj["filters_groups"].map((item) => {
      updateItems(item, currentFilters, activeFilters, notFiltered);
    });
  });
};

const cleanFilterBlock = (filters, currentFilters, categoryId) => {
  if (categoryId) {
    currentFilters[categoryId] = [];
  }
  Object.keys(filters).forEach(function (key) {
    const obj = filters[key];
    obj["filters_groups"].map((item) => {
      const clean = categoryId ? item.categoryId == categoryId : false;
      if (clean) {
        item.filters.map((filter) => {
          filter.checked = false;
        });
      }
    });
  });
};

const cleanAllFilters = (filters, currentFilters) => {
  Object.keys(currentFilters).forEach(function (key) {
    currentFilters[key] = [];
  });
  cleanFilterBlock(filters, currentFilters, null);
};

const initExerciseItems = (exercises) => {
  // Properties that ends with "_string" are used inside Defiant research
  exercises.map((item) => {
    let bpmString = ``;
    item["body_parts_movements"].map((bpm) => {
      const bodyPartId = `*${bpm.body_part_id}*`;
      const movementId = `*${bpm.movement_id}*`;
      bpmString += `[${bodyPartId},${movementId}]`;
    });
    item["body_parts_movements_string"] = bpmString;
    item["tools"] = item["tools"].map((tool) => tool.id);
    item["tools_string"] = item["tools"].map((id) => `*${id}*`).join(",");
    item["work_positions"] = item["work_positions"].map((tool) => tool.id);
    item["work_positions_string"] = item["work_positions"]
      .map((id) => `*${id}*`)
      .join(",");
  });
  return exercises;
};

const initFilters = (newState) => {
  // Filters are used to create the structure of the filterOverlay
  const itemsCategories = JSON.parse(JSON.stringify(newState.filters.items));
  delete newState.filters.items;
  Object.keys(newState.filters).forEach(function (key) {
    const obj = newState.filters[key];
    obj["filters_groups"] = [];
    itemsCategories.map((itemCategory) => {
      if (obj.categories.includes(itemCategory.categoryId)) {
        obj["filters_groups"].push(JSON.parse(JSON.stringify(itemCategory)));
      }
    });
    obj["filters_groups"].map((filterGroups) => {
      newState.currentFilters[filterGroups.categoryId] = [];
      filterGroups.filters.map((filter) => {
        filter.checked = false;
        filter.enabled = true;
      });
    });
  });
};

const initInitialState = (newState) => {
  if (newState.jsonDb.exercises) {
    const exercises = initExerciseItems(newState.jsonDb.exercises);
    newState.jsonDb.exercises = {
      items: exercises,
    };
  }
  if (newState.jsonDb.activities) {
    const activities = newState.jsonDb.activities;
    newState.jsonDb.activities = {
      items: activities,
    };
  }
  newState.firstSelectedCategory = -1;
  initFilters(newState);
};

export { updateFilters, cleanFilterBlock, cleanAllFilters, initInitialState };
