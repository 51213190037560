import React, { useState } from "react";
import { callApi } from "../../../../helpers";
import style from "./UpperResultNotes.module.sass"

function UpperResultNotes(props) {
  const {
    id,
    title,
    updateUrl,
    value,
  } = props

  const [strengthNotes, setStrengthNotes] = useState(value || "");
  const [notesFocus, setNotesFocus] = useState(false)

  const onChangeNote = (e) => {
    setStrengthNotes(e.target.value)
  }

  const saveNotes = () => {
    let note = document.getElementById(id)
    callApi(
      updateUrl, 
      "PATCH", 
      "json", 
      () => {
        setNotesFocus(false)
        note.disabled = true
      }, 
      null, 
      null, 
      { body: JSON.stringify({ strength_general_result: { note_body: strengthNotes} }) }
    )
  }

  const focusNotes = () => {
    document.getElementById(id).disabled = false
    setNotesFocus(true)
    document.getElementById(id).focus()
  }

  return (
    <div className={style.strengthResultNotes}>
      <div className={style.title} style={{color: colors.primaryColor}}>{title}</div>
      <div className={style.value}>
        <input
          type="text"
          value={strengthNotes}
          onChange={onChangeNote}
          disabled
          id={id}
        />
      </div>
      {!notesFocus && <div className={style.edit} onClick={focusNotes}></div>}
      {notesFocus && <div className={style.save} onClick={saveNotes}></div>}
    </div>
  )
}

export default UpperResultNotes