import React, { Fragment, useState } from "react";
import { debounce, triggerTextChange } from "../../../helpers";
import Select from "../../Select/Select";
import classes from "./PatientListHeader.module.sass"

function PatientListHeader(props) {
  const { 
    createPatient, 
    onChangeFilter, 
    searchSelectOptions, 
    searchPatientsPlaceholderText } = props;

  const [orderBy, setOrderBy] = useState(searchSelectOptions[0].value);
  const [search, setSearch] = useState("");
  const [direction, setDirection] = useState("asc");

  const orderBySetter = ((option) => {
    setOrderBy(option.value)
    let newDirection = searchSelectOptions.filter(opt => opt.value == option.value && opt.direction == option.direction)[0]
    newDirection = newDirection ? newDirection.direction : "asc"
    setDirection(newDirection)
    onChangeFilter(option.value, search, newDirection)
  })

  const handleSearchChange = debounce((e) => {
    triggerTextChange(
      e,
      (text) => {
        setSearch(text);
        onChangeFilter(orderBy, text, direction ? direction : "asc");
      },
      search
    );
  }, 200);

  const userIsSearching = (e) => {
    handleSearchChange(e);
  };

  const goToNewPatient = () => {
    if (!createPatient.disabled) {
      window.location.href = createPatient.url
    }
  }

  const buttonClass = () => {
    if (createPatient.disabled) {
      return classes.patientButtonDisabled
    } else {
      return classes.patientButton
    }
  }

  return (
    <Fragment>
      <div className={`col-8 d-flex`} style={{gap: "20px"}}>
        <div className={classes.searchBar}>
          <i className={`fa fa-search ${classes.iconSearch}`}></i>
          <input
            type="search"
            id="patient-search"
            className={`form-control input-kari`}
            onChange={userIsSearching}
            placeholder={searchPatientsPlaceholderText}
          />
        </div>
        <div style={{width: "220px", position: "relative"}}>
          <Select
            options={searchSelectOptions}
            setOption={orderBySetter}
            selectedOption={searchSelectOptions[0].label}
          />
        </div>
      </div>
      <div className={`col-4 d-flex justify-content-end`}>
        <div className={buttonClass()} onClick={() => {goToNewPatient()}}>
          {createPatient.text}
        </div>
      </div>
    </Fragment>
  );
}

export default PatientListHeader;
