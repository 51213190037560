import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./Settings.module.sass";
import NotificationSetting from "./NotificationSetting/NotificationSetting";
import { callApi } from "../../helpers";
import StrengthMeasureSetting from "./StrengthMeasureSetting/StrengthMeasureSetting";
import LanguageChanger from "./NotificationSetting/LanguageChanger/LanguageChanger";

function Settings(props) {
  const { appVersion, licenses, translations, sections } = props;
  const [currentSetting, setCurrentSetting] = useState('general');
  const rootRef = useRef();

  if (sections.languages.value == "en-GB") {
    sections.languages.value = "en-US";
  }

  const onClickSetting = (value) => {
    setCurrentSetting(value);
  };

  const renderAppVersion = () => {
    return <div className={`${style.appVersion}`}>{appVersion}</div>;
  };

  const onSaveSettingsSuccess = (data, onSuccess = null) => {
    if (onSuccess) {
      onSuccess(data);
    }
  };

  const saveSetting = (url, params, onSuccess = null, respType = "") => {
    callApi(
      url,
      "POST",
      respType,
      (data) => onSaveSettingsSuccess(data, onSuccess),
      null,
      null,
      {
        ...params,
      }
    );
  };

  return (
    <div className={`${style.Settings} row`} ref={rootRef}>
      <div className={style.settingTitle}>{translations.settings.label}</div>
      <div className={`col-sm-4 ${style.SettingsList}`}>
        <ul>
          {sections.tabs.map((tab) => {
            return (
              <li className={`${currentSetting == tab.value && style.active}`} onClick={() => onClickSetting(tab.value)}>
                {tab.label}
              </li>
            )
          })}
        </ul>
      </div>
      <div className={`col-sm-8 ${style.SettingsDetailContainer}`}>
        <SettingsContext.Provider
          value={{ saveSetting, translations, sections }}
        >
          {currentSetting == 'euleria_home' &&
            <div>
              <NotificationSetting 
                currentSetting={translations.settings.sections[currentSetting]}
                licenses={licenses}
              />
            </div>
          }
          {currentSetting == 'general' && <LanguageChanger />}
          {currentSetting == 'general' && <StrengthMeasureSetting/>}
        </SettingsContext.Provider>
      </div>
      {renderAppVersion()}
    </div>
  );
}

Settings.propTypes = {};

Settings.defaultProps = {};

export const SettingsContext = React.createContext({});
export default Settings;
