import React from "react";

const News = (props) => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2998 16.3313L11.8147 16.3313L11.8147 18.5L14.2998 18.5L14.2998 16.3313ZM14.2998 6.5L11.7998 6.5L11.7998 14.9L14.2998 14.9L14.2998 6.5Z" fill="#F5FAFF"/>
      <path d="M13 1.5L15.7539 3.62096L19.2174 3.32558L20.3875 6.59874L23.4608 8.22273L22.6755 11.6089L24.3829 14.6366L21.8917 17.0607L21.6911 20.5309L18.2848 21.2233L16.2399 24.0342L13 22.775L9.76008 24.0342L7.71524 21.2233L4.30888 20.5309L4.10835 17.0607L1.61705 14.6366L3.3245 11.6089L2.53923 8.22273L5.61255 6.59874L6.78263 3.32558L10.2461 3.62096L13 1.5Z" stroke="#F5FAFF" strokeWidth="1.8" strokeLinejoin="round"/>
    </svg>
  )
};

export default News;
