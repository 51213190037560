import React, { Fragment } from "react";
import classes from './SliderIconTitleSubtitle.module.sass'
function SliderIconTitleSubtitleMulti(props) {
  const icon = props.icon
  const items = props.items

  const renderItem = (item) => {
    return (
      <Fragment>
        <div className="pe-2">
          <div className={classes.title}>{item.title}</div>
          <div className={classes.subtitle}>{item.subtitle}</div>
        </div>
      </Fragment>
    );

  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-3">
          <img className={`${classes.icon} mt-2 ms-2`} src={icon}></img>
        </div>
        <div className="col-auto d-flex">
          {items.map(item => renderItem(item))}
        </div>
      </div>
    </Fragment>
  )
}

export default SliderIconTitleSubtitleMulti