import React, { useState } from "react";
import style from "./ButtonSelect.module.sass"
import PropTypes from 'prop-types';

function ButtonSelect(props) {
  const {
    callApiFunction,
    openOnTop,
    options,
    title,
  } = props

  const [showOptions, setShowOptions] = useState(false)

  const buttonClass = () => {
    if(showOptions) {
      return `${style.button} ${style.showOptions}`
    } else {
      return `${style.button}`
    }
  }
  
  const arrowClass = () => {
    if(showOptions) {
      return "fa-solid fa-angle-up"
    } else {
      return "fa-solid fa-angle-down"
    }
  }

  return (
    <div className={style.ButtonSelect}>
      <div className={buttonClass()} 
           onClick={() => setShowOptions(!showOptions)}>
        <div>{title}</div>
        <div className={arrowClass()}></div>
      </div>
      {showOptions && 
        <div className={`${style.optionsContainer} ${openOnTop && style.openOnTop}`}>
          {options.map(option => {
            return (
              <div className={`${style.option} ${option.disable ? style.disabled : ''}`}
                   key={option.label}
                   onClick={() => !option.disable && callApiFunction(...option.args)}>
                {option.label}
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

ButtonSelect.propTypes = {
  callApiFunction: PropTypes.func.isRequired,
  openOnTop: PropTypes.bool,
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default ButtonSelect;