const reducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "update_top":
      newState.groupedItems.top.exercises = [...action.exercises];
      break;
    case "update_bottom":
      newState.groupedItems.bottom.exercises = [...action.exercises];
      break;
    case "update_add_url":
      newState.addURL = action.addURL;
      break;
    case "update_plan_summary":
      newState = { ...newState, ...action };
      delete newState.type;
      break;
    case "update_district":
      newState.district = action.district;
      break;
    case "update_tot_found":
      newState.totFound = action.totFound;
      break;
  }
  state = { ...state, ...newState };
  return state;
};

const initialState = {
  nextStep: "bottom",
  district: null,
  duration: 0,
  totFound: 0,
  durationText: "",
  addURL: "",
  customizeUrl: "",
  districts: [],
  incompleteCardioSequence: false,
  groupedItems: [],
  title: ''
};

function initState(values) {
  return {
    ...initialState,
    district: values.districtId,
  };
}

export { initState, reducer };
