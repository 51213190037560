import React from "react";

const ArrowUp = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.41218 5.58782C9.08779 5.91221 8.56193 5.9125 8.23718 5.58846L5 2.35833L1.76282 5.58846C1.43807 5.9125 0.912214 5.91221 0.58782 5.58782V5.58782C0.263176 5.26318 0.263176 4.73682 0.587821 4.41218L4.29289 0.707107C4.68342 0.316583 5.31658 0.316583 5.70711 0.707107L9.41218 4.41218C9.73682 4.73682 9.73682 5.26318 9.41218 5.58782V5.58782Z" fill='currentColor'/>
    </svg>
  )
}

export default ArrowUp
