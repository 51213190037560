import React from "react";

const Audio = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.6667 5H8.33333C6.48333 5 5 6.48333 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5Z" fill="#9BD833" />
      <path d="M27 13V22.7222C27 23.4442 26.7089 24.1366 26.1907 24.6471C25.6725 25.1576 24.9697 25.4444 24.2368 25.4444C23.504 25.4444 22.8012 25.1576 22.283 24.6471C21.7648 24.1366 21.4737 23.4442 21.4737 22.7222C21.4737 22.0002 21.7648 21.3078 22.283 20.7973C22.8012 20.2868 23.504 20 24.2368 20C24.6632 20 25.0658 20.0933 25.4211 20.2644V15.6989L17.5263 17.3556V24.2778C17.5263 24.9998 17.2352 25.6922 16.717 26.2027C16.1988 26.7132 15.496 27 14.7632 27C14.0303 27 13.3275 26.7132 12.8093 26.2027C12.2911 25.6922 12 24.9998 12 24.2778C12 23.5558 12.2911 22.8634 12.8093 22.3529C13.3275 21.8424 14.0303 21.5556 14.7632 21.5556C15.1895 21.5556 15.5921 21.6489 15.9474 21.82V15.3333L27 13Z" fill="white" />
    </svg>
  );
};

export default Audio;
