import React from "react";
import style from './Spinner.module.sass'

const Spinner = () => {
  return (
    <svg className={`${style.spinner}`} viewBox="0 0 50 50">
      <circle className={`${style.path}`} cx="25" cy="25" r="20" fill="none" stroke='currentColor' strokeWidth="5"></circle>
    </svg>
  )
}

export default Spinner