import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import style from "./Exercise.module.sass";
import RadiosBlock from "../../../BulkChangeActions/RadiosBlock/RadiosBlock";
import { CustomizationsPageContext } from "../../../CustomizationsPage";
import NameAndArrows from "./NameAndArrows/NameAndArrows";
import AddRemoveExercise from "./AddRemoveExercise/AddRemoveExercise";
import DeleteIcon from "./assets/cross_circle.svg";
import { callApi, camelize } from "../../../../../helpers";
function Exercise(props) {
  const {
    id,
    code,
    district,
    groupId,
    name,
    typology,
    sets,
    difficultyId,
    sideId,
    repetitions,
    isReacts,
    time,
    urls,
    districtIndex,
    groupIndex,
    index,
    hasSide,
    firstExercise,
    lastExercise,
    undeletable
  } = props;
  const { state, dispatcher, refreshState } = useContext(
    CustomizationsPageContext
  );
  const [hide, setHide] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const radioSideName = `side_${district}_${groupId}_${id}`;
  const radioDurationName = `duration_${district}_${groupId}_${id}`;
  const rootRef = useRef();

  const renderRepetitions = (repetitions) => {
    return (
      <Fragment>
        x<span>{repetitions}</span> {state.translations?.repetionMeasure}
      </Fragment>
    );
  };

  useEffect(() => {
    if (deleting) {
      let timeout = setTimeout(() => {
        rootRef.current.style.height = rootRef.current.getBoundingClientRect().height + "px";
        callApi(urls.delete,
          "DELETE",
          "json",
          (response) => {
            if (response) {
              setHide(true)
            } else {
              setDeleting(false)
            }
          }
        );
      }, 3000);
      return function cleanup() {
        clearTimeout(timeout)
      };
    }
  }, [deleting]);


  useEffect(() => {
    if (hide) {
      callApi(state.url, "GET", "json", (data) => {
        setTimeout(() => {
          dispatcher({
            type: "update_state",
            state: data,
          });
        }, 300);
      });
    }
  }, [hide]);

  const renderDuration = (duration) => {
    return (
      <Fragment>
        <span>{duration}</span>''
      </Fragment>
    );
  };

  const update_property = (url, property, value) => {
    let apiUrl = ""
    if (isReacts) {
      apiUrl = url + "?sequences_exercise[" + property + "]=" + value
    } else {
      apiUrl = url + "?" + property + "=" + value
    }
    callApi(apiUrl, "PATCH", "json", (data) => {
      const { duration, durationText, updatedExercise, durationWarning } = data;
      console.log(camelize(property));
      dispatcher({
        type: "update_property",
        value,
        districtIndex,
        groupIndex,
        exerciseIndex: index,
        property: camelize(property),
        updatedExercise: updatedExercise,
        duration,
        durationText,
        durationWarning
      });
    });
  };

  const onChangeSide = (value) => {
    update_property(urls.customization, "side_id", value);
  };

  const onChangeDifficulty = (value) => {
    update_property(urls.customization, "difficulty_id", value);
  };

  const onChangeSets = (value) => {
    update_property(urls.customization, "sets", value);
  };

  const onChangePosition = (direction) => {
    callApi(`${urls.move}?direction=${direction}`, "POST", "json", () => {
      refreshState(highlightElement);
    });
  };

  const highlightElement = () => {
    console.log(rootRef.current);
    rootRef.current.classList.add(style["Exercise--actived"]);
    setTimeout(() => {
      rootRef.current.classList.remove(style["Exercise--actived"]);
    }, 400);
  };

  const enableArrowUp = !firstExercise;
  const enableArrowDown = !lastExercise;
  const nameClass = sets ? 'col-lg-4' : 'col-lg-5'

  return (
    <div>
      {deleting &&
        <div className={`row ${style.deleteExercise}`}
          ref={rootRef}
        >
          <div className="col-lg-10 d-flex align-items-center">
            <div className={style.name}>{name}</div>
            <div className={style.deleted}>
              {state.licenseSwitch &&
                <span>
                  {state?.translations.biofeedbackDeleted}
                </span>
              }
              {!state.licenseSwitch &&
                <span>{state?.translations.deleted}</span>
              }
            </div>
          </div>
          <div className="col-lg-2 d-flex justify-content-end">
            <div className={style.cancelButton}
              onClick={() => setDeleting(false)}
            >
              {state?.translations.cancel}
            </div>
          </div>
        </div>
      }
      {!deleting &&
        <div
          className={`${style.Exercise} ${hide ? style.hide : ""} row`}
          ref={rootRef}
        >
          <div className={nameClass}>
            <NameAndArrows
              onChangePosition={onChangePosition}
              name={name}
              typology={typology}
              enableArrowUp={enableArrowUp}
              enableArrowDown={enableArrowDown}
              videoUrl={urls.video}
              enableTypologyBadge={!code?.startsWith("ML")}
            />
          </div>
          {sets &&
            <div className={`col-lg-1`}>
              <AddRemoveExercise onChangeSets={onChangeSets} sets={sets} />
            </div>
          }
          <div className={`col-lg-2 py-3 text-center`}>
            {(hasSide && !isReacts) && (
              <RadiosBlock
                values={state.bulkActions["side"].values}
                selected={sideId}
                showTexts={false}
                inverted
                name={radioSideName}
                onChangeRadio={onChangeSide}
              />
            )}
          </div>
          <div className={`col-lg-1`}></div>
          <div className={`col-lg-2 py-3 text-center`}>
            <RadiosBlock
              values={state.bulkActions.difficulty.values}
              selected={difficultyId}
              showTexts={false}
              inverted
              name={radioDurationName}
              onChangeRadio={onChangeDifficulty}
            />
          </div>
          <div className={`col-lg-1 py-3 text-center text-lowercase`}>
            {typology?.code == "TYPE_BAL"
              ? renderDuration(time)
              : renderRepetitions(repetitions)}
          </div>
          {!undeletable &&
            <div className={`col-lg-1 py-3 text-center d-flex justify-content-end pe-3 ${style.button}`}>
              <div onClick={() => setDeleting(true)}>
                <img src={DeleteIcon} />
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}

Exercise.propTypes = {};

Exercise.defaultProps = {};

export default Exercise;
