import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ExerciseSequenceChart.module.sass";
import LeftRightChooser from "./LeftRightChooser/LeftRightChooser";
import { maxData, generateData, updateData, configChart } from "./utils";
function ExerciseSequenceChart(props) {
  const { title, leftData, rightData, leftTextLegend, rightTextLegend, side } =
    props;

  const canvasRef = useRef(null);
  const chart = useRef(null);
  const labels = maxData(leftData, rightData).map(() => "");
  const data = generateData("SX", "DX", labels, leftData, rightData);

  const onEnableLeft = (enable) => {
    updateData(0, enable, leftData, chart);
  };
  const onEnableRight = (enable) => {
    updateData(1, enable, rightData, chart);
  };

  useEffect(() => {
    if (canvasRef.current) {
      if (chart.current != null) {
        chart.current.destroy();
      }
      chart.current = new Chart(canvasRef.current, configChart(data));
    }
  }, [props]);

  return (
    <div className={`${style.exerciseSequenceChart}`}>
      <div className={`${style.exerciseSequenceChartTitle}`}>{title}</div>
      <div className={`${style.chartContainer}`}>
        <canvas ref={canvasRef} data-side={side} />
      </div>

      {Array.isArray(leftData) &&
      Array.isArray(rightData) &&
      leftData.length > 0 &&
      rightData.length > 0 ? (
        <LeftRightChooser
          onEnableLeft={onEnableLeft}
          onEnableRight={onEnableRight}
          leftText={leftTextLegend}
          rightText={rightTextLegend}
        />
      ) : null}
    </div>
  );
}

ExerciseSequenceChart.propTypes = {
  title: PropTypes.string,
  leftData: PropTypes.array,
  rightData: PropTypes.array,
};

ExerciseSequenceChart.defaultProps = {
  leftData: [],
  rightData: [],
};

export default ExerciseSequenceChart;
