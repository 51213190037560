import React from "react";
import ProgressBar from "./ProgressBar"
import classes from "./ProgressBarContainer.module.sass"
const ProgressBarContainer = (props) => {
  const { main, minors } = props;

  const listMinors = minors.map((minor,index) =>
    <ProgressBar key={`${minor.id}-${index}`}
                 color={minor.color}
                 date={minor.date}
                 labelDate={minor.labelDate}
                 tooltip={true}
                 tooltipValue={minor.tooltipValue}
                 percentage={minor.percentage} />
  )
  return <div className={classes.exercisesCharts} >
    <ProgressBar tooltip={false} color={main.color} percentage={main.percentage}/>
    {listMinors}
  </div>
}

export default ProgressBarContainer;
