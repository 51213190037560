import React from "react";

const EuleriaLabSequences = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.90834 7.58168C4.90834 6.73045 5.58629 6.0404 6.42258 6.0404H10.2082C11.0444 6.0404 11.7224 6.73045 11.7224 7.58168V11.4349C11.7224 12.2861 11.0444 12.9761 10.2082 12.9761H6.42258C5.58629 12.9761 4.90834 12.2861 4.90834 11.4349V7.58168ZM10.2082 7.58168H6.42258V11.4349H10.2082V7.58168Z" fill="#F1F9FE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.0917 7.62039L17.2323 12.145C17.1043 12.2951 16.9241 12.3858 16.7317 12.397C16.5393 12.4082 16.3506 12.3389 16.2074 12.2046L14.2777 10.3947L15.2458 9.27365L16.6343 10.5759L20.01 6.61838L21.0917 7.62039Z" fill="#F1F9FE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.90834 16.9145C4.90834 16.0632 5.58629 15.3732 6.42258 15.3732H10.2082C11.0444 15.3732 11.7224 16.0632 11.7224 16.9145V20.7676C11.7224 21.6189 11.0444 22.3089 10.2082 22.3089H6.42258C5.58629 22.3089 4.90834 21.6189 4.90834 20.7676V16.9145ZM10.2082 16.9145H6.42258V20.7676H10.2082V16.9145Z" fill="#F1F9FE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.0917 16.9532L17.2323 21.4778C17.1043 21.6279 16.9241 21.7186 16.7317 21.7298C16.5393 21.7409 16.3506 21.6717 16.2074 21.5373L14.2777 19.7275L15.2458 18.6064L16.6343 19.9087L20.01 15.9512L21.0917 16.9532Z" fill="#F1F9FE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.84849 0C9.41335 0 9.06061 0.352745 9.06061 0.787879V1.05051H1.44445C1.00931 1.05051 0.656567 1.40325 0.656567 1.83838V25.2121C0.656567 25.6473 1.00931 26 1.44445 26H24.5556C24.9907 26 25.3434 25.6473 25.3434 25.2121V1.83838C25.3434 1.40325 24.9907 1.05051 24.5556 1.05051H16.9394V0.787879C16.9394 0.352745 16.5866 0 16.1515 0H9.84849ZM2.23232 2.62626H9.06061V2.88889C9.06061 3.32402 9.41335 3.67677 9.84849 3.67677H16.1515C16.5866 3.67677 16.9394 3.32402 16.9394 2.88889V2.62626H23.7677V24.4242H2.23232V2.62626Z" fill="#F1F9FE"/>
    </svg>
  );
};

export default EuleriaLabSequences;
