import React, { useState } from "react";
import style from "./FavouritePlans.module.sass";
import SavedSequence from "./SavedSequence/SavedSequence";
import List from "../List/List";
import FavouritePlansHeader from "./FavouritePlansHeader/FavouritePlansHeader";
import Notice from "../Notice/Notice";
import { callApi } from "../../helpers";
import ButtonSelect from "../ButtonSelect/ButtonSelect";

function FavouritePlans(props) {
  const { 
    addNewText, 
    baseUrls, 
    createUrl, 
    createUrls, 
    disableCreateBtn,
    noticeText, 
    searchPlaceholder, 
    showNotice, 
    title } = props;
  const [queryUrls, setQueryUrls] = useState(baseUrls);

  const onChangeFilter = (search = "") => {
    setQueryUrls(
      baseUrls.map((baseUrl) => {
        return baseUrl + "?name=" + search;
      })
    )
  };

  const onRemoveItem = () => {
    setQueryUrls(
      queryUrls.map((queryUrl) => {
        if (queryUrl.includes("?")) {
          queryUrl += "&random=" + Math.random();
        } else {
          queryUrl += "?random=" + Math.random();
        }
        return queryUrl
      })
    )
  };

  // TODO: prevent double calls always
  // TODO: fix all others calls (personal sequences, reacts saved and personal, xclinic saved and personal, etc.)
  const createNewSequence = (url) => {
    callApi(url, "POST", "json", (data) => {
      location.href = data.redirectUrl;
    });
  }

  const renderItem = (item) => {
    return <SavedSequence {...item} onRemove={onRemoveItem} />;
  };

  // TODO: cardio - handle xclinic with new button?
  const renderCreateNewPlanBtn = () => {
    return (
      <div className={`${style.createNewPlan}`}>
        <div className={`no-link ${disableCreateBtn == true ? "license-disabled" : ""}`}>
          <div className={`custom-height-similar`}>
            <div className={`clickable ${style.button}`}
                 onClick={() => createNewSequence(createUrl)}>
              {addNewText}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className={`${style.FavouritePlans}`}>
      <div className={`${style.filters}`}>
        <div className={style.title}>
          {title}
        </div>
        <FavouritePlansHeader
          onChangeFilter={onChangeFilter}
          searchPlaceholder={searchPlaceholder}
        />
        {createUrls && <ButtonSelect
          callApiFunction={createNewSequence}
          options={createUrls.map((createUrl) => {
            return {
              label: createUrl.name,
              disable: createUrl.disable,
              args: [createUrl.url]
            }
          })}
          title={addNewText}
        />}
      </div>
      <Notice showNotice={showNotice} noticeText={noticeText} />
      {createUrl && renderCreateNewPlanBtn()}
      <div className={style.plans}>
        {queryUrls.map((queryUrl) => {
          return (
            <List
              baseQuery={queryUrl}
              renderItem={renderItem}
              showPagination={false}
            />
          );
        })}
      </div>
    </div>
  );
}

FavouritePlans.propTypes = {};

FavouritePlans.defaultProps = {};

export default FavouritePlans;
