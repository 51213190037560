import React, {Fragment, useState, useEffect, useRef } from "react";
import { callApi } from "../../../helpers";
import style from "./UpperLimbResult.module.sass"
import UpperLimbResultDetail from "../UpperLimbResult/UpperLimbResultDetail";
import UpperLimbResultDetailNoSide from "../UpperLimbResult/UpperLimbResultDetailNoSide";

function UpperLimbResult(props) {

  const {
    index,
    code,
    side_injured_id,
    left,
    right,
    hover_health,
    hover_inj,
    hover_lsi,
    name,
    results,
    lsi,
    lsi_footer,
    name_labels,
    image_url

  } = props



  useEffect(() => {
    if(true) {
      let canvas;

    }
  }, [])

 

  const renderSide = () => {
    if(side_injured_id ==1)
    return (
      <div className={style.headerSide} >
        <div className={style.void}>
         &nbsp;
        </div>
        <div className={`${style.injuredSide} ${style.tooltip}`}>   
          {left}
            <span className={style.tooltiptext}>
              {hover_inj}
            </span>
        </div>
        <div className={`${style.LSI} ${style.tooltip}`}> 
        {lsi}
        <span className={style.tooltiptext}>
              {hover_lsi}
        </span>
        </div>  
        <div className={`${style.Side} ${style.tooltip}`}> 
        {right}
        <span className={style.tooltiptext}>
              {hover_health}
        </span>
      </div>    
      </div>
    )
    else
    return (
      <div className={style.headerSide} >
         <div className={style.void}>
         &nbsp;
        </div>
        <div className={`${style.Side} ${style.tooltip}`}> 
        {left}
        <span className={style.tooltiptext}>
              {hover_health}
        </span>
      </div>  
      <div className={`${style.LSI} ${style.tooltip}`}> 
        {lsi}
        <span className={style.tooltiptext}>
              {hover_lsi}
        </span>
        </div>   
        <div className={`${style.injuredSide} ${style.tooltip}`}>   
          {right}
            <span className={style.tooltiptext}>
              {hover_inj}
            </span>
        </div>   
      </div>
    )
  }


  const renderSideMovement = (results)  => {

    console.log(results)

    if (!results || results.length === 0) {
      return <div>No movements available</div>;
    }
  
    return results.map((movement, index) => (
      <Fragment>
      <UpperLimbResultDetail
      index={index}
      movement={movement}
      sideInjuredId = {side_injured_id }

       />

      </Fragment>
    )
    );
  }
  
  const renderNoSideMovement = (results)  => {

    if (!results || results.length === 0) {
      return <div>No movements available</div>;
    }
  
    return results.map((movement, index) => (

      <UpperLimbResultDetailNoSide
      index={index}
      movement={movement}
       />


    ));
  }

  const renderLSIfooter = () => {
    return (
    <div className={style.footer_lsi}>
      {lsi_footer}
    </div>
    )
  }
  
  const renderNameLabel = (name_labels) => {

    return name_labels.map((name_label) => (
      <div className={style[name_label.code]}>
      {name_label.value}
     </div>
    ));
  }
  return (

    <div className={style.upperLimbResult}>
      <div className={style.description}>
        <img className={style.image} src={image_url} />
        <div className={style.testName}>{name}</div>
        <div className={style.label}>
          {renderNameLabel(name_labels)}
        </div>
      </div>
      <div>
      {side_injured_id && renderSide()}
      {side_injured_id && 
      renderSideMovement(results) 
      } 
       {side_injured_id && 
      renderLSIfooter()
      }       
      {!side_injured_id && 
      renderNoSideMovement(results)
      }      
      </div>
 
    </div>
  )
}

export default UpperLimbResult