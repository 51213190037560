import React, { useContext, useState } from "react";
import style from "./NewSessionForm.module.sass";
import { PersonalSequencePageContext } from "../PersonalSequencePage";
import Select from "../../Select/Select";
import ButtonSelect from "../../ButtonSelect/ButtonSelect";

function NewSessionForm(props) {
  const [selectState, setSelectState] = useState(-1);
  const [selectType, setSelectType] = useState('');
  const [showCopy, setShowCopy] = useState(false);
  const { state, createSequence, createNewSequence } = useContext(
    PersonalSequencePageContext
  );
  const { savedSequences, translations, newSessionForm } =
    state;

  const clickOnCopy = () => {
    setSelectState(-1);
    setSelectType('');
    createSequence(selectState, true, undefined, selectType);
    setShowCopy(false);
  };

  const onChangeSequence = (e) => {
    const newValue = e.url;
    setSelectState(newValue);
    setSelectType(e.type);
    setShowCopy(parseInt(newValue) == -1 ? false : true);
  };

  const sequencesForSelect = () => {
    let formattedSequences = {}
    savedSequences.forEach((ssGroup) => {
      formattedSequences[ssGroup.label] = ssGroup.items.map((ss) => {
        return {
          type: ssGroup.type,
          key: `${ssGroup.type}-${ss.id}`,
          label: ss.name,
          url: ss.url
        }
      })
    })
    return formattedSequences
  }

  return (
    <div
      className={`${style.NewSessionForm}`}
      data-state={newSessionForm.state}
    >
      {state.personalSequences.length == 0 &&
        <div className={`${style.instructions}`}>
          {translations?.personalSequences.placeholder}
        </div>
      }

      <div className={style.addPlanTitle}>{translations?.personalSequences.addPlan}</div>
      <div className={`${style.addExistent}`}>
        <div className={`${style.selectTag}`}>
          {savedSequences &&
            <Select
              options={sequencesForSelect()}
              prompt={translations?.personalSequences.chooseSaved}
              selectedOption={translations?.personalSequences.chooseSaved}
              translations={translations}
              setOption={onChangeSequence}
              grouped
              openTop
              searchable
            />
          }
        </div>
        <div
          className={`${style.createFromSavedSequence}`}
          onClick={clickOnCopy}
          style={{ display: `${showCopy ? "block" : "none"}` }}
        >
          <div className={`${style.button} clickable`}>
            {translations?.personalSequences.add}
          </div>
        </div>
        {state.createUrls && !showCopy && <ButtonSelect
          callApiFunction={createNewSequence}
          openOnTop
          options={state.createUrls.map((createUrl) => {
            return {
              label: createUrl.name,
              disable: createUrl.disable,
              args: [createUrl.url, createUrl.type]
            }
          })}
          title={translations?.personalSequences.new}
        />}
        {state.createUrl &&  <div
            className={`${style.newPersonalSequence}`}
            onClick={() => createNewSequence()}
            style={{ display: `${!showCopy ? "block" : "none"}` }}
          >
          <div className={`${style.button} clickable`}>
            {translations?.personalSequences.new}
          </div>
        </div>
        }
      </div>
    </div>
  );
}

NewSessionForm.propTypes = {};

NewSessionForm.defaultProps = {
  state: "instructions",
};

export default NewSessionForm;
