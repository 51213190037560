import React, { useEffect, useReducer, useRef, useState } from "react";
import style from "./EditPlanHeader.module.sass";
import searchIcon from "./assets/icon_search_white.svg";
import FilterOverlay from "./FilterOverlay/FilterOverlay";
import { reducer, initialState } from "./EditPlanHeaderState";
function EditPlanHeader(props) {
  const { 
    id, 
    district, 
    filtersUrl, 
    showBiofeedbackSwitch,
    showFilters,
    translations 
  } = props;
  const [showFilterOverlay, setShowFilterOverlay] = useState(false);
  const [state, dispatcher] = useReducer(reducer, initialState);
  const [search, setSearch] = useState("");
  const filterBtnRef = useRef();
  const [nameParams, setNameParams] = useState("name=");
  const [filtersParams, setFiltersParams] = useState("");
  const [onlyBiofeedback, setOnlyBiofeedback] = useState(false)

  useEffect(() => {
    props.onChangeFilter(`${nameParams}&${filtersParams}`);
  }, [nameParams, filtersParams]);

  const handleSearchChange = (e) => {
    const word = e.target.value;
    setSearch(word);
    setNameParams("name=" + word);
  };

  const onChangeFilter = (filters = "") => {
    setFiltersParams(filters);
  };

  const openFilterOverlay = (e) => {
    e.stopPropagation();
    setShowFilterOverlay(() => !showFilterOverlay);
  };

  const hideFilterOverlay = () => {
    setShowFilterOverlay(false);
  };

  const renderSearchText = () => {
    return (
      <div className={`${style.searchText}`}>
        <img src={searchIcon} className={`${style.seachIcon}`} />
        <input
          type="text"
          className={`${style.searchTextForm}`}
          placeholder={translations?.buttons.search}
          onChange={handleSearchChange}
        />
      </div>
    );
  };

  const renderSearchFilter = () => {
    return (
      <div
        className={`${style.searchFilter}`}
        onClick={openFilterOverlay}
        ref={filterBtnRef}
      >
        {state.totFilters > 0 && <span>{state.totFilters}</span>}
      </div>
    );
  };

  const renderOnlyBiofeedbackExercises = (biofeedbackOnly) => {
    if(onlyBiofeedback != biofeedbackOnly) {
      if(biofeedbackOnly) {
        state.totFilters = state.totFilters + 1
      } else if(state.totFilters > 0 ) {
        state.totFilters = state.totFilters - 1
      }
    }
    setOnlyBiofeedback(biofeedbackOnly)
    setFiltersParams(filtersParams + `&only_biofeedback=${biofeedbackOnly}`)
  }

  return (
    <div className={`${style.EditPlanHeader}`}>
      <EditPlanHeaderContext.Provider
        value={{ state, dispatcher, hideFilterOverlay }}
      >
        <div className={`${style.EditPlanHeaderFilterContainer}`}>
          {renderSearchText()}
          {showFilters && renderSearchFilter()}
        </div>
        {showBiofeedbackSwitch && translations &&
          <div className={style.biofeedbackSwitchContainer}>
            <div className={style.switchLabel}>{`${translations?.biofeedbackSwitch.label}:`}</div>
            <div className={style.switch}>
              <div className={`${style.option} ${!onlyBiofeedback && style.selected}`}
                  onClick={() => renderOnlyBiofeedbackExercises(false)}>
                {translations?.biofeedbackSwitch.all}
              </div>
              <div className={`${style.option} ${onlyBiofeedback && style.selected}`}
                  onClick={() => renderOnlyBiofeedbackExercises(true)}>
                {translations?.biofeedbackSwitch.biofeedback}
              </div>
            </div>
          </div>
        }
        {showFilters &&
          <FilterOverlay
            visible={showFilterOverlay}
            onChangeFilter={onChangeFilter}
            filterBtnRef={filterBtnRef}
            filtersUrl={filtersUrl}
            id={id}
            district={district}
          />
        }

      </EditPlanHeaderContext.Provider>
    </div>
  );
}

EditPlanHeader.propTypes = {};

EditPlanHeader.defaultProps = { showFilters: true };

export const EditPlanHeaderContext = React.createContext({});

export default EditPlanHeader;
