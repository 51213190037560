import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import style from "./FilterOverlay.module.sass";
import FilterOverlayFooter from "./FilterOverlayFooter/FilterOverlayFooter";
import FilterOverlayHeader from "./FilterOverlayHeader/FilterOverlayHeader";
import { callApiASync, useOutsideElement } from "../../../../helpers";
import { EditPlanHeaderContext } from "../EditPlanHeader";
import { reducer, initialState } from "./FilterOverlayState";
import FilterOverlayContent from "./FilterOverlayContent/FilterOverlayContent";
import { EditPlanContext } from "../../EditPlan";
function FilterOverlay(props) {
  const { visible, filterBtnRef, filtersUrl, id, district } = props;
  const [state, dispatcher] = useReducer(reducer, initialState);
  const [translations, setTranslations] = useState(null);
  const { dispatcher: dispatcherEditPlanHeader } = useContext(
    EditPlanHeaderContext
  );
  const rootRef = useRef();
  const { hideFilterOverlay } = useContext(EditPlanHeaderContext);
  const { state: editPlanState } = useContext(EditPlanContext);
  const overlayPosition = useRef({
    top: 0,
    left: 0,
  });
  const arrowPosition = useRef({
    top: 0,
    left: 0,
  });

  let rootClasses = `${style.FilterOverlay}`;
  if (visible) {
    rootClasses += ` ${style.FilterOverlayVisible}`;
  }

  const onClickOutise = () => {
    hideFilterOverlay();
  };

  useOutsideElement(rootRef, onClickOutise, [filterBtnRef]);

  useEffect(() => {
    callApiASync(
      filtersUrl,
      "GET",
      "json"
    ).then((data) => {
      setTranslations(data.translations);
      dispatcher({
        type: "init_filters",
        exercises: data.exercises,
        activities: data?.activities,
        filtersGroups: data.items,
      });
    });
    setOverlayAndArrowPosition();
  }, [editPlanState.district]);

  useEffect(() => {
    if (Object.keys(state.currentFilters).length !== 0) {
      onApplyFilters();
    }
  }, [state.currentFilters]);

  useEffect(() => {
    dispatcher({
      type: "clean_all",
      dispatcherEditPlanHeader,
    });
  }, [district]);

  const setOverlayAndArrowPosition = () => {
    const filterBtnRefBounging = filterBtnRef.current.getBoundingClientRect();
    const filterBtnRefBoungingParent =
      filterBtnRef.current.parentNode.getBoundingClientRect();
    const arrowHeaderSize = 20;
    overlayPosition.current.top =
      filterBtnRefBounging.y -
      filterBtnRefBoungingParent.y +
      filterBtnRefBounging.height +
      arrowHeaderSize * 1.5;
    overlayPosition.current.left = 0;
    arrowPosition.current.left =
      filterBtnRefBounging.x -
      filterBtnRefBoungingParent.x -
      arrowHeaderSize +
      filterBtnRefBounging.width / 2;
  };

  const onApplyFilters = () => {
    props.onChangeFilter(getFiltesrUrl());
  };

  const getFiltesrUrl = () => {
    let urlParams = `type=${state.currentType}&`;
    const { currentFilters } = state;
    const jsonToSend = { body_parts_movements: {} }
    Object.keys(currentFilters).forEach((key) => {
      console.log(key)
      const keyToSend = key + "_ids"
      if(key == "body_parts" || key == "movements") {
        jsonToSend.body_parts_movements[keyToSend] = [...currentFilters[key]]
      } else {
        jsonToSend[keyToSend] = [...currentFilters[key]]
      }
    })
    urlParams += encodeURI("filters=" + JSON.stringify(jsonToSend));
    return urlParams;
  };

  return (
    <div
      className={rootClasses}
      ref={rootRef}
      style={{
        top: overlayPosition.current.top,
        left: overlayPosition.current.left,
      }}
    >
      <div
        className={`${style.ArrowPointer}`}
        style={{
          top: arrowPosition.current.top,
          left: arrowPosition.current.left,
        }}
      />
      <FilterOverlayContext.Provider
        value={{ state, dispatcher, translations }}
      >
        {/* <div className={`${style.FilterOverlayHeader}`}>
          <FilterOverlayHeader />
        </div> */}
        <FilterOverlayContent state={state} />
        <div className={`${style.FilterOverlayFooter}`}>
          <FilterOverlayFooter />
        </div>
      </FilterOverlayContext.Provider>
    </div>
  );
}

FilterOverlay.propTypes = {};

FilterOverlay.defaultProps = {
  visible: false,
};

export const FilterOverlayContext = React.createContext({});

export default FilterOverlay;
