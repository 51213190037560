import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { SettingsContext } from "../../Settings";
import styles from "./ReportNotifications.module.sass";

function ReportNotifications(props) {
  const value = useContext(SettingsContext);
  const report = value.sections.report;
  const translations = value.translations;
  const saveSetting = value.saveSetting;

  const [notificationDailyMail, setNotificationDailyMail] = useState(report.email.checked);
  // const [noticeMessage, setNoticeMessage] = useState("");
  const [mailCadence, setMailCadence] = useState(report.day.selected);
  const [mailTime, setMailTime] = useState(report.hour.selected);

  const switchReportNotification = (value, setting) => {
    if (setting == "notification_daily_mail") {
      setNotificationDailyMail(value);
      saveSetting(report.url, {
        body: JSON.stringify({
          data: {
            type: "notification_daily_mail",
            value: value,
          },
        }),
      });
    }
  };

  const onChangeSelect = (e, setting) => {
    updateValue(setting, e.target.value);
  };

  // const onUpdateValue = (data) => {
  //   setNoticeMessage(data.text);
  // };

  const updateValue = (type, value) => {
    saveSetting(
      report.urlPreference,
      {
        body: JSON.stringify({ data: { type, value } }),
      },
      null,
      "json"
    );
    if (type == "notification_mail_cadence") {
      setMailCadence(value);
    }
    if (type == "notification_mail_preferred_time") {
      setMailTime(value);
    }
  };

  return (
    <div className={styles.reportNotifications}>
      <div className={styles.column}>
        <h2>{translations.settings.notifications.report}</h2>
        <br />
        <div>{translations.settings.notifications.send_email}</div>
      </div>
      <div className={styles.column}>
        <div className="row m-0">
          <div className={`col-5 text-end ${styles.inputLabel} ${styles.switch}`}>
            {translations.settings.mailreport.report}
          </div>
          <div className="col-7">
            <span className={styles.radioWrapper}>
              <span onClick={() => switchReportNotification(true, "notification_daily_mail")}
                    className={`${styles.radioButton} ${notificationDailyMail && styles.selectedRadio}`}
              >
                {translations.settings.notifications.yes}
              </span>
              <span onClick={(e) => switchReportNotification(false, "notification_daily_mail")}
                    className={`${styles.radioButton} ${!notificationDailyMail && styles.selectedRadio}`}
              >
                {translations.settings.notifications.no}
              </span>
            </span>
          </div>
        </div>
        {notificationDailyMail && (
          <div className={`mt-3`}>
            <div className="row m-0">
              <div className={`col-5 text-end ${styles.inputLabel} ${styles.select}`}>
                {translations.settings.notifications.every}
              </div>
              <div className="col-7" >
                <span data-type="select">
                  <select
                    onChange={(e) => onChangeSelect(e, "notification_mail_cadence")}
                    defaultValue={mailCadence}
                  >
                    <option value="day">
                      {translations.settings.notifications.day}
                    </option>
                    {translations.days.map((day, index) => {
                      return (
                        <option key={"day-" + day} value={index}>
                          {day}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
            </div>
            <div className="row m-0 mt-2">
              <span className={`col-5 text-end ${styles.inputLabel} ${styles.select}`}>
                {translations.settings.notifications.at}
              </span>
              <div className="col-7" >
                <span data-type="select">
                  <select
                    defaultValue={mailTime}
                    onChange={(e) =>
                      onChangeSelect(e, "notification_mail_preferred_time")
                    }
                  >
                    {Array.apply(null, Array(24)).map((el, index) => {
                      return (
                        <option key={"time-" + index} value={index + ""}>
                          {`${index.toString().padStart(2, "0")}.00`}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div
        className={`${styles.noticeChange} ${
          noticeMessage && notificationDailyMail
            ? styles.noticeChangeActive
            : ""
        }`}
      >
        {noticeMessage}
      </div> */}
    </div>
  );
}

ReportNotifications.propTypes = {};

ReportNotifications.defaultProps = {
  notificationDailyMail: true,
  mailCadence: "",
  mailTime: "",
};

export default ReportNotifications;
