import React from "react";

const Videocamera = () => {
  return (
    <svg
      width="35px"
      height="35px"
      viewBox="0 0 35 35"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="ico-video"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M7.5,10 L18.5,10 C20.4329966,10 22,11.5670034 22,13.5 L22,22.5 C22,24.4329966 20.4329966,26 18.5,26 L7.5,26 C5.56700338,26 4,24.4329966 4,22.5 L4,13.5 C4,11.5670034 5.56700338,10 7.5,10 Z M7.5,12 C6.67157288,12 6,12.6715729 6,13.5 L6,22.5 C6,23.3284271 6.67157288,24 7.5,24 L18.5,24 C19.3284271,24 20,23.3284271 20,22.5 L20,13.5 C20,12.6715729 19.3284271,12 18.5,12 L7.5,12 Z M24.7032868,13.0356346 L28.0366201,12.100845 C29.2774167,11.7528799 30.5653626,12.4766629 30.9133277,13.7174595 C30.9708384,13.922535 31,14.1345191 31,14.347506 L31,21.6530716 C31,22.941736 29.9553311,23.9864049 28.6666667,23.9864049 C28.4536797,23.9864049 28.2416956,23.9572433 28.0366201,23.8997326 L24.7032868,22.964943 C23.6961258,22.682498 23,21.7642975 23,20.718282 L23,15.2822956 C23,14.2362801 23.6961258,13.3180796 24.7032868,13.0356346 Z M25.2433267,14.961344 C25.0994465,15.0016933 25,15.1328648 25,15.2822956 L25,20.718282 C25,20.8677128 25.0994465,20.9988843 25.2433267,21.0392336 L28.57666,21.9740231 C28.6059565,21.982239 28.63624,21.9864049 28.6666667,21.9864049 C28.8507616,21.9864049 29,21.8371665 29,21.6530716 L29,14.347506 C29,14.3170793 28.9958341,14.2867959 28.9876182,14.2574994 C28.9379089,14.0802427 28.7539167,13.9768452 28.57666,14.0265545 L25.2433267,14.961344 Z"
          id="Shape"
          fill="currentColor"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default Videocamera;
