import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import style from "./XclinicExercise.module.sass";
import RadiosBlock from "../../../BulkChangeActions/RadiosBlock/RadiosBlock";
import { CustomizationsPageContext } from "../../../CustomizationsPage";
import NameAndArrows from "./NameAndArrows/NameAndArrows";
import DeleteIcon from "./assets/cross_circle.svg";
import Clock from "./assets/clock.svg";
import SelectedClock from "./assets/clock-selected.svg";
import { callApi, camelize } from "../../../../../helpers";
function XclinicExercise(props) {
  const {
    id,
    district,
    groupId,
    name,
    typology,
    xclinicExerciseTypologyId,
    sideId,
    repetitions,
    timeBack,
    timeForth,
    timeWork,
    timeRest,
    urls,
    districtIndex,
    groupIndex,
    index,
    hasSide,
    firstExercise,
    lastExercise,
  } = props;
  const { state, dispatcher, refreshState } = useContext(
    CustomizationsPageContext
  );
  const [hide, setHide] = useState(false);
  const [timeCustomization, setTimeCustomization] = useState(false)
  const radioSideName = `side_${district}_${groupId}_${id}`;
  const radioDurationName = `duration_${district}_${groupId}_${id}`;
  const rootRef = useRef();
  const timeCustom = useRef()

  const renderRepetitions = (repetitions) => {
    return (
      <div className={style.toggleValueBlock}>
        <div className={style.toggler}>
          <div className={style.button} onClick={() => onIncrementRepetitions(repetitions - 1)}>-</div>
          <div>{repetitions}</div>
          <div className={style.button} onClick={() => onIncrementRepetitions(repetitions + 1)}>+</div>
        </div>
        <div className={style.measure}>{state.translations?.repetionMeasure}</div>
      </div>
    );
  };

  useEffect(() => {
    if (hide) {
      callApi(state.url, "GET", "json", (data) => {
        setTimeout(() => {
          dispatcher({
            type: "update_state",
            state: data,
          });
        }, 300);
      });
    }
  }, [hide]);

  const renderDuration = (duration) => {
    return (
      <div className={style.toggleValueBlock}>
        <div className={style.toggler}>
          <div className={style.button} onClick={() => onIncrementTime(duration - 1, "time_forth")}>-</div>
          <div>{duration}</div>
          <div className={style.button} onClick={() => onIncrementTime(duration + 1, "time_forth")}>+</div>
        </div>
        <div className={style.measure}>{state.translations?.timeMeasure}</div>
      </div>
    );
  };

  const renderTimeCustomizationBlock = (label, value, property) => {
    return (
      <div className="d-flex flex-column align-items-center" style={{"gap": "8px"}}>
        <div className={style.timeLabel}>{label}</div>
        <div className={style.toggleValueBlock}>
          <div className={style.toggler}>
            <div className={style.button} onClick={() => onIncrementTime(value - 1, property)}>-</div>
            <div>{value}</div>
            <div className={style.button} onClick={() => onIncrementTime(value + 1, property)}>+</div>
          </div>
        </div>
      </div>
    )
  }

  const update_property = (url, property, value) => {
    // TODO: nome tabella da rails
    callApi(url + "?xclinic_saved_sequences_exercise[" + property + "]=" + value, "PATCH", "json", (data) => {
      const { duration, durationText, updatedExercise, durationWarning } = data;
      console.log(camelize(property));
      dispatcher({
        type: "update_property",
        value,
        districtIndex,
        groupIndex,
        exerciseIndex: index,
        property: camelize(property),
        updatedExercise: updatedExercise,
        duration,
        durationText,
        durationWarning
      });
    });
  };

  const onChangeSide = (value) => {
    update_property(urls.customization, "side_id", value);
  };

  const onChangeTypology = (value) => {
    update_property(urls.customization, "xclinic_exercise_typology_id", value);
  };

  const onChangePosition = (direction) => {
    callApi(`${urls.move}?direction=${direction}`, "POST", "json", () => {
      refreshState(highlightElement);
    });
  };

  const onIncrementRepetitions = (value) => {
    if (value > 0) {
      update_property(urls.customization, "repetition_number", value)
    }
  }
  const onIncrementTime = (value, property) => {
    if (value > 0) {
      update_property(urls.customization, property, value)
    }
  }

  const highlightElement = () => {
    console.log(rootRef.current);
    rootRef.current.classList.add(style["Exercise--actived"]);
    setTimeout(() => {
      rootRef.current.classList.remove(style["Exercise--actived"]);
    }, 400);
  };

  const onDelete = () => {
    rootRef.current.style.height =
      rootRef.current.getBoundingClientRect().height + "px";
    callApi(urls.delete, "DELETE", "json", () => {
      setHide(true);
    });
  };

  const onTimeCustomization = () => {
    setTimeCustomization(!timeCustomization)
    //TODO: icona blu se selezionata
  }

  const enableArrowUp = !firstExercise;
  const enableArrowDown = !lastExercise;

  return (
    <div
      className={`${style.Exercise} ${hide ? style.hide : ""} row`}
      ref={rootRef}
    >
      <div className={`col-lg-5`}>
        <NameAndArrows
          onChangePosition={onChangePosition}
          name={name}
          typology={typology}
          enableArrowUp={enableArrowUp}
          enableArrowDown={enableArrowDown}
          enableTypologyBadge={false}
          videoUrl={urls.video}
        />
      </div>
      {timeCustomization &&
        <div className="col-lg-6 d-flex justify-content-around" style={{"paddingTop": "10px", "paddingBottom": "10px"}}>
          {renderTimeCustomizationBlock(state.translations?.timeBack, timeBack, "time_back")}
          {renderTimeCustomizationBlock(state.translations?.timeForth, timeForth, "time_forth")}
          {typology.code == 'TYPE_ISO' && renderTimeCustomizationBlock(state.translations?.timeWork, timeWork, "time_work")}
          {renderTimeCustomizationBlock(state.translations?.timeRest, timeRest, "time_rest")}
        </div>
      }
      {!timeCustomization &&
        <div className={`col-lg-2 py-3 text-center`}>
          {hasSide && (
            <RadiosBlock
              values={state.bulkActions["side"].values}
              selected={sideId}
              showTexts={false}
              inverted
              name={radioSideName}
              onChangeRadio={onChangeSide}
            />
          )}
        </div>
      }
      {!timeCustomization &&
        <div className={`col-lg-2 py-3 text-center ${style.balanceLabel}`}>
          {typology.code != 'TYPE_BAL' ?
            <RadiosBlock
              values={state.bulkActions.typology.values}
              selected={xclinicExerciseTypologyId}
              showTexts={false}
              inverted
              name={radioDurationName}
              onChangeRadio={onChangeTypology}
            />
          : state.translations?.balance
          }
        </div>
      }
      {!timeCustomization &&
        <div className={`col-lg-2 py-3 text-center text-lowercase`}>
          {typology?.code == "TYPE_BAL"
            ? renderDuration(timeForth)
            : renderRepetitions(repetitions)}
        </div>
      }
      <div className={`col-lg-1 py-3 px-3 text-center d-flex justify-content-end ${style.button}`}>
        {typology?.code != "TYPE_BAL" &&
          <div onClick={onTimeCustomization} ref={timeCustom}>
            <img src={timeCustomization ? SelectedClock : Clock}/>
          </div>
        }
        <div className={`ms-3 mt-1 ${style.button}`} onClick={onDelete}>
          <img src={DeleteIcon} />
        </div>
      </div>
    </div>
  );
}

XclinicExercise.propTypes = {};

XclinicExercise.defaultProps = {};

export default XclinicExercise;
