import React from "react";
import style from "./NotificationPreview.module.sass";
import trashHat from "../../../../../assets/images/trash_hat.svg"
import trashBottom from "../../../../../assets/images/empty-trash-bottom.svg"
import { callApi } from "../../../helpers";

function NotificationPreview(props) {
  const { 
    action, 
    actor, 
    id, 
    notificable, 
    onRemove, 
    path, 
    shortDate } = props;

  const onDeleteSuccess = () => {
    onRemove();
  };

  const deleteItem = () => {
    console.log(path)
    callApi(path, "DELETE", "", onDeleteSuccess, null, null, {
      id,
    });
  };

  const onClickDelete = (e) => {
    e.preventDefault();
    e.stopPropagation()
    deleteItem()
  };

  const goToPath = () => {
    window.location.href = notificable.path
  }

  return (
    <div className={`row ${style.notificationPreview}`} 
         id={`notification-${id}`} key={id} 
         style={{height: "75px"}}
         onClick={() => goToPath()}>
      <div className={`col-sm-3 ${style.notificationTime} text-center`}>
        {shortDate.day && 
          <span>
           <span className={style.day}>{shortDate.day}</span>
           <br/>
           <span className={style.month}>{shortDate.month}</span>
         </span>
        }
        {!shortDate.day && 
          <span>{shortDate}</span>
        }
      </div>
      <div className={`col-sm-9 d-flex flex-column justify-content-center`}>
        <div className={`${style.notificationText}`}>
          {actor.name}
        </div>
        <div className={`${style.notificationAction} auto-tooltip pointer-event-auto`}
             data-toggle="tooltip" title={action}>
          {action}
        </div>
        <a onClick={onClickDelete}
           href={"#"}
           className={style.cancel}>
        <img src={trashHat} alt="" className={`${style.hat}`} />
        <img src={trashBottom} alt="" className={`${style.bottom}`} />
        </a>
      </div>
    </div>
  );
}

NotificationPreview.propTypes = {};

NotificationPreview.defaultProps = {};

export default NotificationPreview;
