import React from "react";
import classes from './SliderIconTitleSubtitle.module.sass'
function SliderIconTitleSubtitle(props) {
  const icon = props.icon
  const title = props.title
  const subtitle = props.subtitle
  return (
    <div className="d-flex justify-content-start">
      <div>
        <img className={`${classes.icon} mt-2 mx-2`} src={icon}></img>
      </div>
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
    </div>
  )
}

export default SliderIconTitleSubtitle