import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./MenuItem.module.sass";
import { callApi } from "../../../helpers";

function MenuItem(props) {
  const {
    type,
    active,
    tooltip,
    url,
    id,
    stateChat,
    notitficationsCount,
    calendarURL,
    calendarRedirectURL,
    calendarData,
    disabled,
    hidden,
    initModal,
    icon
  } = props;
  const rootRef = useRef();
  const isModal = type === 'modal';

  useEffect(() => {
    if (stateChat) {
      rootRef.current.dataset.state = stateChat;
    }
    if (id == "wave") {
      initWaveBtn();
    }
  }, []);

  const onClickWave = (e) => {
    e.preventDefault();
    Preload.show();
    const calendarDataJSON = JSON.parse(calendarData);
    callApi(
      calendarURL,
      "POST",
      "json",
      (data) => {
        window.location.href = calendarRedirectURL;
      },
      null,
      null,
      {
        body: JSON.stringify({ ...calendarDataJSON }),
      },
      {
        "Access-Control-Allow-Origin": "*",
      }
    );
  };

  const styleForActiveIcon = (active) => {
    if(active) {
      if(icon == 'Chats') {
        return style.activeChat
      }else if (icon == 'XclinicPlans'){
        return style.activeXclinicPlans
      } else {
        return style.active
      }
    }
  }

  const initWaveBtn = () => {
    rootRef.current.addEventListener("click", onClickWave.bind(this));
  };

  const renderEuleriaLabSequences = (e) => {
    e.preventDefault();
    const uniqueUrl = `${url}&rand=${Math.random().toString(36).substring(2, 15)}`;
    initModal(uniqueUrl, tooltip);
  }

  return (
    <div>
      <div
        data-toggle={isModal ? 'modal' : undefined}
        data-target={isModal ? '#EuleriaLabSequencesModal' : undefined}
        data-bs-toggle={isModal ? 'modal' : undefined}
        data-bs-target={isModal ? '#EuleriaLabSequencesModal' : undefined}
      >
        <li
          className={`auto-tooltip ${styleForActiveIcon(active)} ${style.MenuItem} ${disabled ? "license-disabled-clickable" : ""} ${hidden ? "d-none" : ""}`}
          data-toggle="tooltip"
          data-placement="right"
          title={tooltip}
          id={id}
          ref={rootRef}
          onClick={isModal ? renderEuleriaLabSequences : undefined}
        >
          <a href={isModal ? '#' : url}>{props.children}</a>
          {notitficationsCount > 0 && (
            <span className={`${style.notificationsNumber}`}/>
          )}
        </li>
      </div>
    </div>
  );
}

MenuItem.propTypes = {};

MenuItem.defaultProps = {};

export default MenuItem;
