import React, { Fragment, useContext, useEffect, useRef } from "react";
import style from "./ExercisesContent.module.sass";
import Button from "../../Button/Button";
import { CustomizationsPageContext } from "../CustomizationsPage";
import DistrictBlock from "./DistrictBlock/DistrictBlock";
import TimingBlock from "../../EditPlan/EditPlanSummaryBlock/TimingBlock/TimingBlock";
import { callApi } from "../../../helpers";
function ExercisesContent(props) {
  const {} = props;
  const { state, dispatcher, refreshState } = useContext(
    CustomizationsPageContext
  );
  const rootRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", onResize.bind(this));
    onResize();
  }, []);

  const onResize = () => {
    const rootRefBox = rootRef.current.getBoundingClientRect();
    const newHeight = window.innerHeight - rootRefBox.y;
    rootRef.current.style.height = `${newHeight}px`;
  };

  const onClickSwitchDistricts = () => {
    callApi(state.urls.buttons.switch, "POST", "json", (data) => {
      refreshState();
    });
  };

  return (
    <div className={`${style.ExercisesContent}`} ref={rootRef}>
      {state.districts.map((district, index) => {
        if (index == 0) {
          return (
            <DistrictBlock
              district={district}
              key={`district_${district.id}`}
              index={index}
              type={state.sequence.type}
            />
          );
        } else {
          return (
            <Fragment key={`district_${district.id}`}>
              {state.urls.buttons.switch && 
                <div
                  className={`${style.ExercisesContentSwitcher}`}
                  onClick={onClickSwitchDistricts}
                >
                  <Button karyBtn kariBtnGreen>{state?.translations?.buttons.switch}</Button>
                </div>
              }
              <DistrictBlock
                district={district}
                key={`district_${district.id}`}
                index={index}
                type={state.sequence.type}
              />
            </Fragment>
          );
        }
      })}

      <div>
        <TimingBlock
          message={state.sequence?.durationWarning}
          duration={state.sequence?.duration}
          durationText={state.sequence?.durationText}
          showAlert={state.sequence?.showAlert}
          showTime={state.sequence?.showTime}
        />
      </div>
    </div>
  );
}

ExercisesContent.propTypes = {};

ExercisesContent.defaultProps = {};

export default ExercisesContent;
