import React from "react";

const ReactsPlans = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.421 12.1271C24.6919 11.9626 25.0307 11.9575 25.3065 12.1137L40.3065 20.6137C40.6522 20.8096 40.8225 21.2139 40.7212 21.5981C40.6199 21.9823 40.2724 22.25 39.8751 22.25H37.7501V37.875H36.0001V20.1849L24.8903 13.8894L14.7501 20.046L14.7501 37.875H13.0001V22.25H10.8751C10.4826 22.25 10.1382 21.9887 10.0324 21.6108C9.92669 21.2328 10.0855 20.8307 10.421 20.6271L24.421 12.1271Z" fill="#F5FAFF"/>
      <path d="M25 34L24.13 33.2807C21.04 30.7357 19 29.0518 19 26.9973C19 25.3134 20.452 24 22.3 24C23.344 24 24.346 24.4414 25 25.1335C25.654 24.4414 26.656 24 27.7 24C29.548 24 31 25.3134 31 26.9973C31 29.0518 28.96 30.7357 25.87 33.2807L25 34Z" fill="white"/>
    </svg>
  );
};

export default ReactsPlans;
