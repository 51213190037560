import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./CallRecordingsForm.module.sass";
import Button from "../../Button/Button";
import { callApi } from "../../../helpers";

function CallRecordingsForm(props) {
  const { callRecording, translations, url, updateData, isLiveCall } = props;
  const formRef = useRef();
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [fileSelected, setFileSelected] = useState(false)

  useEffect(() => {
    formRef.current.addEventListener("submit", (e) => {
      e.preventDefault();
      clickSubmitVideo(e.target);
    });
  }, []);

  const clickSubmitVideo = (formTarget) => {
    setSubmitDisabled(true);
    const formData = new FormData(formRef.current);
    const videoInput = formRef.current.querySelector("input[type=file]");
    const nameInput = formRef.current.querySelector("input[type=text]");
    formData.append("file", videoInput.files[0]);
    formData.append("title", nameInput);
    $.ajax({
      url: url,
      type: "POST",
      data: formData,
      success: function (data) {
        updateData();
        formTarget.reset(); // to reset the form
        setSubmitDisabled(false);
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  };

  const showFileUpload = () => {
    setFileSelected(true)
    let file = document.getElementById("videoUpload").files[0].name
    document.getElementById("filename").textContent = `${file}`
  }
  
  const defaultFileUpload = () => {
    setFileSelected(false)
    document.getElementById("filename").textContent = `${translations?.noFile}`
  }

  const fileUploadLabel = () => {
    if (fileSelected) {
      return translations?.changeFile
    } else {
      return translations?.chooseFile
    }
  }

  return (
    <form
      className={isLiveCall ? style.LiveCallRecordingsForm : style.CallRecordingsForm}
      encType="multipart/form-data"
      action="/call_recordings.home"
      acceptCharset="UTF-8"
      method="post"
      ref={formRef}
    >
      <input name="utf8" type="hidden" value="✓" />
      <input type="hidden" name="_method" value="patch" />
      <input
        value={callRecording.id}
        type="hidden"
        name="call_recording[call_id]"
      />
      <label 
        htmlFor={"videoUpload"}
        className={style.customUpload}>{fileUploadLabel()}</label>
      <input
        id="videoUpload"
        accept="video/webm"
        required="required"
        type="file"
        name="call_recording[recording]"
        className={style.hiddenUpload}
        onChange={() => showFileUpload()}
      />
      <div id="filename" className={!fileSelected ? style.noFile : style.filename}>
        {translations?.noFile}
      </div>
      <input
        required="required"
        placeholder={translations?.labelVideoname}
        className={`${style.videoName} form-control form-control-sm border-top-0 border-left-0 border-right-0 border-start-0 border-end-0 rounded-0`}
        style={{ color: "white", backgroundColor: "transparent" }}
        type="text"
        name="call_recording[name]"
      ></input>
      <input
        type="submit"
        name="commit"
        value={translations?.addVideo}
        className={style.addVideoButton}
        onClick={() => defaultFileUpload()}
        // data-disable-with={translations?.addVideo}
        disabled={submitDisabled}
      ></input>
    </form>
  );
}

CallRecordingsForm.propTypes = {};

CallRecordingsForm.defaultProps = {};

export default CallRecordingsForm;
