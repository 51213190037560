import React, { useState } from "react";
import style from "./SinglePatient.module.sass";
import PatientBox from "../PatientBox/PatientBox";
import LinkLoginCode from "../LinkLoginCode/LinkLoginCode";
import List from "../List/List";
import Slider from "../DailySequence/Slider/Slider";
import Call from "../Call/Call";
import SearchAndFilters from "./SearchAndFilters/SearchAndFilters";

function SinglePatient(props) {
  const {
    actions,
    activityId,
    archiveRedirectUrl,
    archiveTranslations,
    isDemo,
    licenses,
    patient,
    searchActivityPlaceholderText,
    sendResults } = props;

  const baseUrl = `/patients/${patient.id}.json`;

  const [currentActivityId, setCurrentActivityId] = useState(activityId)
  const [currentLicense, setCurrentLicense] = useState(licenses.current || licenses.active[0]);
  const [currentSearch, setCurrentSearch] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalUrl, setModalUrl] = useState("");

  //spostare in BE
  const allowedTypes = ["outdoor_session",
    "daily_sequence", "kari_connect_daily_sequence",
    "xclinic_sequence", "xclinic_evaluation",
    "eval_general_result", "frp_general_result", "assigned_program",
    "performance_general_result", "gait_test_general_result",
    "performance_training_balance", "performance_training_jump",
    "reacts_daily_sequence", "strength_general_result",
    "cardio_daily_sequence"
  ]

  const renderItem = (item) => {
    if (allowedTypes.includes(item.type)) {
      return (
        <div className={style.itemContainer}>
          <Slider 
            {...item} 
            initModal={initModal} 
            isEuleriaLab={item.license == "euleria_lab" && (item.inModal == true || item.inModal == undefined)} 
            isOpen={item.activityId == currentActivityId} />
        </div>
      );
    }

    if (item.type == "call") {
      return (
        <div className={style.itemContainer}>
          <Call
            {...item}
            isOpen={item.activityId == currentActivityId} />
        </div>
      );
    }

    return <div></div>;
  };

  const showLoginCode = () => {
    return (patient.patientLoginCode &&
      <LinkLoginCode
        linkLoginCode={patient.patientLoginCode}
        linkLoginCodeText={patient.linkLoginCodeText} />
    );
  };

  const initModal = (url, title) => {
    setModalUrl(url);
    setModalTitle(title);
  }

  const archiveRedirect = () => {
    window.location.href = archiveRedirectUrl
  }

  return (
    <div onClick={() => setCurrentActivityId(null)}>
      <div className="modal fade"
        id="EuleriaLabActivitiesModal"
        tabIndex="-1"
        aria-labelledby="EuleriaLabActivitiesModalLabel"
        aria-hidden="true">
        <div className={`modal-dialog modal-xl ${style.ModalLink}`}>
          <div className={`modal-content ${style.ModalContent}`}>
            <div className={`modal-header ${style.ModalHeader}`}>
              <button type="button"
                className="btn-close"
                data-dismiss="modal"
                data-bs-dismiss="modal"
                aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <iframe className="w-100 h-100" src={modalUrl}></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.SinglePatient}`}>

        <PatientBox
          {...patient}
          actions={actions}
          archiveRedirect={archiveRedirect}
          archiveTranslations={archiveTranslations}
          isDemo={isDemo}
          licenses={licenses.active}
          toClick={false} />
        <SearchAndFilters
          activeLicenses={licenses.active}
          currentLicense={currentLicense}
          hasEuleriaHomeActivities={patient.hasEuleriaHomeActivities}
          isDemo={isDemo}
          licensesData={licenses.data}
          setCurrentLicense={setCurrentLicense}
          setCurrentSearch={setCurrentSearch}
          searchActivityPlaceholderText={searchActivityPlaceholderText}
          sendResults={sendResults} />
        {
          patient.hasActivities ? (
            <List
              baseQuery={`${baseUrl}?license=${currentLicense}&search=${currentSearch}`}
              renderItem={renderItem} />
          ) : (
            showLoginCode()
          )
        }
      </div>
    </div>
  );
}

SinglePatient.propTypes = {};

SinglePatient.defaultProps = {};

export default SinglePatient;
