import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./DetailsCall.module.sass";
import Button from "../Button/Button";
import Note from "./Note/Note";
import VideoContainer from "./VideoContainer/VideoContainer";
import CallRecordingsForm from "./CallRecordingsForm/CallRecordingsForm";
import { callApi } from "../../helpers";

function DetailsCall(props) {
  const { callURL, insideBox } = props;
  const [state, setState] = useState({});

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    callApi(callURL, "GET", "json", (data) => {
      setState(data);
    });
  };

  const { translations, backUrl, callRecordings, form, notes } = state;

  const renderNotes = () => {
    return (
      <div className={style.list}>
        {notes.map((note) => (
          <div key={`note_${note.id}`} style={{marginBottom: "10px"}}>
            <Note {...note} />
          </div>
        ))}
      </div>
    )
  };

  const renderCallRecordings = () => {
    return callRecordings.map((recording) => (
      <VideoContainer
        key={`recording_${recording.id}`}
        translations={translations}
        {...recording}
        updateData={updateData}
      />
    ));
  };

  const rootClass = () => {
    let rootClass = style.DetailsCall
    if (insideBox) {
      rootClass += " " + style.insideBox;
    }
    return rootClass
  }

  return (
    <div className={`${rootClass()}`}>
      {state.translations && (
        <div className={`${style.CallGrid}`}>
          {!insideBox && (
            <div className={`col-lg-2 pt-3`}>
              <Button kariBtnWhite href={backUrl}>
                {translations.back}
              </Button>
            </div>
          )}
          <div className={`${style.CallNotes}`}>
            <div className={style.title}>{translations.notes}</div>
            {notes.length > 0 && renderNotes()}
          </div>
          <div className={`${style.CallVideos}`}>
            <div>
              <div className={style.title}>{translations.showVideos}</div>
              <div className={`d-flex`}>
                <div className={`${style.VideoSideScroller} me-2 pb-2`}>
                  {callRecordings.length > 0 && renderCallRecordings()}
                </div>
                <CallRecordingsForm
                  {...form}
                  translations={translations}
                  updateData={updateData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

DetailsCall.propTypes = {};

DetailsCall.defaultProps = {
  insideBox: false,
};

export default DetailsCall;
