import React from "react";

const Bell = () => {
  return (
    <svg
      height="35"
      viewBox="0 0 35 35"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m17.3710938 9.21875h-.1171876l-1.40625.29296875c-1.3281316.27343887-2.4218707.96679135-3.28125 2.08007815-.8593792 1.1132868-1.2890624 2.3730398-1.2890624 3.7792969v7.4414062l-.5273438.5859375-.46875.46875h14.0625l-.46875-.46875-.5273438-.5859375v-7.4414062c0-1.4062571-.4296832-2.6660101-1.2890624-3.7792969-.8593793-1.1132868-1.9531184-1.80663928-3.28125-2.08007815-.6640659-.19531348-1.1328112-.29296875-1.40625-.29296875zm-1.40625-3.69140625c.3515642-.35156426.8007785-.52734375 1.3476562-.52734375s.9863265.17577949 1.3183594.52734375.4980468.78124746.4980468 1.2890625v.87890625c1.7578213.42968965 3.2128849 1.35741475 4.3652344 2.7832031 1.1523495 1.4257884 1.7285156 3.0566315 1.7285156 4.8925782v6.6796874l2.4023438 2.4609376v1.2304687h-20.625v-1.2304687l2.40234375-2.4609376v-6.6796874c0-1.8359467.57616611-3.4667898 1.72851565-4.8925782 1.1523495-1.42578835 2.6074131-2.35351345 4.3652344-2.7832031v-.87890625c0-.50781504.1562484-.93749824.46875-1.2890625zm3.75 21.38671875c0 .6640658-.2343727 1.2402319-.703125 1.7285156-.4687524.4882837-1.035153.7324219-1.6992188.7324219s-1.2304664-.2441382-1.6992188-.7324219c-.4687523-.4882837-.703125-1.0644498-.703125-1.7285156z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Bell;
