import React, { useEffect, useState, useReducer } from "react";
import style from "./PersonalSequencePage.module.sass";
import SessionListContainer from "./SessionListContainer/SessionListContainer";
import SessionListContainerHorizontal from "./SessionListContainerHorizontal/SessionListContainerHorizontal";
import Sequences from "./Sequences/Sequences";
import NewSessionForm from "./NewSessionForm/NewSessionForm";
import { callApi } from "../../helpers";
import ThresholdMissingModal from "./ThresholdMissingModal/ThresholdMissingModal";
import PatientBox from "../PatientBox/PatientBox";

const initialState = {
  newSessionForm: {
    state: "instructions",
    indexes: [],
  },
  createUrl: null,
  createUrls: null,
  savedSequences: [],
  personalSequences: [],
  rental: {
    sessions: [],
  },
};

function reducer(state, action) {
  const newState = JSON.parse(JSON.stringify(action));
  state = { ...state, ...newState };
  return state;
}

function PersonalSequencePage(props) {
  const { baseUrl, header, months, title, type } = props;
  const [state, setState] = useReducer(reducer, initialState);
  const [openThresholdModal, setOpenThresholdModal] = useState(false);

  const getIndexesParamsString = (indexes) => {
    return "sessions_indexes[]=" + indexes.join("&sessions_indexes[]=");
  };

  const createSequence = (
    createBySavedSequenceUrl = null,
    refresh = true,
    onEndUpdateSesssion,
    savedSequenceType
  ) => {
    if (savedSequenceType == 'cardio_saved_sequences' && !state.patient.heartrateThresholds) {
      setOpenThresholdModal(true)
      return
    }
    callApi(createBySavedSequenceUrl, "POST", "json", (data) => {
      updateSessions(data.personal_sequence_id, data.personal_sequence_type, onEndUpdateSesssion, refresh);
    });
  };

  const createNewSequence = (url = undefined, personalSequenceType = '') => {
    if (personalSequenceType == 'cardio_personal_sequences' && !state.patient.heartrateThresholds) {
      setOpenThresholdModal(true)
      return
    }
    const { newSessionForm, createUrl, rental } = state;
    url = url || createUrl
    callApi(
      url,
      "POST",
      "json",
      (data) => { window.location.href = data.location },
      null,
      null,
      {
        body: JSON.stringify({
          rental_id: rental.id,
          // NB: uncomment to assign sequence on creation
          // sessions_indexes: newSessionForm.indexes
        })
      }
    )
  };

  const updateSessions = (
    personalSequenceId,
    personalSequenceType,
    onSuccess = null,
    refresh = true
  ) => {
    if (personalSequenceType == 'CardioPersonalSequence' && !state.patient.heartrateThresholds) {
      setOpenThresholdModal(true)
      return
    }

    const { newSessionForm, rental } = state;
    if (newSessionForm.indexes.length > 0) {
      const url = `${rental.sessionsUrl
        }?personal_sequence_id=${personalSequenceId}&personal_sequence_type=${personalSequenceType}&${getIndexesParamsString(
          newSessionForm.indexes
        )}`;
      callApi(
        url,
        "PATCH",
        "json",
        (data) => onUpdateSessionSuccess(data, onSuccess, refresh),
        onUpdateSessionError
      );
    }
  };

  const onUpdateSessionSuccess = (data, onSuccess, refresh) => {
    if (onSuccess) {
      onSuccess();
    }
    if (refresh) {
      refreshComponent(data);
    }
  };

  const onUpdateSessionError = (data) => {
    console.log('error', data);
  };

  useEffect(() => {
    callMainJSON();
  }, []);

  const callMainJSON = () => {
    callApi(
      baseUrl,
      "GET",
      "json",
      onCallSuccess
    );
  };

  const onCallSuccess = (data) => {
    setState(data);
  };

  const refreshComponent = (data) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.newSessionForm.indexes = [];
    newState.newSessionForm.state = "instructions";
    setState(newState);
    callMainJSON();
  };

  const cancelSessionsUpdate = () => {
    setOpenThresholdModal(false)
    refreshComponent(JSON.stringify(state))
  }

  return (
    <div className={`${style.PersonalSequence}`}>
      <PersonalSequencePageContext.Provider
        value={{
          state: state,
          setState,
          updateSessions,
          createSequence,
          createNewSequence,
          refresh: callMainJSON,
          type: type,
          months: months
        }}
      >
        <div className={style.PersonalSequencePageContainer}>
          <div className={style.title}>{title}</div>
          <div className={`row`} style={{ height: "calc(100vh - 75px)" }}>
            <div className={`col-md-4 ${style.sessionListCol}`}>
              <SessionListContainer />
            </div>
            <div className={'col-md-8'}>
              {openThresholdModal &&
                <ThresholdMissingModal
                  editPatientUrl={state.patient?.editUrl}
                  closeModal={cancelSessionsUpdate}
                  translations={state.translations?.thresholdModal}
                />
              }
              <a className={style.backButton} href={header.backBtn.url}>
                {header.backBtn.text}
              </a>

              <PatientBox
                patientName={state.patient?.name}
                isDemo={true}
                activeActions={[]}
                toClick={'clickable'}
                linkToClick={state.patient?.showUrl}
                patientLoginCode={state.patient?.linkCode} />

              <div className={`${style.session}`}>
                <div className={`${style.sessionTitle}`}>
                  {state.translations?.sessions}
                </div>
                <SessionListContainerHorizontal />
                <div className={`mt-3`}>
                  <Sequences />
                </div>
                <div>
                  <NewSessionForm {...state.translations} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PersonalSequencePageContext.Provider>
    </div>
  );
}

PersonalSequencePage.propTypes = {};

PersonalSequencePage.defaultProps = {};

export const PersonalSequencePageContext = React.createContext({});
export default PersonalSequencePage;
