import React from "react";
import PropTypes from "prop-types";
import style from "./ExercisesBar.module.sass";

function ExercisesBar(props) {
  const {
    colors,
    exercises,
    label,
    noBorder,
    showPain
  } = props;

  const backgroundColorStyle = (exercise) => {
    if (exercise.pain_value) {
      return '#FF4F4F'
    } else if (['interrupted', 'skipped'].includes(exercise.state)) {
      return '#226A89'
    } else if (exercise.state) {
      return colors.primaryColor
    }
    return ''
  }

  const pain = (exercise) => {
    return exercise.pain_value > 0 && showPain ? exercise.pain_value : "";
  };

  let rootClass = `${style.ExercisesBar}`;
  if (noBorder) {
    rootClass += ` ${style["ExercisesBar--noBorder"]}`;
  }

  return (
    <div className={rootClass}>
      {exercises.map((exercise, index) => {
        return (
          <div key={`${label}-${index}-${exercise.id}`}>
            <div style={{ backgroundColor: backgroundColorStyle(exercise) }}>
              <div className={style.painLabel}>{pain(exercise)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

ExercisesBar.propTypes = {
  exercises: PropTypes.array,
};

ExercisesBar.defaultProps = {
  showPain: false,
  noBorder: false,
  exercises: []
};

export default ExercisesBar;
