import React, { useState, useEffect, useRef } from "react";
import style from "./DoneTable.module.sass";



function DoneTableColumnSpan(props) {
  const {
    done,
    done1,
    done2,
    done3,
    typeDone,
    index
  } = props

  console.log(props);

  if (index==1) {
  return (
  
    <div  className={style.tableColumnSpan}>
      <div className={style.valueColumnSpan}>{done1==true? <div className={style.imgDone}></div> : <div className={style.imgSkip}></div>}</div>
      <div className={style.valueColumnSpan}>{done2==true? <div className={style.imgDone}></div> : <div className={style.imgSkip}></div>}</div>
      <div className={style.valueColumnSpan}>{done3==true? <div className={style.imgDone}></div> : <div className={style.imgSkip}></div>}</div>

    </div>
  );}
  else {
    return (
    <div  className={style.tableColumnSpan}>
      <div className={style.valueColumnSpan}></div>
      <div className={style.valueColumnSpan}></div>
      <div className={style.valueColumnSpan}></div>
    </div>
    );
  }
}

export default DoneTableColumnSpan;
