import React, { useState, useEffect, useRef } from "react";
import { callApi } from "../../../helpers";
import style from "./UpperLimbResultDetail.module.sass"
import LineAsimmetry from "./LineAsimmetry/LineAsimmetry";
import DoneTableColumn from "./DoneTable/DoneTableColumn";
import DoneTableRow from "./DoneTable/DoneTableRow";
import DoneTableColumnSpan from "./DoneTable/DoneTableColumnSpan";
function UpperLimbResultDetail(props) {

  const {
    index,
    movement,
    sideInjuredId,
    typeDone
  } = props

  const chart = useRef(null);

  const [chartVisibility, setChartVisibility] = useState(false)
  const color_left = sideInjuredId==1 ?  "#70BBFF" : "#B7DCFE";
  const color_right = sideInjuredId==2 ?  "#70BBFF" :"#B7DCFE";
  console.log(sideInjuredId);


  useEffect(() => {
    if(true) {
  


    }
  }, [])


  var line = index%2==0 ? style.even : style.odd
  
  return (
   
    <div className={`${style.resultRow} ${line}`}>
    < div className={`${style.movementTitle}`}>
      {movement.code}{movement.star}
      { movement.label && 
      <div className={style.label}>
              {movement.label}
        </div>
       
      }
    </div>
    <div className={`${style.valueLeft}`}  id="leftValue" >
      {movement.left}
    </div>
    <div className={style.valueLSI} >
      { movement.lsi && < LineAsimmetry
                  value={movement.lsi} 
                  identifier={`${index}-${movement.code}`}/>
      }
      {!movement.lsi && <div className={style.void}>&nbsp;</div>
      }
    </div>
    <div className={`${style.valueRight} ` } id="rightValue" >
      {movement.right}
    </div>
    <div className={`${style.voidSpace} ` }>
     &nbsp;
    </div>
    
    {(typeDone=="column" && movement.label)  && 
    <div className={`${style.done} ` }> 
    < DoneTableColumn
                  done1={movement.done_1}
                  done2={movement.done_2}
                  done3={movement.done_3}
                  typeDone= {typeDone}
                  identifier={`${index}-${movement.code}`}/> 
    </div>
                  }

    {(typeDone=="row" )  && 
        <div className={`${style.done} ` }>
          < DoneTableRow
                  done={movement.done}
                  typeDone= {typeDone}
                  identifier={`${index}-${movement.code}`}/>
    </div>
    }

    {(typeDone=="column_span"&& movement.label )  && 
        <div className={`${style.done} ` }> 
        < DoneTableColumnSpan
                  done1={movement.done_1}
                  done2={movement.done_2}
                  done3={movement.done_3}
                  typeDone= {typeDone}
                  index={index}/>
    </div>
    }
    {(typeDone=="column"&& !movement.label )  && 
        <div className={`${style.doneVoid} ` }> 
        
    </div>
    }

    </div>
    
  
  )
}

export default UpperLimbResultDetail