import React, {useRef, useEffect} from "react";
import classes from './SliderProgressBar.module.sass'
import monetinaTriste from './assets/monetina-triste.png'

function SliderProgressBar(props){
  const {
    colors,
    isDetail,
    score,
    sessionId,
    statusSlider
  } = props

  const boxRef = useRef();

  const animateRunner = () => {
    const speed = 3;
    TweenLite.to(boxRef.current, speed, {
      left: '70%',
      ease: Power1.easeInOut,
      onComplete: () => {
        TweenLite.to(boxRef.current, speed, {
          left: '-30px',
          ease: Power1.easeInOut,
          onComplete: () => {
            animateRunner(boxRef);
          }
        })
      }
    })
  }

  useEffect(() => {
    if(statusSlider=="loading") {
      animateRunner()
    }
  });

  const loaderClasses = () => {
    if(statusSlider=="loading"){
      return `${classes.monetina} ${sessionId}`
    } else {
      return `d-none ${classes.monetina} ${sessionId}`
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <img src={monetinaTriste}
           ref={boxRef}
           className={loaderClasses()} />

      <div className={`progress ${sessionId} ${classes.customBackground}`} style={isDetail ? {height: "11px"} : {}}>
        <div className={`progress-bar ${classes.secondaryColor}`}
             role="progressbar"
             style={{height: isDetail ? "7px" : "", width: score+'%', backgroundColor: colors?.primaryColor}}
             aria-valuenow={score}
             aria-valuemin="0"
             aria-valuemax="100" />
      </div>
    </div>)
}

export default SliderProgressBar