import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classes from "./KariConnectRepetitions.module.sass";
// todo: questo non è repetitions ma qualcosa come timemeasure
function KariConnectRepetitions(props) {
  const {
    debug,
    exerciseTypology,
    quantity,
    quantityUnit,
    label
  } = props;

  const cssClass = () => {
    return  `${classes.number} ${exerciseTypology !== "TYPE_REP" ? classes.duration : ''}`
  }
  return (
    <div className={classes.repetitions}>
      {debug && <div>{exerciseTypology}</div>}
      <div className={classes.label}>{label.toUpperCase()}</div>
      <div style={{ top: 10 + "px" }} className="text-center pt-3">
      <div className={`${classes.quantity} ${cssClass()}`}>
          {quantity}
          <span className={classes.quantityUnit}>{quantityUnit}</span></div>
      </div>
    </div>
  );
}

KariConnectRepetitions.propTypes = {
  label: PropTypes.string,
  number: PropTypes.number,
};

KariConnectRepetitions.defaultProps = {
  debug: false
};

export default KariConnectRepetitions;
