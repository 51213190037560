import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./SessionListContainer.module.sass";
import { PersonalSequencePageContext } from "../PersonalSequencePage";

function SessionListContainer(props) {
  const { state, setState } = useContext(PersonalSequencePageContext);
  const { rental, personalSequences, savedSequences, translations } = state;

  const renderVisualizer = (personalSequence) => {
    // TODO: remove -1 clause
    if (personalSequence.personal_sequence_id == null || personalSequence.personal_sequence_id == -1) {
      return <div className={`${style.sessionVisualizer}`} />;
    } else {
      const sessionItem = getSessionItem(personalSequence.personal_sequence_id, personalSequence.personal_sequence_type);
      if (sessionItem) {
        let width = `min(${sessionItem.totExercises * 20}px, 300px)`;
        width =
          sessionItem.totExercises > 10
            ? "80%"
            : (sessionItem.totExercises / 10) * 80 + "%";
        return (
          <div
            className={`${style.sessionVisualizer} ${style.counterNumber}`}
            style={{ width: width }}
          />
        );
      } else {
        return <div className={`${style.sessionVisualizer}`}> ~ ~ ~ ~</div>;
      }
    }
  };

  const renderSessionNumber = (personalSequence, index) => {
    const sessionItem = getSessionItem(personalSequence.personal_sequence_id, personalSequence.personal_sequence_type);
    let totExercises = "";
    if (sessionItem) {
      totExercises = sessionItem.id < 0 ? "" : sessionItem.totExercises;
    }
    return <div className={`${style.sessionNumber}`}>{totExercises}</div>;
  };

  const getSessionItem = (id, type) => {
    let sessionItem = null
    personalSequences.forEach((personalSequenceGroup) => {
      sessionItem ||= personalSequenceGroup.items.find((item) => item.id == id && item.type == type);
    })
    return sessionItem;
  };

  const savedSequence = (id) => {
    return savedSequences.find((item) => (item.id = id));
  };

  const renderSession = (personalSequence, index) => {
    return (
      <div
        className={`${style.sessionLine}`}
        key={`session-${personalSequence.personal_sequence_id}-${personalSequence.personal_sequence_type}-${index}}`}
      >
        <div className={`${style.sessionCounter}`}>{personalSequence.counter}</div>
        {renderVisualizer(personalSequence)}
        {personalSequence.personal_sequence_id >= 0 ? renderSessionNumber(personalSequence, index) : null}
      </div>
    );
  };

  return (
    <div className={`${style.SessionListContainer}`}>
      <div className={`${style["session-title-x"]}`}>
        {translations?.exercises}
      </div>
      <div className={`${style["session-title-y"]}`}>
        {translations?.sessions}
      </div>
      <div className={`${style.sessionList}`}>
        {rental.sessions.map((personalSequence, index) => {
          return renderSession(personalSequence, index);
        })}
      </div>
    </div>
  );
}

SessionListContainer.propTypes = {};

SessionListContainer.defaultProps = {};

export default SessionListContainer;
