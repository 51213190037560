import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./DistrictBlock.module.sass";
import ExercisesList from "../ExercisesList/ExercisesList";

function DistrictBlock(props) {
  const { district, index, type } = props;

  const districtNameColor = () => {
    if (type == "xclinic_saved_sequence"){
      switch(district.id) {
        case 1:
          return "#00D79C"
        case 2:
          return "#FFD35A"
        case 3:
          return "#FF8197"
        case 4:
          return "#A778FF"
        case 5:
          return "#B7DCFE"
      }
    } else if(type == "cardio_saved_sequence" || type == "cardio_personal_sequence") {
      return district.color
    } else {
      return "white"
    }
  }

  const renderLineAfterDistrictName = () => {
    if(type == "xclinic_saved_sequence") {
      return style.withAfter
    } else if(type == "cardio_saved_sequence" || type == "cardio_personal_sequence") {
      return style.withAfterLong
    }
  }

  return (
    <div className={`${style.DistrictBlock}`}>
      {district.name &&
        <h1 className={`${style.ToolBlock} ${renderLineAfterDistrictName()}`} 
            style={{"color": districtNameColor()}}>
          {district.name}
        </h1>
      }
      <ExercisesList
        exercisesGroups={district.groups}
        district={district.id}
        districtIndex={index}
        type={type}
      />
    </div>
  );
}

DistrictBlock.propTypes = {};

DistrictBlock.defaultProps = {};

export default DistrictBlock;
