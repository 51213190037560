import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./LeftRightChooser.module.sass";

function LeftRightChooser(props) {
  const { leftText, rightText, onEnableLeft, onEnableRight } = props;
  const [leftEnable, setLeftEnable] = useState(true);
  const [rightEnable, setRightEnable] = useState(true);

  useEffect(() => {
    onEnableLeft(leftEnable);
    onEnableRight(rightEnable);
  }, [leftEnable, rightEnable]);

  const onClickLeft = () => {
    setLeftEnable(() => !leftEnable);
  };

  const onClickRight = () => {
    setRightEnable(() => !rightEnable);
  };

  return (
    <div className={`${style.leftRightChooser}`}>
      <div onClick={onClickLeft} className={`${style.leftRightChooserLabel}`}>
        {leftText}
      </div>
      <div className={`${style.leftRightChooserLabelSwitcher}`}>
        <div
          style={{ left: leftEnable ? "48%" : "16%" }}
          className={`${style.leftRightChooserLabelSwitcherCircle}`}
        ></div>
        <div
          style={{ left: rightEnable ? "52%" : "84%" }}
          className={`${style.leftRightChooserLabelSwitcherCircle}`}
        ></div>
        <div
          className={`${style.leftRightChooserLabelSwitcherHelper}`}
          onClick={onClickLeft}
        />
        <div
          className={`${style.leftRightChooserLabelSwitcherHelper}`}
          onClick={onClickRight}
        />
      </div>
      <div onClick={onClickRight} className={`${style.leftRightChooserLabel}`}>
        {rightText}
      </div>
    </div>
  );
}

LeftRightChooser.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  onClickLeft: PropTypes.func,
  onEnableRight: PropTypes.func,
};

LeftRightChooser.defaultProps = {
  leftText: "Need translation",
  rightText: "Need translation",
  onEnableLeft: null,
  onEnableRight: null,
};

export default LeftRightChooser;
