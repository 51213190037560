import React from "react";
// import PropTypes from "prop-types";
import classes from "./ProgressBar.module.sass";
import ProgressBarTooltip from "./ProgressBarTooltip";

const ProgressBar = (props) => {
  const {
    color,
    date,
    labelDate,
    percentage,
    tooltip,
    tooltipValue
  } = props

  // Percentage è un valore da 0 a 1
  // ex: 0.46354
  // Togliamo tutti i valori dopo la prima cifra dopo la virgola (ex: 0.46780 diventa 0.4)
  // Poi portiamo il valore a 50 pixel.
  const percentageCorrected = Number.parseFloat(percentage).toFixed(1)
  const value =  Math.ceil(50*(percentageCorrected));
  const valueOfBackground =  Math.ceil(50*(1-percentageCorrected));
  return (
    <ProgressBarTooltip enabled={tooltip}
                        labelDate={labelDate}
                        date={date}
                        tooltip={tooltip}
                        tooltipValue={tooltipValue}>
      <div className={classes.itemUp}
           style={{height: valueOfBackground+'px' }} />
      <div className={classes.itemDown}
           style={{height: value+'px', backgroundColor: color}} />
    </ProgressBarTooltip>
  );
};

ProgressBar.defaultProps = {
  color: '#f0f0f0'
}
export default ProgressBar