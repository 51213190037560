import React from "react";
import SummaryBlockItem from "../SummaryBlockItem/SummaryBlockItem";
import style from "./SummuaryExercisesList.module.sass";

function SummuaryExercisesList(props) {
  const {
    exerciseGroup, 
    firstItem,
    listKey,
    removeItem,
    translations
  } = props;

  return (
    <ul className={style.SummuaryExercisesList}>
      {exerciseGroup.label &&
        <div className={`${style.listHeader} ${firstItem && style.firstHeader}`}
             style={{color: exerciseGroup.color}}>
          <div>{exerciseGroup.label}</div>
          {exerciseGroup.tooltip && 
            <div className={`auto-tooltip ${style.infoIcon}`}
                 title={exerciseGroup.tooltip}>
            </div>
          }
        </div>
      }
      {exerciseGroup.items.length == 0 &&
        <div className={style.placeholder}>{translations.placeholder}</div>
      }
      {exerciseGroup.items.length > 0 && 
        exerciseGroup.items.map((exercise, index) => {
          return (
            <li key={`${listKey}-${index}`}>
              <SummaryBlockItem
                {...exercise}
                undeletableTooltip={translations.undeletableTooltip}
                removeItem={removeItem}
                showClose
              />
            </li>
          );
        })
      }
    </ul>
  );
}

SummuaryExercisesList.propTypes = {};

SummuaryExercisesList.defaultProps = {
  firstItem: false
};

export default SummuaryExercisesList;
