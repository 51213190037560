import React, { Fragment, useState } from "react";
import { debounce, triggerTextChange } from "../../../helpers";
import style from "./FavouritePlansHeader.module.sass";

function FavouritePlansHeader(props) {
  const { onChangeFilter, searchPlaceholder } = props;

  const [search, setSearch] = useState("");

  const handleSearchChange = debounce((e) => {
    triggerTextChange(
      e,
      (text) => {
        setSearch(text);
        onChangeFilter(text);
      },
      search
    );
  }, 200);

  const userIsSearching = (e) => {
    handleSearchChange(e);
  };

  return (
    <div className={`${style.FavouritePlansHeader} text-white`}>
      <i className={`fa fa-search ${style.iconSearch}`}></i>
      <input
        id="patient-search"
        className={`form-control input-kari`}
        onChange={userIsSearching}
        type={"search"}
        placeholder={searchPlaceholder}
      />
    </div>
  );
}

export default FavouritePlansHeader;
