import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Modal.module.sass";
import menuItemStyle from "../LeftMenu/MenuItem/MenuItem.module.sass"
import { useForceUpdate } from "../../helpers";
import FullBackgroundLayer from "./FullBackgroundLayer/FullBackgroundLayer";
import ModalContent from "./ModalContent/ModalContent";

const Modal = (props) => {
  const [visible, setVisible] = useState(false);
  const [activeIcon, setActiveIcon] = useState(document.querySelector(`.${menuItemStyle.active}`) || document.querySelector(`.${menuItemStyle.activeChat}`))
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const { triggerButtons, closeButtons } = props;
    if (triggerButtons) {
      initTriggerButtons();
    }
    if (props.snapToElement) {
      window.addEventListener("resize", onResize.bind(this));
    }
    if (props.open) {
      openModal();
    }
    if (closeButtons) {
      initCloseButtons()
    }
  }, []);

  useEffect(() => {
    if (props.navbar && activeIcon) {
      if(visible) {
        if(activeIcon.id == "chat-icon-side-menu") {
          activeIcon.classList.remove(menuItemStyle.activeChat)
        } else {
          activeIcon.classList.remove(menuItemStyle.active)
        }
      } else {
        if(activeIcon.id == "chat-icon-side-menu") {
          activeIcon.classList.add(menuItemStyle.activeChat)
        } else {
          activeIcon.classList.add(menuItemStyle.active)
        }
      }
    }
  }, [visible])

  const onResize = () => {
    forceUpdate();
  };

  const initTriggerButtons = () => {
    const { triggerButtons } = props;
    triggerButtons.map((buttonQuery) => {
      const button = document.querySelector(buttonQuery);
      button.addEventListener("click", clickOnTriggerButton.bind(this));
    });
  };

  const clickOnTriggerButton = (e) => {
    e.preventDefault();
    e.stopPropagation()
    openModal();
  };

  const openModal = () => {
    setVisible(true);
    if (props.onModalOpen) {
      props.onModalOpen();
    }
    if (props.navbar) {
      document.querySelector("#navbar").style.setProperty("z-index", 1002)
      document.querySelector("#newsTrigger").classList.add(styles.newsIcon)
    }
  };

  const initCloseButtons = () => {
    const {closeButtons} = props
    closeButtons.map((buttonQuery) => {
      const button = document.querySelector(buttonQuery)
      button.addEventListener("click", clickOnCloseButton.bind(this))
    })
  }

  const clickOnCloseButton = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setVisible(false)
  }

  const onClickBackground = (e) => {
    setVisible(false);
    if (props.navbar) {
      document.querySelector("#navbar").style.setProperty("z-index", 1000)
      document.querySelector("#newsTrigger").classList.remove(styles.newsIcon)
    }
  };

  let modalClasses = styles.modal;
  if (visible) {
    modalClasses += " " + styles["modal--show"];
  }

  return (
    <div
      className={modalClasses}
      style={{ pointerEvents: visible ? "all" : "none" }}
    >
      <FullBackgroundLayer
        background={props.background}
        opacity={visible ? 0.8 : 0}
        backgroundColor={props.backgroundColor}
        clickOnBackground={
          props.closeOnBackgroundClick ? onClickBackground : null
        }
      />
      <ModalContent
        opacity={visible ? 1 : 0}
        snapToElement={props.snapToElement}
        navbar={props.navbar}
      >
        {props.children}
      </ModalContent>
    </div>
  );
};

Modal.propTypes = {
  background: PropTypes.bool,
  backgroundColor: PropTypes.string,
  closeOnBackgroundClick: PropTypes.bool,
  snapToElement: PropTypes.string,
  navbar: PropTypes.bool
};

Modal.defaultProps = {
  /** Add or not the background of the modal */
  background: true,
  /** In case background is true, set his color */
  backgroundColor: "rgb(0,0,0)",
  /** Enable click on background to close the overlay */
  closeOnBackgroundClick: true,
  /** Open the modal by default */
  open: false,
  /** Query to an html node which the modal should be snapped to */
  snapToElement: "",
  /** Show or not the navbar when the background is true*/
  navbar: false
};

export { Modal as default };
