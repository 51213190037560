import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./Calendar.module.sass";
import ChevronUp from "./assets/chevron-up.svg";
import {
  callApi,
  getBaseURL,
  getYearMonthDayFromTimePickerDate,
} from "../../../helpers";

function Calendar(props) {
  const { randomURLParams } = props;
  const [url, setUrl] = useState(props.calendarURL);
  const [calendar, setCalendar] = useState(null);
  const [offset, setOffset] = useState(
    props.offsetDate !== null ? props.offsetDate : 0
  );
  const [currentDate, setCurrentDate] = useState(props.currentDate);

  const today = new Date();
  console.log(today);

  useEffect(() => {
    callApi(
      `${url}?r=${randomURLParams}&page=${offset}`,
      "GET",
      "json",
      onGetCalendar
    );
  }, [url, offset, randomURLParams]);

  useEffect(() => {
    updateUrl();
  }, [offset, currentDate]);

  useEffect(() => {
    window.history.replaceState({}, "", getNewURL());
  }, []);

  const updateUrl = () => {
    window.history.pushState({}, "", getNewURL());
  };

  const getNewURL = () => {
    let url = getBaseURL(window.location.href) + "?offset_date=" + offset;
    url += "&day_in_list=" + currentDate;
    return url;
  };

  const onGetCalendar = (data) => {
    setCalendar(data);
  };

  const clickOnArrow = (direction) => {
    setOffset(parseInt(offset) + direction);
  };

  const renderArrow = () => {
    return <img src={ChevronUp} />;
  };

  const renderDayTitles = (row) => {
    return calendar.day_names.map((day, index) => {
      const gridColumnStart = index + 2;
      const gridRowStart = row;
      let dayClass = style.label;
      dayClass += " " + style["day--" + index];
      if (gridColumnStart != 8) {
        dayClass += " " + style.line;
      }
      return (
        <div
          key={`day_title_${day}`}
          className={dayClass}
          style={{
            gridColumnStart,
            gridRowStart,
          }}
        >
          {day[0]}
        </div>
      );
    });
  };

  const renderMonth = (row, monthName) => {
    let rootClass = style.monthsVertical;
    const gridRowStart = row;
    const gridColumnStart = 1;
    return (
      <div
        key={`month_${monthName}`}
        className={rootClass}
        style={{
          gridColumnStart,
          gridRowStart,
        }}
      >
        <div>{monthName}</div>
      </div>
    );
  };

  const renderMonths = (row) => {
    let startWeekRow = row;
    return calendar.dates.map((month, index) => {
      const currentStartRow = startWeekRow;
      startWeekRow += month.dates.length;
      return renderWeeks(month, currentStartRow);
    });
  };

  const renderWeeks = (month, row) => {
    let startDayRow = row;
    return month.dates.map((week, index) => {
      const currentStartRow = startDayRow;
      startDayRow += 1;
      return renderDays(week, currentStartRow, month.name);
    });
  };

  const onClickSpecial = (date) => {
    props.onClickSpecial(date);
    const dateArray = date.split("-");
    setCurrentDate(`${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`);
  };

  const renderDays = (week, row, monthName) => {
    return week.map((day, index) => {
      const gridColumnStart = index + 2;
      const gridRowStart = row;

      return day ? (
        <Fragment>
          {day?.day == 1 ? renderMonth(row, monthName) : null}
          <div
            key={`day_${row}_${index}`}
            className={`${style.day} ${style["day--" + index]}  ${
              day?.scheduled ? style.special : ""
            } ${day?.past ? style.past : ""} ${
              gridColumnStart == 7 || gridColumnStart == 8 ? style.weekend : ""
            }`}
            style={{ gridColumnStart, gridRowStart }}
            onClick={day?.special ? null : () => onClickSpecial(day?.date)}
          >
            {day?.day}
          </div>
        </Fragment>
      ) : null;
    });
  };

  const renderCalendar = () => {
    return (
      <div className={`${style.CalendarDays}`}>
        {renderDayTitles(1)}
        {renderMonths(2)}
      </div>
    );
  };

  return (
    <div className={`${style.Calendar}`}>
      <div className={`${style.arrows}`}>
        <div
          onClick={() => clickOnArrow(-1)}
          className={`${style.arrow} ${style.arrowPrev}`}
        >
          {renderArrow()}
        </div>
        <div
          onClick={() => clickOnArrow(1)}
          className={`${style.arrow} ${style.arrowNext}`}
        >
          {renderArrow()}
        </div>
      </div>
      {calendar && renderCalendar()}
    </div>
  );
}

Calendar.propTypes = {};

Calendar.defaultProps = {};

export default Calendar;
