import React, { useEffect } from "react";

import iconInterrupted from "./assets/interrupted.svg";
import iconSkip from "./assets/skip.svg";
import iconPain from "./assets/pain.svg";
import classes from "./Score.module.sass";

function Score(props) {
  const {
    colors,
    interrupted,
    interruptedLabel,
    skipped,
    skippedLabel,
    pain,
    painValue,
    painLabel,
    score
  } = props;

  let mainClassName = classes.scoreContainer;
  if (!pain) {
    mainClassName += " " + classes.scoreContainerAlignBottom;
  }

  useEffect(() => {
    $('[data-toggle="tooltip-side"]').tooltip();
  }, []);

  const ImageInterrupted = () => {
    return (
      <img
        className="auto-tooltip"
        data-toggle="tooltip-side"
        title={interruptedLabel}
        style={{ marginBottom: 10 + "px" }}
        src={iconInterrupted}
      />
    );
  };

  const ImageSkipped = () => {
    return (
      <img
        className="auto-tooltip"
        data-toggle="tooltip-side"
        title={skippedLabel}
        src={iconSkip}
      />
    );
  };
  const PainOrScore = () => {
    if (pain) {
      return (
        <div
          className={
            classes.painChart + " pointer-events-auto auto-tooltip text-center"
          }
          style={{paddingTop: '10px'}}
          data-toggle="tooltip-side"
          title={painLabel}
        >
          <img className="auto-tooltip" src={iconPain} />
          <div>{painValue}</div>
        </div>
      );
    } else {
      return <div className={classes.percentageChart} style={{paddingBottom: '10px', color: colors.primaryColor}}>{score}</div>;
    }
  };
  return (
    <div className={mainClassName}>
      {interrupted && painValue == 0 && ImageInterrupted()}
      {skipped && painValue == 0 && ImageSkipped()}

      {PainOrScore()}
    </div>
  );
}

export default Score;
