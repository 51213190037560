import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./NewNote.module.sass";
import { callApi } from "../../../helpers.js";

function NewNote(props) {
  const { placeholder, newNotePath, callId, updateNotes } = props;

  const [text, setText] = useState("");

  const onSuccess = (data) => {
    updateNotes();
  };

  const onKeyUpHandler = (e) => {
    if (e.key == "Enter") {
      setText("");
      callApi(
        newNotePath,
        "POST",
        "json",
        onSuccess,
        null,
        () => {
          updateNotes();
        },
        {
          body: JSON.stringify({
            call_note: {
              call_id: callId,
              note: text,
            },
          }),
        }
      );
    }
  };

  const onChangeHandler = (e) => {
    setText(e.target.value);
  };

  return (
    <div className={`${style.NewNote}`}>
      <input
        value={text}
        type={"text"}
        className={style.TextNote}
        placeholder={placeholder}
        required={true}
        onKeyUp={onKeyUpHandler}
        onChange={onChangeHandler}
      ></input>
    </div>
  );
}

NewNote.propTypes = {};

NewNote.defaultProps = {};

export default NewNote;
