import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./SelectAuxiliary.module.sass";

function SelectAuxiliary(props) {
  const {
    name,
    options,
    selected,
    explanation,
    ifYouWantAddText,
    keywordText,
    selectHereText,
    tooltipText
  } = props;

  const onChangeSelect = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <div className={`${style.SelectAuxiliary}`}>
      <p>
        {ifYouWantAddText}
        <span
          className={"auto-tooltip"}
          title={tooltipText}
        >
          {`  ${keywordText} `}
        </span>
        {selectHereText}
      </p>
      <p>
        <select
          name={name}
          className={`kari-select text-kari w-100`}
          defaultValue={selected}
          onChange={onChangeSelect}
        >
          {options.map((option, index) => {
            return (
              <option
                key={"option_" + index + "_" + option.id}
                value={option.id}
              >
                {option.text}
              </option>
            );
          })}
        </select>
      </p>
      <p className={"bg-blue p-4 rounded"}>{explanation}</p>
    </div>
  );
}

SelectAuxiliary.propTypes = {};

SelectAuxiliary.defaultProps = {
};

export default SelectAuxiliary;
