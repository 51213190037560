import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./PatientAttachmentsUploadBar.module.sass";

function PatientAttachmentsUploadBar(props) {
  const { 
    id,
    closeFunc,
    closeLabel,
    uploadingLabel,
    successLabel,
    status
   } = props;   

  function statusLabel() {
    if (status == "uploading") {
      return uploadingLabel;
    } else if (status == "success") {
      return successLabel;
    }
  };

  return(
    <div className={style.PatientAttachmentUploadBar}>
      <div className={style.uploadLabelContainer}>
        {status == "uploading" &&
          <div className={style.loadingIcon}></div>
        }
        {status == "success" &&
          <div className={style.loadedIcon}>✔</div>
        }
        <div className={style.uploadLabel}>{statusLabel()}</div>
      </div>
      {status == "success" &&
        <div className={style.closeButton}
             onClick={() => closeFunc(id)}>
          {closeLabel}
        </div>
      }
    </div>
  );  
};

PatientAttachmentsUploadBar.propTypes = {
  closeFunc: PropTypes.func,
  closeLabel: PropTypes.string,
  uploadingLabel: PropTypes.string,
  successLabel: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.string
};

export default PatientAttachmentsUploadBar;
