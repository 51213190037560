import React, { useContext, useEffect, useRef } from "react";
import style from "./FilterOverlayFooter.module.sass";
import Button from "../../../../Button/Button";
import { FilterOverlayContext } from "../FilterOverlay";
import { EditPlanHeaderContext } from "../../EditPlanHeader";
import { EditPlanContext } from "../../../EditPlan";
import Lottie from "react-lottie";
import * as animationData from "./assets/loading.json";

function FilterOverlayFooter(props) {
  const { dispatcher, translations, state } = useContext(FilterOverlayContext);
  const { dispatcher: dispatcherEditPlanHeader, hideFilterOverlay } =
    useContext(EditPlanHeaderContext);
  const { state: editPlanState, isLoading } = useContext(EditPlanContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const onClickApplyFilters = () => {
    hideFilterOverlay();
  };

  const clickOnResetAll = () => {
    dispatcher({
      type: "clean_all",
      dispatcherEditPlanHeader,
    });
  };

  return (
    <div className={`${style.FilterOverlayFooter}`}>
      <div
        className={`${style.FilterOverlayFooterResetall}`}
        onClick={clickOnResetAll}
      >
        {translations?.buttons.reset_all}
      </div>
      <div className={`${style.RightSide}`}>
        <div>
          <div
            className={`${style.loading} ${isLoading ? style.loadingShow : ""}`}
          >
            <Lottie options={defaultOptions} height={30} width={30} />
          </div>
          {`${editPlanState?.totFound} ${state.filters[state.currentType]?.name}`}
        </div>
        <Button karyBtn kariBtnGreen onClick={onClickApplyFilters}>
          {translations?.buttons.submit}
        </Button>
      </div>
    </div>
  );
}

FilterOverlayFooter.propTypes = {};

FilterOverlayFooter.defaultProps = {};

export default FilterOverlayFooter;
