import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import style from "./PatientAttachments.module.sass";
import FileElement from "./FileElement";
import PatientAttachmentsMenu from "./PatientAttachmentsMenu/PatientAttachmentsMenu";
import PatientAttachmentsUploadBar from "./PatientAttachmentsUploadBar/PatientAttachmentsUploadBar";
import SorterMenu from "./SorterMenu/SorterMenu";
import { changeDirection, sortByContentType, sortByName, sortByDate } from './SorterAttachmentsHelper'

function PatientAttachments(props) {
  const formNewRef = useRef();
  const formUpdateRef = useRef();
  const [files, setFiles] = useState([]);
  const [contentTypeDirection, setContentTypeDirection] = useState(['asc'])
  const [nameDirection, setNameDirection] = useState(['asc'])
  const [dateDirection, setDateDirection] = useState(['asc'])
  const [deletions, setDeletions] = useState([]);
  const [uploads, setUploads] = useState({});
  const { uploadBarText } = props

  useEffect(() => {
    formNewRef.current.addEventListener("submit", (e) => {
      e.preventDefault();
      submitFormNew();
    });
    fetchFiles();
  }, []);

  const fetchFiles = () => {
    fetch(props.path)
      .then(response => response.json())
      .then(
        (result) => {
          setFiles(result);
        });
  }

  const deleteFileAt = (key) => {
    const currentDeletions = [...deletions];
    currentDeletions.push(key);
    setDeletions([...currentDeletions]);
  };

  const uploadsKeys = () => {
    return Object.keys(uploads).reverse()
  }

  const closeUploadBar = (key) => {
    const currentUploads = { ...uploads };
    delete currentUploads[key];
    setUploads(currentUploads);
  }

  const submitFormNew = () => {
    const currentUploads = { ...uploads };
    const key = Date.now();
    currentUploads[key] = 'uploading';
    setUploads(currentUploads);
    const formData = new FormData(formNewRef.current);
    const fileInput = formNewRef.current.querySelector("input[type=file]");
    formData.append("file", fileInput.files[0]);
    formNewRef.current.reset();
    $.ajax({
      url: props.path,
      type: "POST",
      data: formData,
      success: function (data) {
        currentUploads[key] = 'success';
        setUploads(currentUploads);
        fetchFiles();
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  const submitFormUpdate = (event) => {
    event.preventDefault();
    const formData = new FormData(formUpdateRef.current);
    const nameInput = formUpdateRef.current.querySelector("input[type=text]");
    formData.append("title", nameInput);
    const url = formUpdateRef.current.action
    $.ajax({
      url: url,
      type: "PATCH",
      data: formData,
      success: function () {
        fetchFiles();
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  };

  const setRenaming = (file) => {
    const currentFiles = [...files]
    const index = currentFiles.indexOf(file)
    currentFiles[index].renaming = true
    setFiles(currentFiles)
  };

  const sortBy = (e, filter) => {
    e.preventDefault()
    switch(filter) {
      case 'content_type':
        setContentTypeDirection(changeDirection(contentTypeDirection))
        setFiles(sortByContentType(files, contentTypeDirection))
        break
      case 'name':
        setNameDirection(changeDirection(nameDirection))
        setFiles(sortByName(files, nameDirection))
        break
      case 'date':
        setDateDirection(changeDirection(dateDirection))
        setFiles(sortByDate(files, dateDirection))
        break
    }
  }

  return (
    <div className={`${style.PatientAttachments}`}>
      <form
        className="mb-4"
        encType="multipart/form-data"
        action={props.path}
        acceptCharset="UTF-8"
        method="post"
        ref={formNewRef}
      >
        <label htmlFor="document[document_file]"
          className={`${style.uploadButton}`}>
          {props.submitMessage}
        </label>
        <input
          accept={props.acceptedContentTypes}
          required="required"
          className={`${style.hiddenInput}`}
          type="file"
          name="document[document_file]"
          id="document[document_file]"
          onChange={submitFormNew}
        />
      </form>
      <SorterMenu
        files={files}
        sortBy={sortBy}
        contentTypeDirection={contentTypeDirection}
        nameDirection={nameDirection}
        dateDirection={dateDirection}
        sorterLabel={props.sorterLabel}/>
      <div className={style.uploadsContainer}>
        {uploadsKeys().map((uploadKey) =>
          <PatientAttachmentsUploadBar key={uploadKey}
            id={uploadKey}
            closeFunc={closeUploadBar}
            closeLabel={uploadBarText.closeLabel}
            uploadingLabel={uploadBarText.uploadingLabel}
            successLabel={uploadBarText.successLabel}
            status={uploads[uploadKey]} />
        )}
      </div>
      <div className={`${style.fileList}`}>
        {files.map((file) =>
          <div key={file.id} className={`${style.fileRow} ${deletions.includes(file.id) && style.deleted}`}>
            <FileElement
              fetchFiles={fetchFiles}
              file={file}
              formUpdateRef={formUpdateRef}
              path={props.path}
              submitFormUpdate={submitFormUpdate}
              uploadedAtText={props.uploadedAtText}
            />
            {!deletions.includes(file.id) &&
              <div className={`${style.pushRight}`}>
                <PatientAttachmentsMenu fetchFiles={fetchFiles}
                  file={file}
                  path={props.path}
                  deleteText={props.deleteText}
                  renameText={props.renameText}
                  downloadText={props.downloadText}
                  onDestroy={() => { deleteFileAt(file.id) }}
                  onRename={() => setRenaming(file)}
                />
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
}

PatientAttachments.propTypes = {
  path: PropTypes.string,
  submitMessage: PropTypes.string,
  acceptedContentTypes: PropTypes.string
};

export default PatientAttachments;
