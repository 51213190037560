import React from "react";
import style from "./PatientProfile.module.sass";

function PatientProfile(props) {
  const { 
    isDemo,
    patientTable 
  } = props;

  const table = isDemo ? patientTable.demoTable : patientTable.table

  return (
    <div className={`${style.PatientProfile}`}>
      <div className={style.sectionTitle}>
        <strong>{patientTable.patientDataTitle}</strong>
      </div>
      {table.map((item) => {
        // do not show autogenarated email address
        if (item.key.toLowerCase() === "email" && item.value?.includes("@nomail.no"))
          return null;

        return item.key ? (
          <div className={`${style.property}`} key={item.key}>
            <div className={`${style.propertyTitle} ${item.keepTextCase ? style.KeepTextCase : ''}`}>{item.key}</div>
            <div className={`${style.value}`}>{item.value}</div>
          </div>
        ) : null;
      })}

      {/* NB: wait for data */}
      {/* <div className={style.sectionTitle}>
        <strong>{patientTable.clinicActivityTitle.toUpperCase()}</strong>
      </div>
      <div className={style.sectionTitle}>
        <strong>{patientTable.homeActivityTitle.toUpperCase()}</strong>
      </div> */}
    </div>
  );
}

PatientProfile.propTypes = {};

PatientProfile.defaultProps = {};

export default PatientProfile;
