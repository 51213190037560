import React from "react"
import style from "./PatientForm.module.sass"
import { useState } from 'react'
import IconInfo from "./IconInfo"

function CommonFields(props) {
  const {
    //errors,
    fieldsTranslations,
    fieldLabelStyle,
    gender,
    inputErrorClass,
    patient,
    selectGender,
    showHeartrateFields,
    showSideFields,
    showUserTypologyFields,
    toggleFieldLabel,
    userTypologies,
    sides } = props

  const [selectedPathology, setSelectedPathology] = useState(patient.userTypology)

  const genderClass = (linkGender) => {
    if (linkGender == gender) {
      return `${style.radioButton} ${style.selectedRadio}`
    }
    return style.radioButton
  }

  const showSurgeryDate = () => {
    return selectedPathology == 3 ||
      selectedPathology == 4 ||
      selectedPathology == 5 ||
      selectedPathology == 8
  }

  // TODO: show depending on patient pathology
  const showHeartrateThreshold = () => {
    return (showHeartrateFields)
  }

  const toggleSurgeryDate = () => {
    if (showSurgeryDate()) {
      return (
        <div>
          <span className={`${style.requiredField}`}>*</span>
          <div style={{ display: 'inline', position: 'relative' }}>
            <span className={style.floatPlaceholder}>{fieldsTranslations.patientSurgeryDate}</span>
          </div>
          <input type='date'
            placeholder={fieldsTranslations.patientSurgeryDate}
            className={`${style.fullWidthInput} ${inputErrorClass('surgery_date')}`}
            id='surgery_date'
            name='surgery_date'
            defaultValue={patient.surgeryDate} />
          {/* <div className={style.inputError}>{errors["surgery_date"]}</div> */}
        </div>
      )
    }
    return ''
  }

  const toggleHeartrateThreshold = () => {
    if (showHeartrateThreshold()) {
      return (
        <React.Fragment>
          <div className={style.halfWidthInputWrapper}>
            <div>
              <div id="heartrate_threshold_min-label" style={fieldLabelStyle(patient.heartrate_threshold_min)}>
                <span className={style.floatPlaceholder}>{fieldsTranslations.heartrate_threshold_min}</span>
              </div>
              <input type='number'
                placeholder={fieldsTranslations.heartrate_threshold_min}
                autoComplete="off"
                className={`${style.halfWidthInput} ${inputErrorClass('heartrate_threshold_min')}`}
                defaultValue={patient.heartrate_threshold_min}
                id='heartrate_threshold_min'
                name='heartrate_threshold_min'
                onChange={(e) => toggleFieldLabel(e)} />
              {/* <div className={style.inputError}>{errors["heartrate_threshold_min"]}</div>  */}
            </div>
            <div style={{ width: "4%" }}></div>
            <div>
              <div id="heartrate_threshold_max-label" style={fieldLabelStyle(patient.heartrate_threshold_max)}>
                <span className={style.floatPlaceholder}>{fieldsTranslations.heartrate_threshold_max}</span>
              </div>
              <input type='number'
                placeholder={fieldsTranslations.heartrate_threshold_max}
                autoComplete="off"
                className={`${style.halfWidthInput} ${inputErrorClass('heartrate_threshold_max')}`}
                defaultValue={patient.heartrate_threshold_max}
                id='heartrate_threshold_max'
                name='heartrate_threshold_max'
                onChange={(e) => toggleFieldLabel(e)} />
              {/* <div className={style.inputError}>{errors["heartrate_threshold_max"]}</div>  */}
            </div>
          </div>
          <div className={style.halfWidthInputWrapper}>
            <div>
              <div id="heartrate_threshold_emergency-label" style={fieldLabelStyle(patient.heartrate_threshold_emergency)}>
                <span className={style.floatPlaceholder}>{fieldsTranslations.heartrate_threshold_emergency}</span>
              </div>
              <input type='number'
                placeholder={fieldsTranslations.heartrate_threshold_emergency}
                autoComplete="off"
                className={`${style.halfWidthInput} ${inputErrorClass('heartrate_threshold_emergency')}`}
                defaultValue={patient.heartrate_threshold_emergency}
                id='heartrate_threshold_emergency'
                name='heartrate_threshold_emergency'
                onChange={(e) => toggleFieldLabel(e)} />
              {/* <div className={style.inputError}>{errors["heartrate_threshold_emergency"]}</div>  */}
            </div>
            <div style={{ width: "4%" }}></div>
            <div className={style.halfWidthInputWrapper}>
              <div className={style.ThresholdDescription}>
                <div className={style.IconInfo}><IconInfo /></div>
                <div className={style.TextInfo}>{fieldsTranslations.heartrate_threshold_emergency_desc}</div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
    return ''
  }

  const defaultValueForSelect = (value) => {
    if (value != "" && value != null) {
      return value
    }
    return 'prompt'
  }

  const addInvalidClass = (e, field) => {
    e.preventDefault()
    document.getElementById(field).classList.add(style.invalidInput)
  }

  return (
    <span>
      <div style={{ marginBottom: '15px' }}>
        <span className={`${style.requiredField}`}>*</span>
        <span className={`${style.radioWrapper} ${inputErrorClass('gender')}`}>
          <a href='javascript:void(0)'
            onClick={(e) => selectGender(e, 'male')}
            className={genderClass('male')}>
            {fieldsTranslations.male}
          </a>
          <a href='javascript:void(0)'
            onClick={(e) => selectGender(e, 'female')}
            className={genderClass('female')}>
            {fieldsTranslations.female}
          </a>
        </span>
      </div>
      {/* <div className={style.inputError}>{errors["gender"]}</div> */}
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <div id="lastname-label" style={fieldLabelStyle(patient.lastname)}>
          <span className={style.floatPlaceholder}>{fieldsTranslations.lastname}</span>
        </div>
        <input type='text'
          placeholder={fieldsTranslations.lastname}
          autoComplete="off"
          className={`${style.fullWidthInput} ${inputErrorClass('lastname')}`}
          defaultValue={patient.lastname}
          id='lastname'
          required
          onInvalid={(e) => addInvalidClass(e, 'lastname')}
          onChange={(e) => toggleFieldLabel(e)}
          name='lastname' />
        {/* <div className={style.inputError}>{errors["lastname"]}</div> */}
      </div>
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <div id="name-label" style={fieldLabelStyle(patient.name)}>
          <span className={style.floatPlaceholder}>{fieldsTranslations.name}</span>
        </div>
        <input type='text'
          placeholder={fieldsTranslations.name}
          autoComplete="off"
          className={`${style.fullWidthInput} ${inputErrorClass('name')}`}
          defaultValue={patient.name}
          id='name'
          required
          onInvalid={(e) => addInvalidClass(e, 'name')}
          onChange={(e) => toggleFieldLabel(e)}
          name='name' />
        {/* <div className={style.inputError}>{errors["name"]}</div> */}
      </div>
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <div style={{ display: 'inline', position: 'relative' }}>
          <span className={style.floatPlaceholder}>{fieldsTranslations.birthDate}</span>
        </div>
        <input type='date'
          className={`${style.fullWidthInput} ${inputErrorClass('birth_date')}`}
          defaultValue={patient.birthDate || patient.birthDateDefault}
          id='birthdate'
          onInvalid={(e) => addInvalidClass(e, 'birthdate')}
          onChange={(e) => toggleFieldLabel(e)}
          name='birth_date' 
          min='1920-01-01'
          max={new Date().toISOString().split('T')[0]} />
        {/* <div className={style.inputError}>{errors["birthdate"]}</div> */}
      </div>
      <div className={style.halfWidthInputWrapper}>
        <div>
          <span className={`${style.requiredField}`}>*</span>
          <div id="height-label" style={fieldLabelStyle(patient.height)}>
            <span className={style.floatPlaceholder}>{fieldsTranslations.height}</span>
          </div>
          <input type='number'
            placeholder={fieldsTranslations.height}
            autoComplete="off"
            className={`${style.halfWidthInput} ${inputErrorClass('height')}`}
            defaultValue={patient.height}
            onChange={(e) => toggleFieldLabel(e)}
            id='height'
            name='height' />
          {/* <div className={style.inputError}>{errors["height"]}</div>  */}
        </div>
        <div style={{ width: "4%" }}></div>
        <div>
          <div id="weight-label" style={fieldLabelStyle(patient.weight)}>
            <span className={style.floatPlaceholder}>{fieldsTranslations.weight}</span>
          </div>
          <input type='number'
            placeholder={fieldsTranslations.weight}
            autoComplete="off"
            className={`${style.halfWidthInput} ${inputErrorClass('weight')}`}
            defaultValue={patient.weight}
            onChange={(e) => toggleFieldLabel(e)}
            id='weight'
            name='weight' />
          {/* <div className={style.inputError}>{errors["weight"]}</div>  */}
        </div>
      </div>
      {showUserTypologyFields &&
        <div>
          <span className={`${style.requiredField}`}>*</span>
          <div id="user_typology_id-label" style={fieldLabelStyle(patient.userTypology)}>
            <span className={style.floatPlaceholder}>{fieldsTranslations.pathology}</span>
          </div>
          <select className={`select kari-select ${style.fullWidthInput} ${inputErrorClass('user_typology')}`}
            id='user_typology_id'
            name='user_typology_id'
            style={{ marginBottom: "12px" }}
            onChange={(e) => { setSelectedPathology(e.target.value); toggleFieldLabel(e); }}
            defaultValue={defaultValueForSelect(patient.userTypology)}>
            <option value="prompt" disabled hidden>{fieldsTranslations.pathology}</option>
            {userTypologies.map(userTypology => {
              return (
                <option key={userTypology.id} value={userTypology.id}>{userTypology.code}</option>
              )
            })}
          </select>
          <i className={`fa-solid fa-angle-down ${style.dropDownIcon}`}></i>
          {/* <div className={style.inputError}>{errors["user_typology"]}</div> */}
        </div>
      }
      {toggleSurgeryDate()}
      {toggleHeartrateThreshold()}
      {showSideFields &&
        <div>
          <span className={`${style.requiredField}`}>*</span>
          <div id="side-label" style={fieldLabelStyle(patient.side)}>
            <span className={style.floatPlaceholder}>{fieldsTranslations.side}</span>
          </div>
          <select className={`select kari-select ${style.fullWidthInput} ${inputErrorClass('side')}`}
            id='side'
            name='side'
            style={{ marginBottom: "12px" }}
            defaultValue={defaultValueForSelect(patient.side)}
            onChange={(e) => toggleFieldLabel(e)}
          >
            <option value="prompt" disabled hidden>{fieldsTranslations.side}</option>
            <option value='none'>{sides.none}</option>
            <option value='right'>{sides.right}</option>
            <option value='left'>{sides.left}</option>
          </select>
          <i className={`fa-solid fa-angle-down ${style.dropDownIcon}`}></i>
          {/* <div className={style.inputError}>{errors["side"]}</div>     */}
        </div>
      }
      <div>
        <div id="notes-label" style={fieldLabelStyle(patient.notes)}>
          <span className={style.floatPlaceholder} style={{ top: "-130px" }}>{fieldsTranslations.notes}</span>
        </div>
        <textarea className={`${style.fullWidthInput}`}
                  placeholder={fieldsTranslations.notes}
                  defaultValue={patient.notes}
                  onChange={(e) => toggleFieldLabel(e)}
                  id='notes'
                  name='notes'
                  maxLength="160"
                  style={{ resize: "none" }} />
        {/* <div className={style.inputError}>{errors["notes"]}</div>     */}
      </div>
    </span>
  )
}

export default CommonFields
