import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./AuxiliarityVideoPage.module.sass";
import AuxiliarityVideoInputName from "./AuxiliarityVideoInputName/AuxiliarityVideoInputName";
import AuxiliarityMessageInput from "./AuxiliarityMessageInput/AuxiliarityMessageInput";
import SelectAuxiliary from "./SelectAuxiliary/SelectAuxiliary";
import { callApi } from "../../helpers";
import Button from "../Button/Button";
import TimingBlock from "../EditPlan/EditPlanSummaryBlock/TimingBlock/TimingBlock";

function AuxiliarityVideoPage(props) {
  const {
    showHomeCustomizationLabel,
    name,
    nameInputName,
    showAlert,
    showTime,
    urlName,
    urlVideo,
    nextUrl,
    prevUrl,
    translations,
    showWarmupCooldownVideoSelectors,
    showMessagesBeforeAfter,
    messageBefore,
    messageAfter,
  } = props;
  const [warmupVal, setWarmupVal] = useState(props.warmup);
  const [cooldownVal, setCooldownVal] = useState(props.cooldown);
  const [duration, setDuration] = useState({
    value: props.duration,
    text: props.durationText,
  });
  const formRef = useRef();

  useEffect(() => {
    callApi(
      urlVideo,
      "PATCH",
      "json",
      (data) => {
        setWarmupVal({ ...warmupVal, explanation: data.warmup_explain });
        setCooldownVal({ ...cooldownVal, explanation: data.cooldown_explain });
        setDuration({
          value: data.duration,
          text: data.durationText,
          warning: data.warning_message
        });
      },
      null,
      null,
      {
        body: JSON.stringify({
          warmup_id: warmupVal.selected + "",
          cooldown_id: cooldownVal.selected + "",
        }),
      }
    );
  }, [warmupVal.selected, cooldownVal.selected]);

  const renderHeader = () => {
    return (
      <div className={`row mb-4 py-3 ${style.header}`}>
      <div className={`col-2`}>
        <Button kariBtnWhite href={prevUrl}>
          {translations.backBtn}
        </Button>
      </div>
    </div>
    );
  };

  const onChangeWarmup = (newVal) => {
    setWarmupVal({ ...warmupVal, selected: newVal });
  };

  const onChangeCooldown = (newVal) => {
    setCooldownVal({ ...cooldownVal, selected: newVal });
  };

  const onClickNext = () => {
    formRef.current.submit();
  };

  return (
    <div className={`${style.AuxiliarityVideos}`}>
      <div className={style.title}>{translations.title}</div>
      {renderHeader()}
      <form method="POST" action={nextUrl} ref={formRef}>
        <input type="hidden" name="_method" value="put" />
        <div className={`${style.container} container text-white`}>
          <div className={`mb-5`}>
            <div className={`mb-1`}>{translations.labelName}</div>
            <AuxiliarityVideoInputName
              placeholder={translations.placeholder}
              value={name}
              name={nameInputName}
              checkUrl={urlName}
            />
          </div>
          <div className={showHomeCustomizationLabel && style.eHomeCustomization}>
            {showHomeCustomizationLabel && 
              <div className="mb-4">
                {translations.homeCustomization.first}
                <span style={{color: "#48E0B9", fontWeight: "700"}}>{translations.homeCustomization.home}</span>
                {translations.homeCustomization.second}
              </div>
            }
            {
              showMessagesBeforeAfter &&
              <div className={`mb-4`}>
                <AuxiliarityMessageInput
                  message={messageBefore}
                  placeholder={translations.messageBefore.placeholder}
                  name={"message_before"}
                  labelText={translations.messageBefore.labelText}
                />
              </div>
            }
            {
              showMessagesBeforeAfter &&
              <div className={`mb-4`}>
                <AuxiliarityMessageInput
                  message={messageAfter}
                  placeholder={translations.messageAfter.placeholder}
                  name={"message_after"}
                  labelText={translations.messageAfter.labelText}
                />
              </div>
            }
            {
              showWarmupCooldownVideoSelectors &&
              <div className={`mb-2`}>
                <SelectAuxiliary
                  onChange={onChangeWarmup}
                  {...warmupVal}
                  ifYouWantAddText={translations.ifYouWantAdd}
                  keywordText={translations.warmupType}
                  selectHereText={translations.selectHere}
                  tooltipText={translations.warmupTooltip}
                />
              </div>
            }
            {
              showWarmupCooldownVideoSelectors &&
              <div className={`mb-5`}>
                <SelectAuxiliary
                  onChange={onChangeCooldown}
                  {...cooldownVal}
                  ifYouWantAddText={translations.ifYouWantAdd}
                  keywordText={translations.cooldownType}
                  selectHereText={translations.selectHere}
                  tooltipText={translations.cooldownTooltip}
                />
              </div>
            }
          </div>
          { duration?.value &&
            <div className={`mb-5`}>
              <TimingBlock
                message={duration.warning}
                duration={duration.value}
                durationText={duration.text}
                showAlert={showAlert}
                showTime={showTime}
              />
            </div>
          }
          <div className={"text-center mb-5 d-flex justify-content-around"}>
            <Button karyBtn kariBtnGreen onClick={onClickNext}>
              {translations.nextBtn}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

AuxiliarityVideoPage.propTypes = { showWarmupCooldownVideoSelectors: true };

AuxiliarityVideoPage.defaultProps = {
  cooldown: {},
  nameInputName: 'code',
  warmup: {}
};

export default AuxiliarityVideoPage;
