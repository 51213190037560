import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./EndCallButton.module.sass";
import closeCall from "../../../../../assets/images/close-call.svg"

function EndCallButton(props){
  const {onClick} = props;

  return (
  <div className={`${style.EndCallButton}`} onClick={onClick}>
    <img src={closeCall}/>
  </div>
  );
}

EndCallButton.propTypes = {
  onClick: PropTypes.func
};

EndCallButton.defaultProps = {
};

export default EndCallButton