import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./CallNotes.module.sass";
import SingleNote from "../SingleNote/SingleNote";
import NewNote from "../NewNote/NewNote";
import { callApi } from "../../../helpers.js";

function CallNotes(props) {
  const { newNote, callId, notesPath } = props;
  const [notes, setNotes] = useState([
    { id: "1", date: "10:30", message: "nota nuova" },
  ]);

  useEffect(() => {
    updateNotes();
  }, []);

  const updateNotes = () => {
    callApi(notesPath, "GET", "json", (data) => {
      setNotes(data);
    });
  };

  return (
    <div className={`${style.CallNotes}`}>
      <div className={style.NoteList} id={"node-list"}>
        {notes.map((note) => {
          return <SingleNote {...note} key={"CallNotes" + note.id} />;
        })}
      </div>
      <NewNote {...newNote} callId={callId} updateNotes={updateNotes} />
    </div>
  );
}

CallNotes.propTypes = {};

CallNotes.defaultProps = {};

export default CallNotes;
