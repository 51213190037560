import React, { useState } from "react";
import AllRepetitionsCharts from "./AllRepetitionsCharts/AllRepetitionsCharts";
import CompareRepetitionsCharts from "./CompareRepetitionsCharts/CompareRepetitionsCharts";
import style from "./StrengthResultRepetitionsCharts.module.sass"

function StrengthResultRepetitionsCharts(props) {
  const {
    avgLabel,
    filter,
    hasSide,
    left,
    leftChartId,
    leftData,
    measure,
    rightChartId,
    right,
    rightData,
    repetitionChartId,
    sdLabel,
    tabs,
    trend
  } = props

  const [selectedTab, setSelectedTab] = useState("all")

  const activeTab = (tab) => {
    if (tab == selectedTab) {
      return <div className={style.ActiveTab}></div>
    }
  }

  const renderTab = (selectionLabel, tabLabel) => {
    return (
      <div className={`${style.tab} ${selectedTab == selectionLabel && style.selected}`}
           onClick={() => setSelectedTab(selectionLabel)}>
        {tabLabel}
        {activeTab(selectionLabel)}
      </div>
    )
  }

  return (
    <div className={style.repetitionsChartsContainer}>
      <div className={style.tabSwitch}>
        {renderTab("all", tabs.all)}
        <div className={style.separator}></div>
        {renderTab("compare", tabs.compare)}
      </div>

      {selectedTab == "all" && 
        <AllRepetitionsCharts
          avgLabel={avgLabel}
          hasSide={hasSide}
          leftChartId={leftChartId}
          leftData={leftData}
          measure={measure}
          rightChartId={rightChartId}
          rightData={rightData}
          sdLabel={sdLabel}
          trend={trend}
        />
      }

      {selectedTab == "compare" &&
        <CompareRepetitionsCharts
          hasSide={hasSide}
          left={left}
          leftData={leftData}
          right={right}
          rightData={rightData}
          filter={filter}
          repetitionChartId={repetitionChartId}
          measure={measure}
        />
      }
    </div>
  )
}

export default StrengthResultRepetitionsCharts