import React from "react";
import style from "./ExerciseItem.module.sass";
import SequenceTypology from "../../Sequence/SequenceTypology";
import ToolLabel from "./ToolLabel/ToolLabel";
import Button from "../../Button/Button";
function ExerciseItem(props) {
  const {
    biofeedbackLabel,
    buttons,
    clickOnAdd,
    code,
    showBiofeedbackLabel,
    id,
    labelKey,
    title,
    tool,
    videoSrc,
    videoThumbSrc
  } = props;
  const labelData = props[labelKey]

  return (
    <div
      className={`${style.ExerciseItem}`}
      data-id={id}
      data-video-src={videoSrc}
    >
      <div className={`${style.mainContainer}`}>
        <div style={{position: "relative"}}>
          <img src={videoThumbSrc} className={`${style.media}`} />
          {showBiofeedbackLabel &&
            <div className={style.biofeedbackLabel}>{biofeedbackLabel}</div>
          }
        </div>
        <div className={`${style.nameContainer}`}>
          <div className={`${style.name}`}>
            <div>{title}</div>
            {/* FIXME: Improve this elegant crap */}
            {(labelData.code == 'TYPE_MEDICAIR') && <ToolLabel code={tool.code} title={tool.title} /> ||
             !code.startsWith('ML') && <SequenceTypology code={labelData.code} name={labelData.title} />}
          </div>
        </div>

        <div className={`${style.buttonTo}`}>
          <Button
            kariBtn
            kariBtnGreenOver
            kariBtnArrowRight
            onClick={() => clickOnAdd(id)}
          >
            {buttons.add}
          </Button>
        </div>
      </div>
    </div>
  );
}

ExerciseItem.propTypes = {};

ExerciseItem.defaultProps = {
  code: "TYPE_BAL",
  showBiofeedbackLabel: false
};

export default ExerciseItem;
