// Sembra che questo file non venga mai usato... controllare..

import React, { useState, useEffect } from "react";
import styles from "./SequenceSelector.module.sass";
import plus from "./assets/plus.svg";
import minus from "./assets/minus.svg";
import { callApi } from "../../helpers";
const SequenceSelector = (props) => {
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    sendDataToServer();
  }, [value]);

  const minValue = 1;
  const maxValue = 4;

  const cssClassForMinus = () => {
    if (value <= minValue) return styles.opacity25;
  };

  const cssClassForPlus = () => {
    if (value >= maxValue) return styles.opacity25;
  };

  const increment = () => {
    if (value < maxValue) setValue((value) => value + 1);
  };
  const decrement = () => {
    if (value > minValue) setValue((value) => value - 1);
  };

  const onSendDataSuccess = (data) => {
    // update single row
    $(".time.choosed-exercise-time").html(data.text);
    SequenceTimeDanger.run(data.duration);
  };

  const sendDataToServer = () => {
    let url = `${props.url}?sets=${value}`;
    callApi(url, "PATCH", "json", onSendDataSuccess);
  };

  return (
    <div className="py-3 text-center">
      <span
        onClick={decrement}
        className={`me-2 ${styles.circle} ${cssClassForMinus()}`}
      >
        <img src={minus} />
      </span>
      <span className={styles.value}>{value}</span>
      <span
        onClick={increment}
        className={`ms-2 ${styles.circle} ${cssClassForPlus()}`}
      >
        <img src={plus} />
      </span>
    </div>
  );
};

export { SequenceSelector as default };
