import React, { useEffect, useRef } from "react";
import style from "./ExerciseSequenceChart.module.sass";

import { generateEffortData, configEffort } from "./utilsMedicair";
function ExerciseSequenceChartEffort(props) {
  let { title, effortData } = props;

  const chart = useRef(null);
  const canvasContainerRef = useRef();
  const data = generateEffortData(effortData);

  useEffect(() => {
    let canvas;
    if (canvasContainerRef.current) {
      canvas = canvasContainerRef.current.querySelector("canvas");
      if (canvas) {
        canvas.remove();
      }
      canvas = document.createElement("canvas");
      // canvas.dataset.side = side;
      canvasContainerRef.current.appendChild(canvas);
      chart.current = new Chart(canvas, configEffort(data));
    }
  }, [props]);

  return (
    <div className={`${style.exerciseSequenceChartMedicair}`}>
      <div className={`${style.exerciseSequenceChartTitle}`}>{title}</div>
      <div className={`${style.chartContainer}`} ref={canvasContainerRef}></div>
    </div>
  );
}

export default ExerciseSequenceChartEffort;
