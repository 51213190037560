import React, { useContext } from "react";
import PropTypes from "prop-types";
import style from "./Filter.module.sass";
import { FilterOverlayContext } from "../../FilterOverlay";
import { EditPlanHeaderContext } from "../../../EditPlanHeader";

function Filter(props) {
  const { filter, id } = props;
  const { dispatcher: dispatcherFilterOverlay, state: stateFilterOverlay } =
    useContext(FilterOverlayContext);
  const { dispatcher: dispatcherEditPlanHeader, state: stateEditPlanHeader } =
    useContext(EditPlanHeaderContext);
  let rootClasses = `${style.Filter}`;
  if (!filter.enabled) {
    rootClasses += ` ${style.FilterDisabled}`;
  }

  const clickOnCheckbox = (e) => {
    const target = e.currentTarget.parentNode;
    triggerClick(target);
  };

  const triggerClick = (target) => {
    const lastUnderscoreIndex = id.lastIndexOf("_");
    const categoryId = id.substring(0, lastUnderscoreIndex);
    const filterId = id.substring(lastUnderscoreIndex + 1);
    const checked = !target.querySelector("input").checked;
    let firstSelectedCategory = stateFilterOverlay.firstSelectedCategory;

    if (categoryId == "body_parts" || categoryId == "movements") {
      const totBodyPartsFiltered = stateFilterOverlay.currentFilters["body_parts"].length;
      const totMovementsFiltered = stateFilterOverlay.currentFilters["movements"].length;
      const totBodyPartsAndMovementsFiltered =
        totBodyPartsFiltered + totMovementsFiltered;
      if (!checked) {
        if (totBodyPartsAndMovementsFiltered == 0) {
          firstSelectedCategory = categoryId;
        }
      } else {
        if (totBodyPartsAndMovementsFiltered == 1) {
          firstSelectedCategory = -1;
        }
      }
    }
    dispatcherFilterOverlay({
      type: checked ? "remove_filter" : "add_filter",
      categoryId,
      filterId,
      firstSelectedCategory: firstSelectedCategory,
      dispatcherEditPlanHeader,
    });
  };

  return (
    <div className={`${rootClasses} position-relative`}>
      <input
        type={"checkbox"}
        id={id}
        checked={filter.checked}
        onChange={clickOnCheckbox}
      />
      <label className="position-relative" htmlFor={id}>{filter.title}</label>
    </div>
  );
}

Filter.propTypes = {
  filter: PropTypes.object,
};

Filter.defaultProps = {};

export default Filter;
