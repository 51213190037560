import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./ChatDiscussion.module.sass";
import NewChatMessage from "../NewChatMessage/NewChatMessage";
import VideoCameraSVG from "./assets/videocamera.svg";
import ChatMessage from "./ChatMessage/ChatMessage";
import { ChatContext } from "../Chat";

function ChatDiscussion(props) {
  const {
    id,
    showOtherUser,
    otherUserLink,
    otherUserName,
    callUrl,
    callText,
    messages,
    other,
  } = props;
  const callBtnRef = useRef();
  const chatListRef = useRef();
  const [newMessages, setNewMessages] = useState([]);
  const value = useContext(ChatContext);

  useEffect(() => {
    $(callBtnRef.current).tooltip();
  }, []);

  useEffect(() => {
    setNewMessages([]);
    value.forceChatListUpdate();
  }, [messages.length]);

  useEffect(() => {
    const scrollHeight = chatListRef.current.scrollHeight;
    chatListRef.current.scrollTop = scrollHeight;
  });

  const onClickCall = () => {
    window.open(callUrl, "videocall", "width=1000,height=700");
    window.onmessage = function (e) {
      if (e.data) {
        // FIXME: Commented because it executes right after the call window is opened.
        // window.location.replace(e.data.toString());
      }
    };
  };

  const onSendMessage = (newMessage) => {
    const upateNewMessages = [...newMessages];
    upateNewMessages.push(newMessage);
    setNewMessages(upateNewMessages);
    value.forceChatListUpdate();
  };

  const remoteArrayDuplicate = (arr) => {
    // Code from here: https://stackoverflow.com/a/53543030
    const keys = ["id"];
    const filtered = arr.filter(
      (
        (s) => (o) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
      )(new Set())
    );
    return filtered;
  };

  const allMessages = remoteArrayDuplicate([...messages, ...newMessages]);
  return (
    <div className={`${style.ChatDiscussion}`} id={`chat-show-${id}`}>
      <div className={`${style.chatTitle} d-flex`}>
        {showOtherUser && (
          <a href={otherUserLink} className={`${style.chatName}`}>
            {otherUserName}
          </a>
        )}
        <div className={`${style.chatActions}`}>
          <div
            className={`pointer auto-tooltip`}
            onClick={onClickCall}
            ref={callBtnRef}
            title={callText}
          >
            <img src={VideoCameraSVG} alt={""} />
          </div>
        </div>
      </div>
      <div
        className={`${style.chatHistory} ${style.chatMessagesList}`}
        id={`chat-show-${id}`}
        ref={chatListRef}
      >
        {allMessages.map((message) => {
          return (
            <ChatMessage {...message} key={message.id} otherUser={other} />
          );
        })}
      </div>
      <div>
        <NewChatMessage id={id} onSendMessage={onSendMessage} />
      </div>
    </div>
  );
}

ChatDiscussion.propTypes = {};

ChatDiscussion.defaultProps = { messages: [] };

export default ChatDiscussion;
