import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./FilterOverlayHeader.module.sass";
import { FilterOverlayContext } from "../FilterOverlay";
import { EditPlanHeaderContext } from "../../EditPlanHeader";

function FilterOverlayHeader(props) {
  const {} = props;
  const { state, dispatcher, translations } = useContext(FilterOverlayContext);
  const { dispatcher: dispatcherEditPlanHeader } = useContext(
    EditPlanHeaderContext
  );

  const onClickRadio = (e) => {
    const target = e.currentTarget;
    dispatcher({
      type: "change_current_type",
      value: target.value,
    });
    dispatcherEditPlanHeader({
      type: "remove_all",
    });
  };

  const renderChoices = () => {
    const choices = [];
    Object.keys(state.filters).forEach(function (key) {
      const obj = state.filters[key];
      choices.push(obj);
    });
    return choices.map((choice) => {
      return (
        <div
          className={`${style.FilterOverlayHeaderChoice}`}
          key={"choice_" + choice.type}
        >
          <div>
            <input
              type="radio"
              // onChange={onClickRadio}
              id={choice.type}
              name={"type"}
              value={choice.type}
              // checked={choice.type == state.currentType}
              checked={true}
            />
          </div>
          <label htmlFor={choice.type}>
            {choice.title}
            <div className={`${style.FilterOverlayHeaderSubtitle}`}>
              {choice.subtitle}
            </div>
          </label>
        </div>
      );
    });
  };

  return (
    <div className={`${style.FilterOverlayHeader}`}>
      <div className={`${style.FilterOverlayHeaderTitle}`}>{translations?.title}</div>
      <div className={`${style.FilterOverlayChoices}`}>{renderChoices()}</div>
    </div>
  );
}

FilterOverlayHeader.propTypes = {};

FilterOverlayHeader.defaultProps = {};

export default FilterOverlayHeader;
