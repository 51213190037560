import React from "react"
import style from "./UpperLimbGeneralResult.module.sass"
import UpperResultNotes from "../UpperLimbResult/UpperResultNotes/UpperResultNotes"

function UpperLimbGeneralResult(props) {
  const {
    id,
    injured_side,
    notes

  } = props

  return (
    <div>
        <div className={style.strengthRepetitionAndTime}>
          <div className={style.info}>
          <div className={style.label}>{injured_side.label}: </div>
            <div className={style.value} >{injured_side.value}</div>

          </div>
        </div>
      


      
    </div>
  )
}

export default UpperLimbGeneralResult