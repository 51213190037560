import React from "react";
import ReactDOM from "react-dom";

export default class ReactComponentsHandler {
  constructor() {
    this.componentNames = {};
    this.context = null;
    this.loadComponentsName();
  }

  camelize(str) {
    return (
      str.charAt(0).toUpperCase() +
      str.slice(1).replace(/-./g, (x) => x.toUpperCase()[1])
    );
  }

  getClassNameFromFile(fileName) {
    let newFilename = fileName.split("./")[1].split(".js")[0];
    newFilename = newFilename.substring(newFilename.lastIndexOf("/") + 1);
    return newFilename;
  }

  loadComponentsName(maxFolderDepth = -1) {
    this.context = require.context("./components/", true, /\.(js)$/);
    this.context.keys().forEach((filename) => {
      const folderDepth = (filename.match(/\//g) || []).length;
      const newFilename = this.getClassNameFromFile(filename);
      if (folderDepth == maxFolderDepth || maxFolderDepth == -1) {
        if (this.componentNames[newFilename]) {
          console.warn("Duplicate component name for " + newFilename);
        } else {
          this.componentNames[newFilename] = filename;
        }
      }
    });
  }

  insertReactComponents(query = "[data-react-class]") {
    const reactClassesElements = document.querySelectorAll(query);
    reactClassesElements.forEach((el) => {
      let elReactData = el.dataset.props ? JSON.parse(el.dataset.props) : {};
      // aggiungi eventuali children.
      if (el.innerHTML)
        elReactData.children = el.innerHTML

      const componentName = this.camelize(el.dataset.reactClass);
      const MyComponent = this.context(
        this.componentNames[componentName]
      ).default;
      const reactElement = React.createElement(MyComponent, elReactData);
      ReactDOM.render(reactElement, el);
      delete el.dataset.props;
      delete el.dataset.reactClass;
    });
  }
}
