import React from "react";

const Generic = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.6667 5H8.33333C6.48333 5 5 6.48333 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5Z" fill="#4541FF" />
      <path d="M20.8 17.6V13.2L25.2 17.6M15.2 12C14.312 12 13.6 12.712 13.6 13.6V26.4C13.6 26.8243 13.7686 27.2313 14.0686 27.5314C14.3687 27.8314 14.7757 28 15.2 28H24.8C25.2244 28 25.6313 27.8314 25.9314 27.5314C26.2314 27.2313 26.4 26.8243 26.4 26.4V16.8L21.6 12H15.2Z" fill="white" />
    </svg>
  );
};

export default Generic;
