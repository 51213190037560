import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ExerciseVideo.module.sass";
import VideoTooltip from "../../VideoTooltip/VideoTooltip";
function ExerciseVideo(props) {
  const { url, reflectX, videoUrl } = props;
  let classesNames = styles.exerciseVideo;
  if (reflectX) {
    classesNames += " " + styles.exerciseVideoReflect;
  }

  return (
    <div className={`${classesNames}`}>
      <VideoTooltip videoUrl={videoUrl} reflectX={reflectX} position={"left"}>
        <img className={styles["exercise-button-video"]} src={url} />
      </VideoTooltip>
    </div>
  );
}

ExerciseVideo.propTypes = {
  url: PropTypes.string,
  reflectX: PropTypes.bool,
  videoUrl: PropTypes.string,
};

ExerciseVideo.defaultProps = {
  url: "",
  reflectX: false,
  videoUrl: "",
};

export default ExerciseVideo;
