import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./ChatListSearch.module.sass";
import SearchIcon from "./assets/icon_search_white";
import { debounce, triggerTextChange } from "../../../../helpers";
import { ChatContext } from "../../Chat";

function ChatListSearch(props) {
  const { onChangeFilter } = props;
  const [search, setSearch] = useState("");
  const { translations } = useContext(ChatContext);

  const handleSearchChange = debounce((e) => {
    triggerTextChange(
      e,
      (text) => {
        setSearch(text);
        onChangeFilter(text);
      },
      search
    );
  }, 200);

  const userIsSearching = (e) => {
    handleSearchChange(e);
  };

  return (
    <div className={`${style.ChatListSearch} d-flex py-4 pe-3 ps-5`}>
      <img src={SearchIcon} alt="" />
      <input
        type="search"
        placeholder={translations.searchPlaceholderText}
        onChange={userIsSearching}
      />
    </div>
  );
}

ChatListSearch.propTypes = {};

ChatListSearch.defaultProps = {};

export default ChatListSearch;
