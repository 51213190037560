import React from "react"
import { useEffect, useState } from 'react'
import style from "./PatientForm.module.sass"
import CommonFields from "./CommonFields"
import MedicairFields from "./MedicairFields"
import Spinner from "./Spinner"

function PatientForm(props) {
  const {
    action,
    backPath,
    grouptags,
    licenses,
    patient,
    showGrouptagsFields,
    showOxygenFields,
    showHeartrateFields,
    showSideFields,
    showUserTypologyFields,
    submitUrl,
    translations,
    userTypologies,
    sides } = props
  const [buttonClicked, setButtonClicked] = useState('')
  const [errors, setErrors] = useState({})
  const fieldsTranslations = translations.fields
  const [gender, setGender] = useState('male')
  const [goToPathway, setGoToPathway] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState([])
  const [unselectedGroups, setUnselectedGroups] = useState([])
  const [useOxygen, setUseOxygen] = useState(false)

  useEffect(() => {
    let newUnselectedGroups = sortGroups([...grouptags])
    if (patient.grouptags != undefined) {
      newUnselectedGroups = sortGroups([...selectedGroupDifference()])
      let newSelectedGroups = sortGroups([...patient.grouptags])
      setSelectedGroups(newSelectedGroups)
    }
    setUnselectedGroups(newUnselectedGroups)
  }, []);

  useEffect(() => {
    if (patient.useOxygen != undefined) {
      setUseOxygen(patient.useOxygen)
    }
  }, [])

  useEffect(() => {
    if (patient.gender != undefined) {
      setGender(patient.gender)
    }
  }, []);

  const selectedGroupDifference = () => {
    return grouptags.filter(grouptag => {
      return !patient.grouptags.some(patientGrouptag => {
        return grouptag.id === patientGrouptag.id
      })
    })
  }

  const sortGroups = (groups) => {
    return groups.sort((groupA, groupB) => {
      if (groupA.tag < groupB.tag) { return -1; }
      if (groupA.tag > groupB.tag) { return 1; }
      return 0;
    });
  }

  const selectGender = (e, selectedGender) => {
    e.preventDefault()
    setGender(selectedGender)
  }

  const selectUseOxygen = (e, selectedUseOxygen) => {
    e.preventDefault()
    setUseOxygen(selectedUseOxygen)
  }

  const handleSelectedGroup = group => {
    let newSelectedGroups = [...selectedGroups]
    newSelectedGroups.push(group)
    setSelectedGroups(sortGroups(newSelectedGroups))
    let newUnselectedGroups = unselectedGroups.filter(selectedGroup => selectedGroup.tag != group.tag)
    setUnselectedGroups(newUnselectedGroups)
  }

  const handleRemoveGroup = (group) => {
    if (grouptags.filter(grouptag => grouptag.tag === group.tag).length > 0) {
      let newUnselectedGroups = [...unselectedGroups]
      newUnselectedGroups.push(group)
      setUnselectedGroups(sortGroups(newUnselectedGroups))
    }
    let newSelectedGroups = selectedGroups.filter(selectedGroup => selectedGroup.tag != group.tag)
    setSelectedGroups(newSelectedGroups)
  }

  const handleNewGroup = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      let tagName = event.target.value
      if (tagName.length > 0) {
        let newSelectedGroups = [...selectedGroups]
        let newSelectedGroup = { tag: event.target.value }
        newSelectedGroups.push(newSelectedGroup)
        setSelectedGroups(sortGroups(newSelectedGroups))
        event.target.value = ""
      }
    }
  }

  const goBack = () => {
    setButtonClicked('back')
    window.location.href = backPath
  }

  const styleForGroup = (group) => {
    if (selectedGroups.filter(e => e.tag === group.tag).length > 0) {
      return { display: 'none' }
    }
    return { marginRight: '10px' }
  }

  const buttonClass = () => {
    if (Object.keys(errors).length) {
      return `${style.buttonSubmit} ${style.buttonSubmitWithErrors}`
    }
    return style.buttonSubmit
  }

  const submitForm = async (e) => {
    e.preventDefault()
    if (goToPathway) {
      setButtonClicked('pathway')
    } else {
      setButtonClicked('submit')
    }
    const formData = new FormData()
    const params = Array.from(e.target)
      .filter(el => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: b.value }), {})
    params.gender = gender
    if (showOxygenFields) {
      params.use_oxygen = useOxygen
    }
    formData.patient = params
    formData.grouptags = selectedGroups
    formData.go_to_pathway = goToPathway
    let response = await fetch(submitUrl, {
      method: action,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    const data = await response.json()

    if (response.ok) {
      window.location.href = data.location
    } else {
      setErrors({ ...data.errors })
    }
    setButtonClicked('')
  }

  const buttonSend = () => {
    if (buttonClicked == 'submit') {
      return <a className={buttonClass()}
        disabled>
        <Spinner />
      </a>
    } else if (buttonClicked != '') {
      return <input className={buttonClass()}
        type='submit'
        value={translations.button}
        disabled
        onClick={() => setGoToPathway(false)} />
    } else {
      return <input className={buttonClass()}
        type='submit'
        value={translations.button}
        onClick={() => setGoToPathway(false)} />
    }
  }

  const buttonPathway = () => {
    if (buttonClicked == 'pathway') {
      return <a className={style.goToPathway}
        disabled>
        <Spinner />
      </a>
    } else if (buttonClicked != '') {
      return <button type='submit'
        className={style.goToPathway}
        disabled
        onClick={() => setGoToPathway(true)}>
        <span className={style.buttonText}>{translations.goToPathway}</span>
      </button>
    } else {
      return <button type='submit'
        className={style.goToPathway}
        onClick={() => setGoToPathway(true)}>
        <span className={style.buttonText}>{translations.goToPathway}</span>
      </button>
    }
  }

  const backLink = () => {
    if (buttonClicked == 'back') {
      return <a className={style.back}
        disabled>
        <Spinner />
      </a>
    } else if (buttonClicked != '') {
      return <a href='javascript:void(0)' className={style.backDisabled}>{translations.back}</a>
    } else {
      return <a href={backPath}
        className={style.back}
        onClick={() => goBack()}>
        {translations.back}
      </a>
    }
  }

  const inputErrorClass = (inputName) => {
    if (errors != undefined && errors[inputName] != undefined) {
      return style.inputWithError
    }
    return ''
  }

  const isEuleriaLabCenter = () => {
    return licenses.includes('euleria_lab')
  }

  const toggleFieldLabel = (e) => {
    let label = document.getElementById(`${e.target.id}-label`)
    if (e.target.value == "") {
      label.style.display = "none"
    } else {
      label.style.display = "inline"
    }
  }

  const fieldLabelStyle = (value) => {
    if (value == null || value == "") {
      return { display: 'none', position: 'relative' }
    } else {
      return { display: 'inline', position: 'relative' }
    }
  }

  return (
    <div className={`${style.patientFormWrapper}`}>
      <div className={`${style.title}`}>{translations.title}</div>
      <div className={`${style.subtitle}`}
        style={{ marginBottom: '30px' }}>
        {translations.subtitle}
      </div>
      <form onSubmit={(e) => submitForm(e)}>
        <CommonFields errors={errors}
          fieldsTranslations={fieldsTranslations}
          fieldLabelStyle={fieldLabelStyle}
          gender={gender}
          patient={patient}
          showHeartrateFields={showHeartrateFields}
          showSideFields={showSideFields}
          showUserTypologyFields={showUserTypologyFields}
          selectGender={selectGender}
          toggleFieldLabel={toggleFieldLabel}
          userTypologies={userTypologies}
          sides={sides}
          inputErrorClass={inputErrorClass} />
        {showOxygenFields ?
          <MedicairFields errors={errors}
            fieldsTranslations={fieldsTranslations}
            fieldLabelStyle={fieldLabelStyle}
            patient={patient}
            selectUseOxygen={selectUseOxygen}
            toggleFieldLabel={toggleFieldLabel}
            useOxygen={useOxygen} />
          : ''}
        {showGrouptagsFields &&
          <div>
            <div>
              <div className={`${style.selectedGroupsWrapper} ${inputErrorClass('grouptags')}`}>
                <input className={style.groupTagInput}
                  placeholder={fieldsTranslations.addNewGroup}
                  onKeyDown={(e) => handleNewGroup(e)}></input>
                {selectedGroups.map(selectedGroup => {
                  return (
                    <div key={selectedGroup.tag}
                      className={`${style.groupTag}`}
                      style={{ marginRight: '10px' }}
                      onClick={() => handleRemoveGroup(selectedGroup)}
                      contentEditable="false">
                      {selectedGroup.tag}
                      <span className={style.removeGroupButton}> x</span>
                    </div>
                  )
                })
                }
              </div>
              <div className={style.inputError}>{errors["grouptags"]}</div>
            </div>
            <div className={`${style.groupTagsWrapper}`} style={{ marginBottom: '30px' }}>
              {unselectedGroups.map(grouptag => {
                return (
                  <div key={grouptag.tag}
                    className={`${style.groupTag}`}
                    style={styleForGroup(grouptag)}
                    onClick={() => handleSelectedGroup(grouptag)}>
                    {grouptag.tag}
                  </div>
                )
              })}
            </div>
          </div>
        }
        <div className={`${style.legend}`}>{translations.requiredField}</div>
        <div style={{ marginBottom: '20px', marginLeft: '15px' }}>
          {backLink()}
          <span className={`${style.buttonRight}`}>
            {buttonSend()}
          </span>
        </div>
        {isEuleriaLabCenter() &&
          <div style={{ textAlign: 'right' }}>
            {buttonPathway()}
          </div>
        }
      </form>
    </div>
  )
}

export default PatientForm
