import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./InfoTooltip.module.sass";
import { useOutsideElement } from "../../../helpers";

function InfoTooltip(props) {
  const { message } = props;
  const [messageVisible, setMessageVisible] = useState(false);
  const rootRef = useRef();

  useEffect(() => {}, []);

  const clickOnInfo = (e) => {
    e.stopPropagation();
    setMessageVisible(() => !messageVisible);
  };

  const onClickOutside = () => {
    setMessageVisible(false);
  };

  useOutsideElement(rootRef, onClickOutside);

  let messageClasses = style.InfoTooltipNotice;
  if (!messageVisible) {
    messageClasses += " " + style.InfoTooltipNoticeDisappear;
  }

  return (
    <div className={`${style.InfoTooltip}`} onClick={clickOnInfo} ref={rootRef}>
      ?<div className={messageClasses}>{message}</div>
    </div>
  );
}

InfoTooltip.propTypes = {};

InfoTooltip.defaultProps = {};

export default InfoTooltip;
