import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ListPagination.module.sass";

function ListPagination(props) {
  const { page, totPages, clickOnPrevPage, clickOnNextPage, translations } =
    props;

  const renderPrevBtn = () => {
    return (
      <div
        onClick={page == 1 ? null : () => clickOnPrevPage()}
        className={`${style.ListPaginationPrev} ${
          page == 1 ? style.disabled : ""
        }`}
      >
        {translations?.prev}
      </div>
    );
  };

  const renderNextBtn = () => {
    return (
      <div
        onClick={page == totPages ? null : () => clickOnNextPage()}
        className={`${style.ListPaginationNext} ${
          page == totPages ? style.disabled : ""
        }`}
      >
        {translations?.next}
      </div>
    );
  };

  return (
    <div className={`${style.ListPagination}`}>
      {totPages > 1 && renderPrevBtn()}
      <div className={`${style.ListPaginationPages}`}>
        <span>{page}</span> / {totPages}
      </div>
      {totPages > 1 && renderNextBtn()}
    </div>
  );
}

ListPagination.propTypes = {
  page: PropTypes.number,
  totPages: PropTypes.number,
};

ListPagination.defaultProps = {
  page: 1,
  translations: {
    prev: "prev to translate",
    next: "next to translate"
  }
};

export default ListPagination;
