const reducer = (state, action) => {
  const newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "add_filter":
      newState.totFilters++;
      break;
    case "remove_filter":
      newState.totFilters--;
      break;
    case "remove_tot_filter":
      newState.totFilters -= action.tot;
      break;
    case "remove_all":
      newState.totFilters = 0;
      break;
  }
  state = { ...state, ...newState };
  return state;
};

const initialState = {
  totFilters: 0,
};

export { initialState, reducer };