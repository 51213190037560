import React, { useContext, useEffect, useRef } from "react";
import style from "./FilterBlock.module.sass";
import { FilterOverlayContext } from "../FilterOverlay";
import { EditPlanHeaderContext } from "../../EditPlanHeader";
import Filter from "./Filter/Filter";

function FilterBlock(props) {
  const { filterBlock } = props;
  const { dispatcher: dispatcherFilterOverlay, translations } =
    useContext(FilterOverlayContext);
  const { dispatcher: dispatcherEditPlanHeader } = useContext(
    EditPlanHeaderContext
  );

  const totChecked = filterBlock.filters.filter((item) => item.checked).length;
  let rootClasses = `${style.FilterBlock}`;
  if (filterBlock.filters.length > 10) {
    rootClasses += ` ${style.FilterBlockTwoColumn}`;
  }

  const clickOnResetAll = () => {
    dispatcherFilterOverlay({
      type: "clean_filters_block",
      categoryId: filterBlock.categoryId,
      dispatcherEditPlanHeader,
      totToRemove: filterBlock.filters.filter((item) => item.checked).length,
    });
  };

  return (
    <div className={`${rootClasses}`}>
      <div className={`${style.FilterBlockTitle}`}>
        {filterBlock.title}
        <span className={`${style.FilterBlockCounter}`}>{totChecked}</span>
      </div>
      <div className={`${style.FilterBlockContent}`}>
        <ul>
          {filterBlock.filters.map((filter) => {
            const id = `${filterBlock.categoryId}_${filter.id}`;
            return (
              <li
                key={id}
                className={`${
                  filter.position == "both" ? style.singleLine : ""
                }`}
              >
                <Filter filter={filter} id={id} />
              </li>
            );
          })}
        </ul>
        <div className={`${style.FilterBlockFooter}`}>
          <div
            className={`${style.FilterBlockReset}`}
            onClick={clickOnResetAll}
          >
            {translations?.buttons.reset_category}
          </div>
        </div>
      </div>
    </div>
  );
}

FilterBlock.propTypes = {};

FilterBlock.defaultProps = {
  checked: false,
};

export default FilterBlock;
