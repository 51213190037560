import React from "react"
import style from "./StrengthGeneralResult.module.sass"
import StrengthResultNotes from "../StrengthResult/StrengthResultNotes/StrengthResultNotes"

function StrengthGeneralResult(props) {
  const {
    colors,
    id,
    notes,
    repetitions,
    times,
    type,
    license
  } = props

  return (
    <div>
      {repetitions && times &&
        <div className={style.strengthRepetitionAndTime}>
          <div className={style.info}>
            <div className={style.value} style={{ color: colors.primaryColor }}>{repetitions.value}</div>
            <div className={style.label}>{repetitions.label}</div>
          </div>
          <div className={style.info}>
            <div className={style.value} style={{ color: colors.primaryColor }}>{times.work.value + " sec"}</div>
            <div className={style.label}>{times.work.label}</div>
          </div>
          <div className={style.info}>
            <div className={style.value} style={{ color: colors.primaryColor }}>{times.rest.value + " sec"}</div>
            <div className={style.label}>{times.rest.label}</div>
          </div>
        </div>
      }
      {type == "strength_general_result" && notes &&
        <StrengthResultNotes
          {...notes}
          colors={colors}
          id={`strength-notes-${id}`}
          license={license}
        />
      }
    </div>
  )
}

export default StrengthGeneralResult