import React, { useRef } from "react";
import PropTypes from "prop-types";
import style from "./CardioSavedSequenceCompletion.module.sass";
import Button from "../Button/Button";
import TimingBlock from "../EditPlan/EditPlanSummaryBlock/TimingBlock/TimingBlock";
import SavedSequenceInputName from "../FavouritePlans/SavedSequence/SavedSequenceInputName/SavedSequenceInputName";

function CardioSavedSequenceCompletion(props) {
  const { duration, value, updateNameUrl, prevUrl, nextUrl, translations } = props

  const renderHeader = () => {
    return (
      <div className={`row mb-4 py-3 ${style.header}`}>
        <div className={`col-2`}>
          <Button kariBtnWhite href={prevUrl}>
            {translations.backBtn}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={`${style.CompletionPage}`}>
      <div className={style.title}>{translations.title}</div>
      {renderHeader()}
      <div className={`${style.container} container text-white`}>
        <div className={`${style.name} mb-4`}>
          <div className={`mb-1`}>{translations.labelName}</div>
          <SavedSequenceInputName
            placeholder={translations.placeholder}
            value={value}
            updateUrl={updateNameUrl}
          />
        </div>
        <div className={`mb-5`}>
          <TimingBlock
            message={''}
            duration={duration.value}
            durationText={duration.text}
          />
        </div>
        <div className={"text-center mb-5 d-flex justify-content-around"}>
          <Button karyBtn kariBtnGreen href={nextUrl}>
            {translations.nextBtn}
          </Button>
        </div>
      </div>
    </div>
  )
}

CardioSavedSequenceCompletion.propTypes = {};

CardioSavedSequenceCompletion.defaultProps = {};

export default CardioSavedSequenceCompletion;