import React, { useEffect, useRef } from "react";
import style from "./TechBox.module.sass";

function TechBox(props) {
  const { supportEmail, title, supportTel, whatsappUrl, whatsappText } = props;

  return (
    <div className={`${style.TechBox}`}>
      <div className={`${style.TechBoxTitle} p-3 text-uppercase`}>{title}</div>
      <div className={`${style.TechBoxProblems} p-3 text-uppercase`}>
        <div className={`${style.contentLink} ${style.emailLink} p-2`}>
          <a href={"mailto:" + supportEmail} target="_blank">
            {supportEmail}
          </a>
        </div>
        <div className={`${style.contentLink} ${style.phoneLink} p-2`}>
          <a href={"tel:" + supportTel} target="_blank">
            {supportEmail}
          </a>
        </div>
        <div className={`${style.contentLink} ${style.whatsappLink} p-2`}>
          <a href={whatsappUrl} target="_blank">
            {whatsappText}
          </a>
        </div>
      </div>
    </div>
  );
}

export default TechBox;
