import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ChatMessage.module.sass";

function ChatMessage(props) {
  const { text, time, author, otherUser } = props;

  let rootClasses = `${style.ChatMessage}`;
  if (JSON.stringify(author )== JSON.stringify(otherUser)) {
    rootClasses += " " + style["ChatMessage--personal"];
  }

  return (
    <div className={`${rootClasses}`}>
      <div className={`${style.text}`}>{text}</div>
      <div className={`${style.time}`}>{time}</div>
    </div>
  );
}

ChatMessage.propTypes = {};

ChatMessage.defaultProps = {
  message: "",
  time: "",
};

export default ChatMessage;
