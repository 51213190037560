import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { SettingsContext } from "../../Settings";

function PatientHomeNotification(props) {
  const {} = props;
  const value = useContext(SettingsContext);
  const saveSetting = value.saveSetting;
  const translations = value.translations;
  const notifications = value.sections.notifications;
  const [finish, setFinish] = useState(notifications.finish.checked);
  const [pain, setPain] = useState(notifications.pain.checked);
  const infoLabel1Ref = useRef();
  const infoLabel2Ref = useRef();

  const clickOnCheckbox = (e, setting) => {
    const value = setting == "finish" ? !finish : !pain;
    updateValue(setting, value);
  };

  const updateValue = (type, value) => {
    saveSetting(notifications.url, {
      body: JSON.stringify({ data: { type, value: value ? "true" : "false" } }),
    });
    if (type == "pain") {
      setPain(value);
    }
    if (type == "finish") {
      setFinish(value);
    }
  };

  useEffect(() => {
    $(infoLabel1Ref.current).tooltip();
    $(infoLabel2Ref.current).tooltip();
  });

  const renderCheckOption = (option) => {
    return (
      <div>
        <input
          id={option}
          type={"checkbox"}
          checked={option == "finish" ? finish : pain}
          onChange={(e) => clickOnCheckbox(e, option)}
        />
        <label htmlFor={option}>
          {option == "finish" ? translations.settings.notifications.session.finish : translations.settings.notifications.session.pain}
          <span
            data-info=""
            data-placement={"right"}
            title={option == "finish" ? translations.settings.notifications.session.finish : translations.settings.notifications.session.pain}
            ref={option == "finish" ? infoLabel1Ref : infoLabel2Ref}
          >
            i
          </span>
        </label>
      </div>
    )
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div style={{width: "50%"}}>
        <h2>{translations.settings.notifications.label}</h2>
        <br/>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.settings.notifications.kind_html,
          }}
        />
      </div>
      <div>
        {renderCheckOption("finish")} 
        {renderCheckOption("pain")} 
      </div>
    </div>
  );
}

PatientHomeNotification.propTypes = {};

PatientHomeNotification.defaultProps = { finish: true, pain: true };

export default PatientHomeNotification;
