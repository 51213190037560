import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classes from "./XclinicWorkAngle.module.sass";

function XclinicWorkAngle(props) {
  const { angle, label } = props;

  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
      <div className={classes.angle}>{`${angle}°`}</div>
    </div>
  );
}

XclinicWorkAngle.propTypes = {
  label: PropTypes.string,
  angle: PropTypes.number,
};

XclinicWorkAngle.defaultProps = {
  label: "",
  angle: 0,
};

export default XclinicWorkAngle;
