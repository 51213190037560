import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import style from "./LanguageChanger.module.sass";
import { callApi } from "../../../../helpers";
import { SettingsContext } from "../../Settings";

function LanguageChanger(props) {
  const { } = props;
  const [showBtn, setShowBtn] = useState(false);
  const value = useContext(SettingsContext);
  const languages = value.sections.languages;
  const [lang, setLang] = useState(languages.value);
  const saveSetting = value.saveSetting;
  const translations = value.translations;

  const onChangeSelect = (e, setting) => {
    setLang(e.target.value);
    setShowBtn(true);
    saveSetting(languages.url, {
      body: JSON.stringify({
        lang: e.target.value,
      }),
    });
  };

  return (
    <div className={`${style.LanguageChanger}`}>
      <h2>{translations.settings.languages.title}</h2>
      <span data-type="select">
        <select value={lang} onChange={(e) => onChangeSelect(e, "language")}>
          <option value="it">🇮🇹 Italiano</option>
          <option value="de">🇩🇪 Deutsch</option>
          <option value="en">🇺🇸 English</option>
          <option value="fr">🇫🇷 Français</option>
          <option value="pl">🇵🇱 Polski</option>
          <option value="no">🇳🇴 Norsk</option>
          <option value="tr">🇹🇷 Türkçe</option>
          <option value="es">🇪🇸 Español</option>
          <option value="zh_CN">🇨🇳 Chinese</option>
          <option value="ro">🇷🇴 Română</option>
          <option value="uk">🇺🇦 Українська</option>
        </select>
      </span>

      {showBtn &&
        <span className={`${style.refreshBlock} ${showBtn ? style['refreshBlock--show'] : ''} `}>
          <a href={languages.urlRefresh}>
            <button>{translations.settings.languages.refresh}</button>
          </a>
        </span>
      }
    </div>
  );
}

LanguageChanger.propTypes = {};

LanguageChanger.defaultProps = {
  lang: "it",
};

export default LanguageChanger;
