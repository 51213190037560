import React from "react";

const Pdf = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.6667 5H8.33333C6.48333 5 5 6.48333 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5Z" fill="#E85E73" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 21.6667C14.6667 21.6667 15.8333 20.5 15.8333 19.1667V17.5C15.8333 16.1667 14.6667 15 13.3333 15H9.16667V25H11.6667V21.6667H13.3333ZM21.6667 25C23 25 24.1667 23.8333 24.1667 22.5V17.5C24.1667 16.1667 23 15 21.6667 15H17.5V25H21.6667ZM28.3333 17.5H30.8333V15H25.8333V25H28.3333V21.6667H30.8333V19.1667H28.3333V17.5ZM21.6667 17.5H20V22.5H21.6667V17.5ZM13.3333 17.5H11.6667V19.1667H13.3333V17.5Z" fill="white" />
    </svg>
  );
};

export default Pdf;
