import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ButtonCall.module.sass";

function ButtonCall(props) {
  const { onClick, imgOn, imgOff, customClass } = props;

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={`${style.ButtonCall} ${customClass}`}
      onClick={onClickHandler}
    >
      <input type={"checkbox"}></input>
      <img src={imgOn} className={`${style.on}`} />
      <img src={imgOff} className={`${style.off}`} />
    </div>
  );
}

ButtonCall.propTypes = {
  onClick: PropTypes.func,
  imgOn: PropTypes.string,
  imgOff: PropTypes.string,
};

ButtonCall.defaultProps = {};

export default ButtonCall;
