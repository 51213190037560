import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./Sequence.module.sass";
import Actions from "../../../FavouritePlans/SavedSequence/Actions/Actions";
import { callApi } from "../../../../helpers";
import { PersonalSequencePageContext } from "../../PersonalSequencePage";

function Sequence(props) {
  const {
    code,
    draft,
    shortSide,
    longSide,
    totExercises,
    savePreferredUrl,
    removePreferredUrl,
    duplicateUrl,
    editUrl,
    name,
    translations,
    index,
    groupIndex,
    updateNameUrl,
    id,
    type,
    url,
    favourite,
  } = props;
  const sideRef = useRef(null);
  const [wrongName, setWrongName] = useState(false);
  const { updateSessions, refresh } = useContext(PersonalSequencePageContext);
  const rootRef = useRef();

  useEffect(() => {
    if (sideRef.current) {
      $(sideRef.current).tooltip();
    }
  }, []);

  const removeFromList = () => {
    refresh();
  };

  const onDuplicateEnd = (data) => {
    window.location.href = data.location;
  };

  const onUpdateFav = () => {
    refresh();
  };

  const onChangeName = (e) => {
    const newName = e.target.value;
    props.onChangeName(newName, index, groupIndex);
  };

  const onFocus = () => {
    setWrongName(false);
  };

  const onBlur = (e) => {
    callApi(
      updateNameUrl,
      "PATCH",
      "json",
      (data) => {
        setWrongName(!data.valid);
      },
      null,
      null,
      {
        body: JSON.stringify({ sequence: { name: name } }),
      }
    );
  };

  let duration = 0.9;
  const onClickSequence = async () => {
    updateSessions(id, type);
    if (rootRef.current) {
      await TweenLite.fromTo(rootRef.current, duration, {
        css: { className: style.SequenceHover }
      }, {
        css: { className: style.Sequence }
      });
    }
  };

  return (
    <div
      className={`${style.Sequence}`}
      onClick={draft ? undefined : onClickSequence}
      ref={rootRef}
    >
      <div className={`${style.sequenceGrid}`}>
        <div className={`${style.letter}`}>{code}</div>
        <div className={`${style.title}`}>
          {draft &&
            <div className={style.draftBadge}>{translations.draft}</div>
          }
          <input
            type={"text"}
            name={"code"}
            className={`${wrongName ? style.nameWrong : ""}`}
            value={name}
            onChange={onChangeName}
            onBlur={onBlur}
            onFocus={onFocus}
            onClick={(e) => e.stopPropagation()}
            maxLength="25"
          />
          {!draft && !["CardioPersonalSequence", "ReactsPersonalSequence"].includes(type) &&
            <div className={`${style.side}`} ref={sideRef} title={longSide}>
              {shortSide}
            </div>
          }
        </div>
        <div className={`${style.exerciseCount}`}>
          <div>
            <span style={{ paddingRight: "10px" }}>{totExercises}</span>
            {totExercises > 1 || totExercises == 0
              ? translations.exercises
              : translations.exercise}
          </div>
        </div>
        <div className={`${style.commands}`}>
          <Actions
            addFav={true}
            duplicateUrl={duplicateUrl}
            savePreferredUrl={savePreferredUrl}
            removePreferredUrl={removePreferredUrl}
            editUrl={editUrl}
            onDeleteEnd={removeFromList}
            tooltipActions={translations?.tooltipActions}
            deleteUrl={url}
            favourite={favourite}
            onDuplicateEnd={onDuplicateEnd}
            onUpdateFav={onUpdateFav}
          />
        </div>
        {/* <Commands /> */}
      </div>
    </div>
  );
}

Sequence.propTypes = {};

Sequence.defaultProps = {};

export default Sequence;
