import React, { useContext, useState } from "react";
import style from "./StrengthMeasureSetting.module.sass"
import { SettingsContext } from "../Settings";

function StrengthMeasureSetting(props) {
  const value = useContext(SettingsContext);
  const strengthMeasureSettings = value.sections.strengthMeasure
  const saveSetting = value.saveSetting
  const [measure, setMeasure] = useState(strengthMeasureSettings.selectedOption || 'N')

  const onChangeMeasure = (e) => {
    setMeasure(e.target.value)
    saveSetting(strengthMeasureSettings.url, {
      body: JSON.stringify({
        strength_measure: e.target.value
      })
    })
  } 

  return (
    <div>
      <div className={style.StrengthMeasureSetting}>
        <h2>{strengthMeasureSettings.title}</h2>
        <span data-type="select">
          <span className={style.selectLabel}>{strengthMeasureSettings.strengthLabel}</span>
          <select value={measure} onChange={(e) => onChangeMeasure(e)}>
            { strengthMeasureSettings.options.map((option) => {
              return (
                <option key={option.value} 
                        value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </select>
        </span>
      </div>
    </div>
  )
}

export default StrengthMeasureSetting