import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./FilterOverlayContent.module.sass";
import FilterBlock from "../FilterBlock/FilterBlock";

function FilterOverlayContent(props) {
  const {state} = props;

  const filters = [];
  let totTypes = 0;
  let currentTypeIndex = 0;
  Object.keys(state.filters).forEach(function (key) {
    if (key === state.currentType) {
      currentTypeIndex = totTypes;
    }
    totTypes++;
    filters.push(state.filters[key]);
  });
  let offsetTranslation = currentTypeIndex / totTypes;

  const renderFilterBlock = (filter) => {
    return filter["filters_groups"].map((filterBlock) => {
      return (
        <FilterBlock
          key={`filter_block_${filterBlock.categoryId}`}
          filterBlock={filterBlock}
        />
      );
    });
  };

  return (
    <div className={`${style.FilterOverlayContent}`}>
      <div
        className={`${style.FilterOverlayContentIn}`}
        style={{
          width: `${totTypes * 100}%`,
          transform: `translate(${-offsetTranslation * 100}%,0)`,
        }}
      >
        {filters.map((filter, index) => {
          const filterBlockClass =
            filter["filters_groups"].length < 4 ? style.FewElements : "";
          return (
            <div
              key={`filter_${filter.id}_${index}`}
              className={filterBlockClass}
              style={{ width: `${100 / totTypes}%` }}
            >
              {renderFilterBlock(filter)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

FilterOverlayContent.propTypes = {};

FilterOverlayContent.defaultProps = {};

export default FilterOverlayContent;
