import React from "react";
import style from "./FirstAccess.module.sass";
import { callApi } from "../../helpers";
import ButtonSelect from "../ButtonSelect/ButtonSelect";

function FirstAccess(props) {
  const {
    addNewText,
    createPlanHidden,
    createPlanUrls,
    licenses,
    newPatientUrl,
    translations
  } = props;

  const createPlan = (url) => {
    callApi(url, "POST", "json", (data) => {
      location.href = data.redirectUrl
    })
  }

  const renderLicensesNames = () => {
    return (
      <span>
        {licenses.map((license, index) => {
          if (license && index > 0) {
            return (
              <span>
                <span>{translations.and}</span>
                <span className={style.textHighlight}>{license}</span>
              </span>
            )
          } else {
            return (
              <span className={style.textHighlight}>{license}</span>
            )
          }
        })}
      </span>
    )
  }

  const renderPlansBox = () => {
    return (
      <div className={`${style.box} ${style.plansBox}`}>
        <div className={style.title}>
          {translations.title}
        </div>
        <div>
          <div className={style.textHighlight}>
            {translations.description_1}
          </div>
          <div>
            <span>{translations.description_2}</span>
            {createPlanUrls && renderLicensesNames()}
          </div>
          <div>{translations.description_3}</div>
        </div>
      </div>

    )
  }

  const renderBox = () => {
    return (
      <div className={style.box}>
        <div className={style.title}>
          {translations.title}
        </div>
        <div>
          <span>{translations.description_1}</span>
          <span className={style.textHighlight}>{translations.euleria}</span>
        </div>
        <div>
          <span>{translations.description_2}</span>
          <span className={style.textHighlight}>{translations.description_3}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={`${style.FirstAccess} ${createPlanUrls && style.plans}`}>
      {createPlanUrls && renderPlansBox()}
      {newPatientUrl && renderBox()}
      <div className={style.buttons}>
        {!createPlanHidden && createPlanUrls && createPlanUrls.length > 1 &&
          <ButtonSelect
            callApiFunction={createPlan}
            options={createPlanUrls.map((createUrl) => {
              return {
                label: createUrl.name,
                disable: createUrl.disable,
                args: [createUrl.url]
              }
            })}
            title={addNewText}
          />
        }
        {!createPlanHidden && createPlanUrls && createPlanUrls.length == 1 &&
          <div className={style.button}
            onClick={() => createPlan(createPlanUrls[0].url)}>
            {addNewText}
          </div>
        }
        {newPatientUrl &&
          <a className={style.button}
            href={newPatientUrl}>
            {addNewText}
          </a>
        }
      </div>
    </div>
  );
}

FirstAccess.propTypes = {
};

FirstAccess.defaultProps = {
};

export default FirstAccess
