import React, {Fragment, useState, useEffect, useRef } from "react";
import { callApi } from "../../../helpers";
import style from "./UpperLimbResultReport.module.sass"
import UpperLimbResultDetail from "../UpperLimbResultReport/UpperLimbResultDetail";
import UpperLimbResultDetailNoSide from "../UpperLimbResultReport/UpperLimbResultDetailNoSide";

function UpperLimbResultReport(props) {

  const {
    index,
    code,
    side_injured_id,
    left,
    right,
    hover_health,
    hover_inj,
    hover_lsi,
    name,
    results,
    lsi,
    lsi_footer,
    name_labels,
    image_url,
    type_done,
    round_label

  } = props



  useEffect(() => {
    if(true) {
      let canvas;

    }
  }, [])

 const renderHeaderColumn = () =>{
  return (
    <div className={style.headerColumn} >
        <div className={style.columnLabel}>
          1°
          <div>
          {round_label}
          </div>
        </div>
        <div className={style.columnLabel}>
          2°
          <div>
          {round_label}
          </div>
        </div>
        <div className={style.columnLabel}>
          3°
          <div>
          {round_label}
          </div>
        </div>
      </div>
  )
 }

  const renderSide = () => {
    if(side_injured_id ==1)
    return (
      <div className={style.headerSide} >
        <div className={style.void}>
        <div className={style.name}>
            {name}
          </div>
        </div>
        <div className={`${style.injuredSide}`}>   
          {left}
        </div>
        <div className={`${style.LSI}`}> 
          {lsi}*
        </div>  
        <div className={`${style.Side}`}> 
          {right}
        </div>
        <div className={`${style.voidSpace} ` }>
          &nbsp;
        </div>
        <div className={`${style.done} ` }>
        {type_done!="row" &&
            renderHeaderColumn()
          }
        </div>    
      </div>
    )
    else
    return (
      <div className={style.headerSide} >
        <div className={style.void}>
          <div className={style.name}>
            {name}
          </div>
        </div>
        <div className={`${style.Side}`}> 
          {left}
        </div>  
        <div className={`${style.LSI}`}> 
          {lsi}*
        </div>   
        <div className={`${style.injuredSide}`}>   
          {right}
        </div>  
        <div className={`${style.voidSpace} ` }>
          &nbsp;
        </div>
        <div className={`${style.done} ` }>
        {type_done!="row" &&
            renderHeaderColumn()
          }
        </div> 
      </div>
    
    )
  }

  const renderNoSide = () => {

    return (
      <div className={style.headerSide} >
        <div className={style.void}>
        <div className={style.name}>
            {name}
          </div>
        </div>
        <div className={`${style.injuredSide}`}>   
         &nbsp;
        </div>
        <div className={`${style.LSI}`}> 
        &nbsp;
        </div>  
        <div className={`${style.Side}`}> 
        &nbsp;
        </div>
        <div className={`${style.voidSpace} ` }>
          &nbsp;
        </div>
        <div className={`${style.done} ` }>
        {type_done!="row" &&
            renderHeaderColumn()
          }
        </div>    
      </div>
    )
    
  }


  const renderSideMovement = (results)  => {

    console.log(results)

    if (!results || results.length === 0) {
      return <div>No movements available</div>;
    }
  
    return results.map((movement, index) => (
      <Fragment>
      <UpperLimbResultDetail
      index={index}
      movement={movement}
      sideInjuredId = {side_injured_id }
      typeDone = {type_done}

       />

      </Fragment>
    )
    );
  }
  
  const renderNoSideMovement = (results)  => {

    if (!results || results.length === 0) {
      return <div>No movements available</div>;
    }
  
    return results.map((movement, index) => (

      <UpperLimbResultDetailNoSide
      index={index}
      movement={movement}
       />


    ));
  }


  
  const renderNameLabel = (name_labels) => {

    return name_labels.map((name_label) => (
      <div className={style[name_label.code]}>
      {name_label.value}
     </div>
    ));
  }
  return (

    <div className={style.upperLimbResult}>

      <div>
      {side_injured_id && renderSide()}
      {side_injured_id && 
      renderSideMovement(results) 
      } 
          
      {!side_injured_id && renderNoSide()}
      {!side_injured_id && 
      renderNoSideMovement(results)
      }      
      </div>
 
    </div>
  )
}

export default UpperLimbResultReport