import React, { Fragment, useEffect, useState, useRef } from "react";
import Modal from "../../Modal/Modal";
import Select from "../../Select/Select";
import { callApi } from "../../../helpers";
import style from "./PatientSurveys.module.sass";

function PatientSurveys(props) {
  const {
    sendNotifications,
    translations,
    url
  } = props

  
  const [surveySelectOptions, setSurveySelectOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(undefined)

  const [patientSurveysSubmitted, setPatientSurveysSubmitted] = useState([])
  const [patientsSurveysUnsubmitted, setPatientsSurveysUnsubmitted] = useState([])
  const [closedSurveyModal, setClosedSurveyModal] = useState(false)
  const [surveyPolling, setSurveyPolling] = useState(false)
  
  const [openSurveylegendModal, setOpenSurveylegendModal] = useState(false)
  const [surveyToShow, setSurveyToShow] = useState(undefined)
  const [surveyToShowData, setSurveyToShowData] = useState(undefined)
  const [surveyToShowScores, setSurveyToShowScores] = useState(undefined)
  
  const sendNotificationButton = useRef()
  const patientSurveysContainer = useRef()

  useEffect(() => {
    if(!sendNotifications) {
      sendNotificationButton.current.setAttribute('data-toggle', 'tooltip')
      sendNotificationButton.current.setAttribute('title', translations.noMyLink)
      sendNotificationButton.current.classList.add('auto-tooltip')
    }
    setSurveyPolling(true)
    patientsSurveysCall(() => setSurveyPolling(false))
  }, [])

  useEffect(() => {
    if (surveyToShow) {
      fetch(surveyToShow.url)
        .then(response => response.json())
        .then(
          (result) => {
            setSurveyToShowData({...result})
          }
        )
        .catch((e) => {
          setSurveyToShow(undefined)
      });
    }
    return function cleanup() {
      setSurveyToShowData(undefined)
    };
  }, [surveyToShow])

  useEffect(() => {
    if (closedSurveyModal) {
      setOpenSurveylegendModal(false)
      setClosedSurveyModal(false)
      refreshSurveysSubmitted()
    }
  }, [closedSurveyModal])

  // useEffect(() => {
  //   if (surveyToShow) {
  //     if (surveyToShowData && surveyToShowData.scores.max != null) {
  //       if (surveyToShowData.scores.total == null) {
  //         callApi(
  //           surveyToShow.url, 
  //           "PATCH", 
  //           "json", 
  //           (result) => { 
  //             console.log('c', result)
  //             setSurveyToShowScores(result.scores)
  //           }, 
  //           null, 
  //           null
  //         )
  //       } else {
  //         setSurveyToShowScores(surveyToShowData.scores)
  //       }
  //     }
  //   }
  //   return function cleanup() {
  //     setSurveyToShowScores(undefined)
  //   };
  // }, [surveyToShowData])

  const refreshSurveysSubmitted = () => {
    setSurveyPolling(true)
    patientsSurveysCall()
    let timeout = setTimeout(() => {
      patientsSurveysCall(() => setSurveyPolling(false))
    }, 5000)
  }

  const patientsSurveysCall = (callback=undefined) => {
    callApi(
      url, 
      "GET", 
      "json", 
      (result) => { 
        setPatientSurveysSubmitted(result.submitted)
        setPatientsSurveysUnsubmitted(result.unsubmitted)
        setSurveySelectOptions(result.surveysForSelect)
        if (callback) {
          callback()
        }
      }, 
      null, 
      null
    )
  }

  const sendNotification = () => {
    if(sendNotifications) {
      setSelectedOption(undefined)
      callApi(
        url,
        "POST",
        "json",
        () => {
          patientsSurveysCall()
        },
        null,
        null,
        {
          body: JSON.stringify({
            patients_survey: {
              survey_id: selectedOption.id
            }
          })
        }
      );
    }
  }

  const deleteUnsubmittedSurvey = (deleteUrl) => {
    if(confirm(translations.areYouSure)) {
      callApi(
        deleteUrl, 
        "DELETE", 
        "json", 
        () => {
          patientsSurveysCall()
        }, 
        null, 
        null
      )
    }
  }

  const renderPatientSurveysLists = () => {
    return (
      <div className={style.surveysLists}>
        <div className={style.titleContainer}>
          <div className={style.title}>{translations.uncompiled}</div>
        </div>
        {patientsSurveysUnsubmitted.map((survey) => {
          return (
            <div className={style.uncompiledSurvey} key={survey.id}>
              <div className="d-flex flex-column">
                <div className={style.name}>{survey.name}</div>
                <div className={style.date}>{survey.createdAt}</div>
              </div>
              <div className={style.delete} onClick={() => deleteUnsubmittedSurvey(survey.url)}></div>
            </div>
          )
        })}
        <div className={style.titleContainer}>
          <div className={style.title}>{translations.compiled}</div>
          {surveyPolling &&
            <div className={`${style.refresh} ${style.animate}`}></div>
          }
          {!surveyPolling &&
            <div className={style.refreshLabel} onClick={refreshSurveysSubmitted}>{translations.refresh}</div>
          }
        </div>
        {patientSurveysSubmitted.map((survey) => {
          return (
            <div className={`${style.compiledSurvey} ${survey.processing != "" && style.disabledSurvey}`} 
                 key={survey.id} 
                 onClick={() => setSurveyToShow(survey)}>
              <div className={style.name}>{survey.name}</div>
              {survey.processing == "" && <div className={style.date}>{survey.submittedAt}</div>}
              {survey.processing != "" && <div className={style.processing}>{survey.processing}</div>}
            </div>
          )
        })}
      </div>
    )
  }
  const surveylegendModal = () => {
    return (
      <Modal open closeOnBackgroundClick={false}>
        <div className={style.surveyModal}>
          <div className={style.iframeHeader}>
            <div className={style.surveyInfo}>
              <div className={style.name}>
                {selectedOption.label}
              </div>
              <div className={style.date}>
                {selectedOption.date}
              </div>
            </div>
            <div onClick={() => setClosedSurveyModal(true)} className={style.closeButton}></div>
          </div>
          <iframe id="surveylegend-survey"
                  src={selectedOption.url}
                  className={style.iframeContent}
          >
          </iframe>
        </div>
      </Modal>
    )
  }

  const surveyQuestionsAndAnswers = () => {
    return surveyToShowData.questions.map((question, index) => {
      return (
        <div className={style.questionBlock} key={index}>
          <div className={style.question}>
            {`${index + 1}. ${question.text}`}
          </div>
          {question.answers && question.answers.map((answer) => {
            return (
              <div className={`${style.answer} ${answer.chosen && style.chosen}`}>
                <div className={answer.chosen ? style.point : style.line}></div>
                <div>{answer.text}</div>
              </div>
            )
            })
          }
          {question.answer &&
            <div className={`${style.answer} ${style.chosen}`}>
              <div className={style.point}></div>
              <div className={style.scale}>
                <span className={style.value}>{question.answer.value}</span>
                <span>{` ${translations.of} ${question.answer.maxValue}`}</span> 
              </div>
            </div>
          }
        </div>
      )
    })
  }

  const compiledSurveyScore = () => {
    if(surveyToShowScores && surveyToShowScores.total != null) {
      return (
        <span>
          <span className={style.value}>{surveyToShowScores.total}</span>
          <span>{translations.of} {surveyToShowScores.max}</span>
        </span>
      )
    } else {
      return (
        <span className={style.processing}>{surveyToShowData.scores.processing}</span>
      )
    }
  }

  const surveyModal = () => {
    return (
      <Modal open closeOnBackgroundClick={false}>
        <div className={style.compiledSurveyModal}>
          <div className={style.header}>
            <div onClick={() => setSurveyToShow(undefined)} className={style.closeButton}></div>
            <div className={style.title}>{surveyToShow.name}</div>
            {surveyToShowData &&
              <Fragment>
                <div className={style.dateAndQuestions}>
                  <span className={style.label}>{translations.submittedAt}</span>
                  <span className={style.value}>{surveyToShowData.submittedAt}</span>
                </div>
                <div className={style.dateAndQuestions}>
                  <span className={style.label}>{translations.questionNumber}</span>
                  <span className={style.value}>{surveyToShowData.questions.length}</span>
                </div>
                {false && surveyToShowData.scores.max &&
                  <div className={style.score}>
                    <span className={style.label}>{translations.score}</span>
                    {surveyToShowData && surveyToShowData.scores.max != null && compiledSurveyScore()}
                  </div>
                }
              </Fragment>
            }
          </div>
          <div className={style.body}>
            {surveyToShowData && surveyQuestionsAndAnswers()}
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <div className={style.patientSurveys} ref={patientSurveysContainer}>
      <div className={style.surveySelectTitle}>{translations.selectSurvey}</div>
      <div className={style.surveySelect}>
        <Select
          prompt={translations.prompt}
          options={surveySelectOptions}
          setOption={setSelectedOption}
          selectedOption={selectedOption?.label}
          grouped
          searchable
          translations={{notFound: translations.notFound}}
        />
      </div>
      <div className={style.compileOrSendRow}>
        {/* TODO: verify if has firebase token */}
        <a className={`${style.button} ${selectedOption == undefined && style.disabled}`}
           disabled={selectedOption == undefined}
           onClick={() => setOpenSurveylegendModal(true)}>
          {translations.compile}
        </a>
        <a className={`${style.button} ${(selectedOption == undefined || !sendNotifications) && style.disabled}`}
           ref={sendNotificationButton}
           disabled={selectedOption == undefined || !sendNotifications}
           onClick={sendNotification}>
          {translations.send}
        </a>
      </div>
      {renderPatientSurveysLists()}
      {openSurveylegendModal && surveylegendModal()}
      {surveyToShow && surveyModal()}
    </div>
  );
}

export default PatientSurveys;
