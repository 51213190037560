import React, { Fragment, useEffect, useRef, useState } from "react";
import style from "./Button.module.sass";

function Button(props) {
  const {
    href,
    karyBtn,
    kariBtnWhite,
    kariBtnGreen,
    kariBtnGreenBg,
    kariBtnGreenOver,
    kariBtnArrowRight,
    kariBtnArrowLeft,
    kariBtnDisable,
    waitContent,
    showWait
  } = props;
  let rootClass = style.Button;
  const [showWaitMessage, setShowWaitMessage] = useState(showWait);


  useEffect(() => {
    setShowWaitMessage(showWait)
  }, [showWait])

  const onClick = (e) => {
    if (href == '#' || !href) {
      e.preventDefault();
    }
    if (props.onClick) {
      props.onClick();
      if (showWait) {
        setShowWaitMessage(true);
      }
    }
  };

  if (typeof karyBtn == "boolean") {
    if (karyBtn !== false) {
      rootClass += " text-primary";
    }
  }

  if (typeof kariBtnDisable == "boolean") {
    if (kariBtnDisable !== false) {
      rootClass += " " + style.kariBtnDisable;
    }
  }

  if (typeof kariBtnWhite == "boolean") {
    if (kariBtnWhite !== false) {
      rootClass += " " + style.kariBtnWhite;
    }
  }

  if (typeof kariBtnGreen == "boolean") {
    if (kariBtnGreen !== false) {
      rootClass += " " + style.kariBtnGreen;
    }
  }

  if (typeof kariBtnGreenBg == "boolean") {
    if (kariBtnGreenBg !== false) {
      rootClass += " " + style.kariBtnGreenBg;
    }
  }

  if (typeof kariBtnGreenOver == "boolean") {
    if (kariBtnGreenOver !== false) {
      rootClass += " " + style.kariBtnGreenOver;
    }
  }

  if (typeof kariBtnArrowRight == "boolean") {
    if (kariBtnArrowRight !== false) {
      rootClass += " " + style.kariBtnArrowRight;
    }
  }

  if (typeof kariBtnArrowLeft == "boolean") {
    if (kariBtnArrowLeft !== false) {
      rootClass += " " + style.kariBtnArrowLeft;
    }
  }

  const renderArrowRight = () => {
    if (typeof kariBtnArrowRight == "boolean") {
      if (kariBtnArrowRight !== false) {
        return <i className="fa fa-sm fa-angle-right ms-2"></i>;
      }
    }
    return null;
  };

  const renderArrowLeft = () => {
    if (typeof kariBtnArrowLeft == "boolean") {
      if (kariBtnArrowLeft !== false) {
        return <i className="fa fa-sm fa-angle-left ms-2"></i>;
      }
    }
    return null;
  };

  const renderWaitMessage = () => {
    return waitContent;
  };

  const renderDefaultContent = () => {
    return (
      <Fragment>
        {renderArrowLeft()}
        {props.children}
        {renderArrowRight()}
      </Fragment>
    );
  };

  return (
    <a
      className={`${rootClass} btn`}
      href={href}
      onClick={onClick}
    >
      {showWaitMessage ? renderWaitMessage() : renderDefaultContent()}
    </a>
  );
}

Button.propTypes = {
};

Button.defaultProps = {
  href: "#",
  showWait: false
};

export default Button;
