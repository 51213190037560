import React from "react";

const LegendLabel = (props) => {
  return (
    <h3
      className="text-white"
      style={{
        fontSize: "16px",
        position: "absolute",
        bottom: 0,
        left: "35px",
      }}
    >
      {props.label}
    </h3>
  );
};

export { LegendLabel as default };
