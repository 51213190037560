import React, { useState, useEffect, useRef } from "react";
import style from "./LineAsimmetry.module.sass";

function updateLine(value,identifier) {
  var fill = document.getElementById(identifier+"_fill");
  var label = document.getElementById(identifier+"_valueLabel");
  var bars = document.querySelectorAll("#bar_"+identifier);
  
  var label_value = value.toFixed(1);




  // Limitiamo il valore all'intervallo consentito
  value = Math.max(-99, Math.min(99, value-100));
  value = value / 2;


  // Calcoliamo la larghezza percentuale del riempimento
  var fillWidth = Math.abs(value); // Larghezza percentuale

  // Calcoliamo la posizione del riempimento
  var fillPosition = 50 + (value / 2); // Parte sempre dal centro

  // Aggiorniamo lo stile del riempimento e il testo del label
  fill.style.width = fillWidth + '%';
  fill.style.left = fillPosition + '%'; // Aggiorniamo la posizione
 
  label.textContent = Math.abs(label_value) + '%';
  if (parseInt(label_value)>200){
    label.textContent = ">200%"
  }
  bars.forEach(function (bar) {
    console.log(bar.classList);
    if (bar.classList[1].indexOf('left')>0) {
      bar.style.left = 50 + '%';
      console.log(bar.style.left);
    } else {
      bar.style.left = 50 + value + '%';
      console.log(bar.style.left);
    }
  });
}

function LineAsimmetry({ value, identifier }) {
  const containerRef = useRef(null);

  useEffect(() => {
    updateLine(value,identifier);
  }, [value,identifier]);

  return (
    <div ref={containerRef} className={style.container}>
      <div id={identifier} className={style.line}>
        <div id={`${identifier}_fill`} className={style.fill}></div>
        <div className={`${style.bar} ${style.left} ` } id={`bar_${identifier}`} ></div>
        <div className={`${style.bar} ${style.right} `} id={`bar_${identifier}`} ></div>
      </div>
      <div id={`${identifier}_valueLabel`} className={style.valueLabel}>{value}</div>
      <hr />
    </div>
  );
}

export default LineAsimmetry;
