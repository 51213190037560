import React, { useContext, useEffect, useRef, useState } from "react";
import List from "../../List/List";
import CallItem from "./CallItem/CallItem";
import style from "./CallsList.module.sass";

function CallsList(props) {
  const { url, urlFuture } = props;
  const [titleToday, setTitleToday] = useState("");
  const [titleFuture, setTitleFuture] = useState("");
  const [showToday, setShowToday] = useState(false);
  const [showFuture, setShowFuture] = useState(false);
  const [translations, setTranslations] = useState(false);

  const renderItem = (item) => {
    return (
      <CallItem
        {...item}
        onRemove={onRemoveItem}
        tooltipActions={translations?.tooltip_actions}
        updateCall={props.updateCall}
      />
    );
  };

  const onRemoveItem = () => {
    props.forceUpdate();
  };

  return (
    <div className={`${style.CallsList}`}>
      <div
        className={`${style.title}`}
        style={{ display: showToday ? "block" : "none" }}
      >
        {titleToday}
      </div>
      <List
        showPagination={false}
        renderItem={renderItem}
        baseQuery={url}
        onItemsLoaded={(items, data) => {
          setShowToday(items.length);
          setTranslations(data.translations);
          setTitleToday(data.translations.title);
        }}
      />
      <div
        className={`${style.title}`}
        style={{ display: showFuture ? "block" : "none" }}
      >
        {titleFuture}
      </div>
      <List
        showPagination={false}
        renderItem={renderItem}
        baseQuery={urlFuture}
        onItemsLoaded={(items, data) => {
          setShowFuture(items.length);
          setTranslations(data.translations);
          setTitleFuture(data.translations.title);
        }}
      />
    </div>
  );
}

CallsList.propTypes = {};

CallsList.defaultProps = {};

export default CallsList;
